import { FormField, FormItem, FormControl, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useFormContext } from 'react-hook-form'

const Fields = () => {
    const { control } = useFormContext()

    return (
        <div className='space-y-4'>
            <FormField
                control={control}
                name='email'
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] rounded-sm'
                                type='text'
                                placeholder='Recipient*'
                                disabled
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
            <FormField
                control={control}
                name='address'
                render={({ field }) => (
                    <FormItem>
                        <FormControl>
                            <Input
                                className='font-sans pl-3 w-full text-base h-11 border border-black text-[#191A0A] rounded-sm'
                                type='text'
                                placeholder='Address*'
                                disabled
                                {...field}
                            />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                )}
            />
        </div>
    )
}
export default Fields
