import { InvoiceType } from '@/api/invoicing/schema'
import { COMPLETE_FORMAT_WITHOUT_TIME } from '@/constants'
import { userAtom } from '@/store/auth'
import dayjs from 'dayjs'
import { useAtomValue } from 'jotai'

interface IInvoiceHeader {
    invoice: InvoiceType
}

const InvoiceHeader = ({ invoice }: IInvoiceHeader) => {
    const user = useAtomValue(userAtom)
    return (
        <>
            <div className='flex justify-center w-full p-10'>
                <p className='font-semibold text-3xl'>Invoice</p>
            </div>
            <div className='flex flex-row justify-between w-full p-10'>
                <div className='flex flex-col'>
                    <p className='font-semibold'>Billed To</p>
                    <p>{invoice?.profile?.firstName + ' ' + invoice?.profile?.lastName}</p>
                    <p>{`${user?.address?.streetAddress + ','} ${
                        user?.address?.streetTwoAddress !== 'undefined' &&
                        user?.address?.streetTwoAddress !== undefined
                            ? user?.address?.streetTwoAddress + ', '
                            : ''
                    }${user?.address?.city + ','} ${user?.address?.state + ','} ${user?.address
                        ?.zipCode}`}</p>
                </div>
                <div className='flex flex-col'>
                    <p className='font-semibold'>
                        Invoice Number: <span className='font-normal'>{invoice?.invoiceName}</span>
                    </p>
                    <p className='font-semibold'>
                        Billing Date:{' '}
                        <span className='font-normal'>
                            {dayjs(invoice?.createdAt).format(COMPLETE_FORMAT_WITHOUT_TIME)}
                        </span>
                    </p>
                    <p className='font-semibold'>
                        Due Date:{' '}
                        <span className='font-normal'>
                            {dayjs(invoice?.createdAt)
                                .add(1, 'month')
                                .format(COMPLETE_FORMAT_WITHOUT_TIME)}
                        </span>
                    </p>
                </div>
            </div>
            <div className='flex flex-row justify-end w-full px-10'>
                <p className='font-semibold text-2xl text-zentive-blue'>
                    Total Amount:{' '}
                    <span className='font-normal text-zentive-blue'>
                        {' '}
                        $
                        {invoice?.totalAmount?.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                        })}
                    </span>
                </p>
            </div>
            <div className='flex flex-row h-2 my-2 bg-zentive-gray-light w-full'></div>
        </>
    )
}
export default InvoiceHeader
