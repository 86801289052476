import { Button } from '@/components/Button'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
    DialogTrigger,
} from '@/components/Dialog'
import SearchBar from '@/components/InputSearchBox'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { customerInvoiceAtom, expenseAtom, invoiceContentAtom } from '@/store/owner'
import { useAtom, useSetAtom } from 'jotai'
import { ChangeEvent, useState } from 'react'
import { FiUserPlus } from 'react-icons/fi'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useFormContext } from 'react-hook-form'
import { ImPencil } from 'react-icons/im'
import { InvoiceCustomerListType, InvoiceProfileType, InvoiceType } from '@/api/invoicing/schema'
import { getAllInvoiceCustomer } from '@/api/invoicing'
import { Pagination } from '@/components/Pagination'
import { paginationAtom } from '@/store/manageSubs'
import useViewedAsUser from '@/hooks/useViewedAsUser'

interface ClientDialogProps {
    isEditing: boolean
}

const ClientDialog: React.FC<ClientDialogProps> = ({ isEditing }) => {
    const user = useViewedAsUser()
    const { businessId = '' } = user

    const setCustomer = useSetAtom(customerInvoiceAtom)
    const setInvoiceContent = useSetAtom(invoiceContentAtom)

    const { setValue } = useFormContext<InvoiceType>()

    const [searchValue, setSearchValue] = useState('')
    const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null)
    const [tempCustomer, setTempCustomer] = useState<InvoiceProfileType | null>(null)

    const [pagination, setPagination] = useAtom(paginationAtom)
    const [expenses, setExpenses] = useAtom(expenseAtom)

    const { data: customers, isSuccess } = useQuery<unknown, AxiosError, InvoiceCustomerListType>({
        enabled: !!businessId,
        queryKey: ['customerList', businessId, pagination, searchValue],
        queryFn: () => getAllInvoiceCustomer(businessId, searchValue, pagination),
    })

    const handleOnSubmit = () => {
        setCustomer(tempCustomer)

        if (tempCustomer?.quote?.expense?.length! > 0) {
            setExpenses([])
            setExpenses(tempCustomer?.quote?.expense!)
        } else {
            setExpenses([])
        }

        const totalExpenses =
            expenses && expenses?.length > 0
                ? expenses.reduce((total, expense) => total + (expense.total || 0), 0)
                : 0

        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalExpense: prevVal.totalExpense + totalExpenses,
        }))

        setInvoiceContent((prevVal) => ({
            ...prevVal,
            totalUnitPrice: tempCustomer?.quote
                ? tempCustomer?.quote
                      .productServiceGroup!?.map((datum) => datum.unitPrice!)
                      .reduce((a, b) => a + b)
                : 0.0,
        }))

        setInvoiceContent((prevVal) => ({
            ...prevVal,
            internalNotes: tempCustomer?.quote ? tempCustomer?.quote?.internalNotes! : '',
        }))

        setInvoiceContent((prevVal) => ({
            ...prevVal,
            laborCost: tempCustomer?.quote?.laborCost ?? 0.0,
        }))

        if (tempCustomer?.quote) {
            setInvoiceContent((prevVal) => ({
                ...prevVal,
                subTotal:
                    prevVal.subTotal +
                    prevVal.totalExpense +
                    prevVal.totalProduct +
                    prevVal.totalService +
                    prevVal.laborCost,
            }))
        } else {
            setInvoiceContent((prevVal) => ({
                ...prevVal,
                subTotal:
                    prevVal.totalExpense +
                    prevVal.totalProduct +
                    prevVal.totalService +
                    prevVal.laborCost,
            }))
        }

        setValue('businessId', user?.businessId ? user.businessId : '')
        setValue('status', 'draft')
        if (tempCustomer?.profile != null) {
            setValue('profileId', tempCustomer.profile.profileId)
            setValue('profile', tempCustomer.profile)
        }
        if (tempCustomer?.quote != null) {
            setValue('quoteId', tempCustomer?.quoteId)
            setValue('quote', tempCustomer?.quote)
            setValue('productServiceGroup', tempCustomer?.quote?.productServiceGroup)
            setValue('expense', tempCustomer?.quote?.expense)
            setValue('internalNotes', tempCustomer?.quote?.internalNotes)
            setValue('laborCost', tempCustomer?.quote?.laborCost)
            setValue('subTotal', tempCustomer?.quote?.subTotal)
            setValue('totalAmount', tempCustomer?.quote?.totalAmount as number)
            setValue('isDiscountPercent', tempCustomer?.quote?.isDiscountPercent)
            setValue('discount', tempCustomer?.quote?.discount)
            setValue('tax', Number(tempCustomer?.quote?.tax?.toFixed(2)))
        }
    }

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                {isEditing ? (
                    <ImPencil className='w-[20px] h-[20px] ml-4 text-zentive-blue' />
                ) : (
                    <Button
                        type='button'
                        variant='ghost'
                        className='text-zentive-blue px-8 py-6 border border-zentive-blue font-semibold mr-5 hover:bg-zentive-blue hover:text-white mx-5 w-[300px] h-[80px] text-[20px]'
                    >
                        <FiUserPlus className='w-6 h-6 mr-2' />
                        Add a Client
                    </Button>
                )}
            </DialogTrigger>
            <DialogPortal>
                <DialogOverlay>
                    <DialogContent className='max-w-[750px] w-[750px] max-h-[700px] h-[688px] rounded-md '>
                        <DialogHeader>
                            <DialogTitle className='mb-2'>
                                <div className='text-[20px] font-semibold text-left mb-6'>
                                    Select Client
                                </div>
                                <p className='text-left font-normal text-[16px] mb-4'>
                                    Which client would you like to create this for?
                                </p>
                                <div className='font-light mb-4'>
                                    <SearchBar
                                        placeHolder='Search for...'
                                        onSearchChange={handleSearchChange}
                                    />
                                </div>
                            </DialogTitle>
                            <DialogDescription className='max-h-[25rem] overflow-y-auto no-scrollbar'>
                                <RadioGroup
                                    defaultValue='1'
                                    value={selectedAddressId ?? undefined}
                                    className='gap-0'
                                >
                                    {isSuccess && Array.isArray(customers.content)
                                        ? customers.content.map((data) => (
                                              <div
                                                  className='border border-zentive-gray-light p-4'
                                                  key={data.profile.profileId}
                                              >
                                                  <div className='flex flex-row mb-4 text-black'>
                                                      <RadioGroupItem
                                                          value={data.profile.address?.addressId!}
                                                          className={`mr-10 ml-2 mt-1 text-white ${
                                                              data.profile.address?.addressId ===
                                                              selectedAddressId
                                                                  ? 'border-[5px] border-zentive-green-dark'
                                                                  : ''
                                                          }`}
                                                          onClick={() => {
                                                              setTempCustomer(data)
                                                              setSelectedAddressId(
                                                                  data.profile.address?.addressId!,
                                                              )
                                                          }}
                                                          disabled={!data.profile.customerId}
                                                          // checked={val}
                                                      />
                                                      <div className='flex flex-col text-[16px] text-left'>
                                                          <span className='font-semibold text-[16px] w-full justify-between mb-4'>
                                                              {data.profile.firstName}{' '}
                                                              {data.profile.lastName}{' '}
                                                              {!data.profile.customerId && (
                                                                  <span className='rounded-lg text-[14px] bg-zentive-red-dark m-2 p-1 text-white'>
                                                                      {' '}
                                                                      Unverified{' '}
                                                                  </span>
                                                              )}
                                                          </span>
                                                          <div className='flex flex-row'>
                                                              <div className='flex flex-col w-[200px] mr-6 '>
                                                                  <span className='mb-2'>
                                                                      {
                                                                          data.profile.address
                                                                              ?.streetAddress
                                                                      }
                                                                  </span>
                                                                  <span>
                                                                      {data.profile.address?.city}{' '}
                                                                      {data.profile.address?.state}{' '}
                                                                      {
                                                                          data.profile.address
                                                                              ?.zipCode
                                                                      }
                                                                  </span>
                                                              </div>
                                                              <div className='flex flex-col w-[200px] ml-6 '>
                                                                  <span className='mb-2'>
                                                                      {data.profile.phoneNumber}
                                                                  </span>
                                                                  <span className='whitespace-pre-wrap'>
                                                                      {data.profile.email?.length! >
                                                                      33
                                                                          ? data.profile.email?.slice(
                                                                                0,
                                                                                30,
                                                                            ) +
                                                                            '\n' +
                                                                            data.profile.email?.slice(
                                                                                30,
                                                                            )
                                                                          : data.profile.email}
                                                                  </span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          ))
                                        : null}
                                </RadioGroup>
                            </DialogDescription>
                            {pagination.pageSize !== undefined && (
                                <div className=''>
                                    <Pagination
                                        itemsPerPage={pagination.pageSize}
                                        setPagination={setPagination}
                                        totalRecords={customers?.meta.totalRecords ?? 1}
                                        pagination={pagination}
                                    />
                                </div>
                            )}
                        </DialogHeader>
                        <DialogFooter className='w-full h-[50px] mt-auto rounded-b-md items-end'>
                            <div className='flex flex-row w-[50%]'>
                                <DialogClose asChild>
                                    <Button
                                        type='button'
                                        variant={'outline'}
                                        className='w-full mr-4'
                                    >
                                        Cancel
                                    </Button>
                                </DialogClose>
                                <DialogClose asChild>
                                    <Button
                                        type='submit'
                                        className='w-full'
                                        onClick={() => handleOnSubmit()}
                                    >
                                        Save
                                    </Button>
                                </DialogClose>
                            </div>
                        </DialogFooter>
                    </DialogContent>
                </DialogOverlay>
            </DialogPortal>
        </Dialog>
    )
}

export default ClientDialog
