import { Card, CardContent, CardHeader } from '@/components/Card'
import { Pencil1Icon } from '@radix-ui/react-icons'
import { useFormContext } from 'react-hook-form'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getAllCrewMembersById } from '@/api/crew'
import { formatRecordNumber } from '@/utils/helper'
import {
    CrewMemberDetailsType,
    CrewMemberType,
    CrewType,
    CrewtoCrewMemberType,
} from '@/api/crew/schema'
import SelectedCrew from './SelectedCrew'
import SelectCrew from './SelectCrew'
import { AlertDialog, AlertDialogTrigger } from '@/components/AlertDialog'
import { useEffect, useState } from 'react'
import { RefreshCw } from 'lucide-react'
import Tooltip from '@/components/Tooltip'
import { FaInfoCircle, FaUser } from 'react-icons/fa'
import { computeLaborCost } from '@/utils/calc'
import { ConflictingCrewSchedule } from './ConflictingCrewSchedule'
import { SHOULD_VALIDATE_TRUE } from '@/constants'
import { getTimeDifference } from '@/utils/time'

export const getCrewFullName = (crewProfile: CrewMemberType) =>
    `${crewProfile?.firstName} ${crewProfile?.lastName}`

interface AssignCrewProps {
    schedAvailable?: boolean
    rsdCrewId?: string
}

export const AssignCrew: React.FC<AssignCrewProps> = ({ schedAvailable, rsdCrewId }) => {
    const { watch, setValue } = useFormContext()
    const [open, setOpen] = useState<boolean>(false)

    useEffect(() => {
        if (rsdCrewId) {
            setValue('crewId', rsdCrewId)
        }
     }, [])

    const [crewId, startDT, endDT] = watch([
        'crewId',
        'appointment.startDateTime',
        'appointment.endDateTime',
    ])

    const queryClient = useQueryClient()

    const { data } = useQuery({
        enabled: !!crewId,
        queryKey: ['crews', crewId],
        queryFn: () => getAllCrewMembersById(crewId as string),
    })

    const crew: CrewType | undefined = data?.content

    useEffect(() => {
        let crewMemberIds: String[] = []
        let defaultForemanId: undefined | string = undefined
        let foreman: undefined | CrewMemberDetailsType = undefined
        let laborCost: number = 0

        if (crew?.members && crew?.members?.length > 0) {
            crewMemberIds = crew.members?.map((member: CrewtoCrewMemberType) => member?.crewMemberId)

            defaultForemanId = crew?.foreMan?.crewMemberId ?? ''

            foreman = {
                firstName: crew?.foreMan?.crewProfile.firstName,
                lastName: crew?.foreMan?.crewProfile.lastName,
                crewMemberId: crew?.foreMan?.crewMemberId,
                profileId: crew?.foreMan?.profileId,
            }

            laborCost = computeLaborCost(crew.members) * getTimeDifference(startDT, endDT)
        }

        setValue('crewMemberIds', crewMemberIds, SHOULD_VALIDATE_TRUE)
        setValue('defaultForemanId', defaultForemanId, SHOULD_VALIDATE_TRUE)
        setValue('foreman', foreman, SHOULD_VALIDATE_TRUE)
        setValue('quote.laborCost', laborCost, SHOULD_VALIDATE_TRUE)
    }, [crew, startDT, endDT])

    return (
        <Card className='rounded-none'>
            <CardHeader className='bg-zentive-bg-100 flex flex-col w-full gap-2 py-3'>
                <div className='flex flex-row items-center justify-between w-full'>
                    <div>
                        <div className='flex flex-row gap-2'>
                            <p className='font-bold text-[17.07px] h-fit'>Crew</p>
                            <div className='mt-1'>
                                <Tooltip
                                    position='bottom'
                                    alignment='start'
                                    width='64'
                                    fontstyle='font-sans'
                                    content={
                                        <div>
                                            Click the "
                                            <FaUser className='text-zentive-green-dark inline' />"{' '}
                                            (Person Icon) to remove the crew member temporarily from
                                            this job.
                                        </div>
                                    }
                                >
                                    <FaInfoCircle className='text-black' />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row gap-4'>
                        <AlertDialog onOpenChange={setOpen} open={open}>
                            <AlertDialogTrigger asChild>
                                <button
                                    className='text-[17.07px] font-semibold text-zentive-green-dark cursor-pointer'
                                    type='button'
                                >
                                    {crew ? (
                                        <div className='flex flex-row space-x-[7.89px] items-center'>
                                            <p>{'Crew-' + formatRecordNumber(crew.crewNumber)}</p>
                                            <Pencil1Icon className=' h-[15.42px] w-[15.42px]' />
                                        </div>
                                    ) : (
                                        '+ Assign'
                                    )}
                                </button>
                            </AlertDialogTrigger>
                            <SelectCrew setOpen={setOpen} />
                        </AlertDialog>
                        {crewId && (
                            <button
                                onClick={(e) => {
                                    e.preventDefault()
                                    setValue('crewId', '')
                                    queryClient.invalidateQueries({ queryKey: ['crews'] })
                                }}
                                type='button'
                            >
                                <RefreshCw className='text-zentive-blue' />
                            </button>
                        )}
                    </div>
                </div>
                {schedAvailable && (
                    <p className='font-semibold text-zentive-gray-medium'>
                        Please make sure to assign a foreman before saving the job.
                    </p>
                )}
            </CardHeader>

            <CardContent className='p-0'>
                {!crew ? (
                    <p className='p-5 font-medium text-zentive-gray-medium'>
                        No crew are currently assigned
                    </p>
                ) : schedAvailable ? (
                    <SelectedCrew crew={crew} />
                ) : (
                    <ConflictingCrewSchedule />
                )}
            </CardContent>
        </Card>
    )
}
