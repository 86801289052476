import { FullProfileType } from '@/api/profile/schema'
import { getEstimatedTime, getRouteByCrewMemberId } from '@/api/routing'
import { RouteType, OwnerForemanProfileType, RoutePolylineType } from '@/api/routing/schema'
import { userAtom } from '@/store/auth'
import { isPolylineLoadingAtom, routeAtom, routeCrewPolylineAtom } from '@/store/route'
import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue, useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import WrappedCrewRoutingMap from './RouteMap'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import JobList from './JobList'

const CrewRoute = () => {
    const [selectedTab, setSelectedTab] = useState<string>('map')
    const user = useAtomValue(userAtom) as FullProfileType
    const setRoute = useSetAtom(routeAtom)
    const setPolylineList = useSetAtom(routeCrewPolylineAtom)
    const setIsPolylineLoading = useSetAtom(isPolylineLoadingAtom)
    const [latitude, setLatitude] = useState<number>(0)
    const [longitude, setLongitude] = useState<number>(0)

    const { data, isLoading } = useQuery<unknown, AxiosError, RouteType>({
        queryKey: ['route', user.crewMemberId],
        queryFn: () =>
            getRouteByCrewMemberId(user.crewMemberId as string, user.profileId as string),
        enabled: !!user.crewMemberId,
    })

    const onSuccess = (data: RouteType) => {
        setRoute(data)
        if (data.encodedPolyline != '') {
            const profiles = data.jobGroup
                ?.map((profile) => profile.job?.quote.profile)
                .filter((profile): profile is OwnerForemanProfileType => !!profile)

            const polylines: RoutePolylineType = {
                customers: profiles,
                encodedPolyline: data.encodedPolyline!,
            }
            setPolylineList(polylines)
        }
    }

    useEffect(() => {
        if (data) {
            onSuccess(data)
        }
    }, [data])

    useEffect(() => {
        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords
                        setLatitude(latitude)
                        setLongitude(longitude)
                    },
                    () => {},
                )
            }
        }

        getLocation()
    }, [])

    const { data: estimatedTime } = useQuery<unknown, AxiosError, string[]>({
        queryKey: ['route'],
        queryFn: () =>
            getEstimatedTime({
                routeId: data?.routeId,
                foremanLat: latitude,
                foremanLng: longitude,
            }),
        enabled: latitude != 0,
    })

    const handleTabChange = (value: string) => {
        setSelectedTab(value)
    }

    useEffect(() => {
        setIsPolylineLoading(isLoading)
    }, [isLoading])

    return (
        <div className='w-full h-full overflow-hidden'>
            {!data && (
                <div className='mx-4 my-10'>
                    <div className='flex justify-center rounded-md bg-white'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-8 h-60'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-white mt-4'>
                        {' '}
                        {'No Jobs Assigned Today'}{' '}
                    </p>
                </div>
            )}

            {data && (
                <div className='h-full pt-10 '>
                    <Tabs
                        defaultValue='map'
                        onValueChange={handleTabChange}
                        className=' w-[40%] mx-auto h-[5%] '
                    >
                        <TabsList className='grid w-full  grid-cols-2 bg-zentive-blue rounded-sm'>
                            <TabsTrigger
                                value='map'
                                className='text-sm rounded-sm px-6 py-1 data-[state=active]:bg-transparent data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:border data-[state=inactive]:border-zentive-blue data-[state=inactive]:text-zentive-blue'
                            >
                                Map
                            </TabsTrigger>
                            <TabsTrigger
                                value='list'
                                className='text-sm rounded-sm px-6 py-1 data-[state=active]:bg-transparent data-[state=active]:text-white data-[state=inactive]:bg-white data-[state=inactive]:border data-[state=inactive]:border-zentive-blue data-[state=inactive]:text-zentive-blue'
                            >
                                List
                            </TabsTrigger>
                        </TabsList>
                    </Tabs>
                    <div className='text-zentive-green-dark mt-6 text-left ml-10  font-semibold h-[5%]'>
                        Today{' '}
                    </div>
                    <div className=' h-[80%] pb-[70px]'>
                        {selectedTab === 'map' && <WrappedCrewRoutingMap />}
                        {selectedTab === 'list' && <JobList estimatedArrival={estimatedTime} />}
                    </div>
                </div>
            )}
        </div>
    )
}

export default CrewRoute
