import { CrewListResponseType, CrewType } from '@/api/crew/schema'
import { Card, CardContent, CardFooter } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { USER_STATUS } from '@/constants'
import { bulkUpdateCrewStatusAtom } from '@/store/crew'
import { selectedCrewTabStatusAtom } from '@/store/employee'
import { cn } from '@/utils/helper'
import { UseQueryResult } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { FaArrowDownLong } from 'react-icons/fa6'
import { NavLink } from 'react-router-dom'

const tableHeader = ['Crew Number', 'Description', 'Foreman', 'Crew Members']

interface CrewTableProps {
    crews: UseQueryResult<CrewListResponseType>
}

const CrewTable: React.FC<CrewTableProps> = ({ crews }) => {
    const selected = useAtomValue(selectedCrewTabStatusAtom)
    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
    const [crewsToUpdate, setCrewsToUpdate] = useAtom(bulkUpdateCrewStatusAtom)

    const handleCheckClick = (crew: CrewType, isCheck: boolean) => {
        if (isCheck) {
            const updatedCrewIds = [...crewsToUpdate.crewIds, crew.crewId]
            setCrewsToUpdate({
                ...crewsToUpdate,
                crewIds: updatedCrewIds,
                status: selected === 'ACT' ? USER_STATUS.DELETED : USER_STATUS.ACTIVATED,
            })
            setIsCheckAll(crewsToUpdate.crewIds?.length + 1 === crews.data?.content?.length)
        } else {
            const updatedCrewIds = crewsToUpdate.crewIds.filter((id) => id !== crew.crewId)
            setCrewsToUpdate({
                ...crewsToUpdate,
                crewIds: updatedCrewIds,
            })
            setIsCheckAll(crewsToUpdate.crewIds?.length - 1 === crews.data?.content?.length)
        }
    }

    const handleCheckAll = (checked: boolean) => {
        setIsCheckAll(!isCheckAll)
        if (crews && crews.data && checked) {
            const updatedCrewIds = [...crewsToUpdate.crewIds]
            crews.data?.content.forEach((crew) => {
                updatedCrewIds.push(crew.crewId)
            })
            setCrewsToUpdate({
                ...crewsToUpdate,
                crewIds: updatedCrewIds,
                status: selected === 'ACT' ? USER_STATUS.DELETED : USER_STATUS.ACTIVATED,
            })
        } else {
            setCrewsToUpdate({
                crewIds: [],
            })
        }
    }

    return (
        <Card className='overflow-x-scroll green-scrollbar'>
            <CardContent>
                <Table className='table-auto whitespace-normal mx-auto'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader?.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                        { '2xl:w-120 xl:w-100 lg:w-80': header === 'Crew Members' },
                                    )}
                                >
                                    <div className='inline-flex gap-1'>
                                        {header === 'Crew Number' && (
                                            <Checkbox
                                                id={'message-checked-status'}
                                                className='mt-[4px] mr-0'
                                                onCheckedChange={handleCheckAll}
                                                checked={isCheckAll}
                                            />
                                        )}
                                        {header}
                                        {header === 'Crew Number' || header === 'Foreman' ? (
                                            <FaArrowDownLong className='w-3 h-3 mt-[6px]' />
                                        ) : null}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {crews &&
                            crews.data &&
                            crews.data?.content?.map((crew, index) => (
                                <TableRow key={crew.crewNumber} className='whitespace-nowrap'>
                                    <TableCell className=''>
                                        <div className='flex items-center'>
                                            <div className='mt-[4px]'>
                                                <Checkbox
                                                    className='mr-[5px]'
                                                    checked={crewsToUpdate?.crewIds?.some(
                                                        (p) => p === crew.crewId,
                                                    )}
                                                    onCheckedChange={(val) => {
                                                        handleCheckClick(crew, val as boolean)
                                                    }}
                                                />
                                            </div>
                                            <NavLink
                                            className=''
                                            to={`/schedule/crew/update/${crew.crewId}`}
                                            >
                                                <div>{'000' + crew.crewNumber}</div>
                                            </NavLink>
                                        </div>
                                    </TableCell>
                                    <TableCell className='text-[#191A0A] text-base max-w-[150px] text-ellipsis overflow-hidden'>
                                        <NavLink
                                            className=''
                                            to={`/schedule/crew/update/${crew.crewId}`}
                                        >
                                            <p>{crew.description}</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className=''>
                                        <NavLink
                                            className=''
                                            to={`/schedule/crew/update/${crew.crewId}`}
                                        >
                                            {crew && crew.foreMan
                                                ? crew.foreMan?.crewProfile?.firstName +
                                                ' ' +
                                                crew.foreMan?.crewProfile?.lastName
                                                : 'N/A'}
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='flex flex-row text-[#191A0A] text-base'>
                                        {crew &&
                                            crew.members &&
                                            crew.members.slice(0, 5)?.map((member) => (
                                                <NavLink
                                                className=''
                                                to={`/schedule/crew/update/${crew.crewId}`}
                                                >
                                                <div key={member.crewId}>
                                                    {member.crewProfile?.profileImage ? (
                                                        <img
                                                            src={
                                                                typeof member.crewProfile
                                                                    ?.profileImage === 'string'
                                                                    ? member.crewProfile
                                                                          ?.profileImage
                                                                    : ''
                                                            }
                                                            alt={`Profile ${index + 1}`}
                                                            className='h-[26px] w-[26px] rounded-full mr-1'
                                                            title={`${member.crewProfile?.firstName} ${member.crewProfile?.lastName}`}
                                                        />
                                                    ) : (
                                                        <div
                                                            className='h-[26px] w-[26px] rounded-full bg-gray-300 flex justify-center items-center mr-1'
                                                            title={`${member.crewProfile?.firstName} ${member.crewProfile?.lastName}`}
                                                        >
                                                            <span className='text-[#191A0A] text-base'>
                                                                {`${
                                                                    member.crewProfile?.firstName
                                                                        ?.charAt(0)
                                                                        ?.toUpperCase() ?? ''
                                                                }${
                                                                    member.crewProfile?.lastName
                                                                        ?.charAt(0)
                                                                        ?.toUpperCase() ?? ''
                                                                }`}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                </NavLink>
                                            ))}
                                        {crew && crew.foreMan && (
                                            <NavLink
                                             className=''
                                             to={`/schedule/crew/update/${crew.crewId}`}
                                            >
                                            <div>
                                                {crew.foreMan.crewProfile?.profileImage ? (
                                                    <img
                                                        src={
                                                            typeof crew.foreMan.crewProfile
                                                                ?.profileImage === 'string'
                                                                ? crew.foreMan.crewProfile
                                                                      ?.profileImage
                                                                : ''
                                                        }
                                                        alt={`Profile ${index + 1}`}
                                                        className='h-[26px] w-[26px] rounded-full mr-1'
                                                        title={`${crew.foreMan.crewProfile?.firstName} ${crew.foreMan.crewProfile?.lastName}`}
                                                    />
                                                ) : (
                                                    <div
                                                        className='h-[26px] w-[26px] rounded-full bg-gray-300 flex justify-center items-center mr-1'
                                                        title={`${crew.foreMan.crewProfile?.firstName} ${crew.foreMan.crewProfile?.lastName}`}
                                                    >
                                                        <span className='text-[#191A0A] text-base'>
                                                            {`${
                                                                crew.foreMan.crewProfile?.firstName
                                                                    ?.charAt(0)
                                                                    ?.toUpperCase() ?? ''
                                                            }${
                                                                crew.foreMan.crewProfile?.lastName
                                                                    ?.charAt(0)
                                                                    ?.toUpperCase() ?? ''
                                                            }`}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            </NavLink>
                                        )}
                                        {crew && crew.members && crew.members?.length > 5 && (
                                             <NavLink
                                             className=''
                                             to={`/schedule/crew/update/${crew.crewId}`}
                                            >
                                                <div className='flex flex-end'>...</div>
                                            </NavLink>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </CardContent>
            <CardFooter className='items-end justify-end'></CardFooter>
        </Card>
    )
}
export default CrewTable
