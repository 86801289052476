import { Dispatch, SetStateAction, useState } from 'react'
import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { JOB_STATUS, JOB_STATUS_LABEL } from '@/constants'
import { Checkbox } from '@/components/Checkbox'
import { useAtom } from 'jotai'
import { jobStatusFilterAtom } from '@/store/job'
import { JobStatusType, JobFilterStatusType } from '@/api/job/schema'

interface JobStatusFilterModalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
}

const JobStatusFilterModal: React.FC<JobStatusFilterModalProps> = ({ open, setOpen }) => {
    const [statusFilter, setStatusFilter] = useAtom(jobStatusFilterAtom)
    const [selectedStatus, setSelectedStatus] = useState<JobFilterStatusType | null>(statusFilter)
    const [unCheckAll, setUncheckAll] = useState<boolean>(false)

    const handleCheck = (status: JobStatusType, isChecked: boolean) => {
        if (isChecked) {
            const updatedStatus = selectedStatus ? [...selectedStatus, status] : [status]
            setSelectedStatus(updatedStatus as JobFilterStatusType)
        } else {
            const updatedStatus = selectedStatus?.filter((s) => s !== status) || []
            setSelectedStatus(updatedStatus?.length ? updatedStatus : null)
        }
    }

    const handleApply = () => {
        setStatusFilter(selectedStatus)
        setUncheckAll(false)
        setOpen(false)
    }

    const handleClear = () => {
        setUncheckAll(false)
        setSelectedStatus(null)
    }

    const handleClose = () => {
        setSelectedStatus(null)
        setOpen(false)
    }

    return (
        <Modal
            isOpen={open}
            isHideCloseButton
            onClose={() => {
                setOpen(false)
            }}
            title=''
            titleClassName=''
            containerClassName='max-w-[600px] p-0'
        >
            <div className='flex gap-5 px-10'>
                <div className=''>
                    <h1 className='text-left font-semibold text-2xl text-gray-900 capitalize'>
                        Filter
                    </h1>
                    <p className='text-left text-md font-semibold text-gray-500 mt-5'>Job Status</p>

                    <div className='overflow-auto max-h-50'>
                        <ul>
                            {Object.entries(JOB_STATUS)?.map(([key, status]) => (
                                <li key={key} className='flex flex-row gap-2 mt-4 text-base'>
                                    <Checkbox
                                        className='mt-1'
                                        onCheckedChange={(val) =>
                                            handleCheck(status as JobStatusType, val as boolean)
                                        }
                                        checked={selectedStatus?.includes(status) || unCheckAll}
                                    />
                                    <p>{JOB_STATUS_LABEL[status]}</p>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='mt-6 flex justify-between bg-gray-300 py-6 px-6'>
                <Button
                    variant={'link'}
                    className='text-zentive-blue font-bold text-lg'
                    onClick={handleClear}
                >
                    Clear Filters
                </Button>
                <div className='flex gap-x-4'>
                    <Button
                        onClick={handleClose}
                        className='w-97 h-11 text-base font-semibold cursor-pointer'
                        variant={'outline'}
                    >
                        Cancel
                    </Button>

                    <Button
                        className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium cursor-pointer'
                        onClick={handleApply}
                    >
                        Apply
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default JobStatusFilterModal
