import {
    JobCimpletionFilterType,
    JobCompletionExportType,
    JobsCompletedByEmployeeExportType,
    JobsCompletedByEmployeeFilterType,
    NetGrossExportType,
    NetGrossFilterType,
    NetGrossTabsType,
} from '@/api/reports/schema'
import { NET_GROSS_TABS } from '@/constants'
import { atom } from 'jotai'

export const netGrosstabsATom = atom<NetGrossTabsType>(NET_GROSS_TABS.gross)
export const netGrossFilterAtom = atom<NetGrossFilterType | null>(null)
export const netGrossExportAtom = atom<NetGrossExportType | null>(null)

export const jobCompletionExportAtom = atom<JobCompletionExportType | null>(null)
export const jobCompletionFilterAtom = atom<JobCimpletionFilterType | null>(null)

export const jobCompletedByEmployeeFilterAtom = atom<JobsCompletedByEmployeeFilterType | null>(null)
export const jobCompletedByEmployeeExportAtom = atom<JobsCompletedByEmployeeExportType | null>(null)
