import { RouteParamType } from '@/api/routing/schema'
import { routeJobListAtom } from '@/store/route'
import { useAtom } from 'jotai'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { useFormContext } from 'react-hook-form'
import { RxDragHandleDots2 } from 'react-icons/rx'

const SortableList: React.FC = () => {
    const [routeJobList, setRouteJobList] = useAtom(routeJobListAtom)
    const { setValue } = useFormContext<RouteParamType>()

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return

        // setValue('jobGroup', [])
        const items = Array.from(routeJobList!)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        setRouteJobList(items)
        const updatedJobGroups = items?.map((job) => ({ jobId: job.jobId! }))
        setValue('jobGroup', updatedJobGroups)
    }

    const formatTime = (dateTimeString: string): string => {
        const date = new Date(dateTimeString)
        const options: Intl.DateTimeFormatOptions = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }
        return date.toLocaleTimeString('en-US', options)
    }

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='tasks'>
                {(provided) => (
                    <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className=' list-none p-0 max-h-[500px] overflow-y-auto gray-scrollbar'
                    >
                        {routeJobList ? (
                            routeJobList?.map(({ quote, address, appointment, jobId }, index) => (
                                <Draggable
                                    key={jobId ? jobId : '0'}
                                    draggableId={jobId ? jobId : '0'}
                                    index={index}
                                >
                                    {(provided) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className='select-none p-0 mb-1 bg-white rounded shadow-sm '
                                            style={{ ...provided.draggableProps.style }}
                                        >
                                            <div className='flex flex-row'>
                                                <RxDragHandleDots2 className='my-auto w-[8%]' />
                                                <div className=' bg-zentive-blue w-[12%] text-white  items-center justify-center flex'>
                                                    <span className=''> {index + 1} </span>
                                                </div>
                                                <div className='flex-grow w-[80%] m-4'>
                                                    <p className='text-base font-semibold'>
                                                        {quote.profile?.firstName}{' '}
                                                        {quote.profile?.lastName}
                                                    </p>
                                                    <p className='text-sm text-zentive-gray-medium'>
                                                        {quote.productServiceGroup?.map(
                                                            (item, index) => (
                                                                <span key={index}>
                                                                    {item.name}
                                                                    {index <
                                                                        quote.productServiceGroup
                                                                            ?.length -
                                                                            1 && ', '}
                                                                </span>
                                                            ),
                                                        )}
                                                    </p>
                                                    <p className='text-sm text-zentive-gray-medium'>
                                                        {address?.streetAddress} {address?.city},{' '}
                                                        {address?.state}, {address?.zipCode}
                                                    </p>
                                                    <p className='text-sm text-zentive-gray-medium'>
                                                        Scheduled Time:{' '}
                                                        {formatTime(appointment.startDateTime)}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </Draggable>
                            ))
                        ) : (
                            <></>
                        )}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default SortableList
