import { NOT_FOUND_ERRORS } from '@/constants'
import { axiosInstance } from '../axiosInstance'

type SyncResponse = {
    customersCount: number
    invoicesCount: number
}

export const authorizeQuickBooks = async (profileId: string | undefined): Promise<string> => {
    if (!profileId) {
        throw new Error(NOT_FOUND_ERRORS.PROFILE_ID)
    }

    const res = await axiosInstance.get(`/quickbooks/authorize/${profileId}`)

    return res.data.content
}

export const checkQuickBooksIntegration = async (
    profileId: string | undefined,
): Promise<boolean> => {
    if (!profileId) {
        throw new Error(NOT_FOUND_ERRORS.PROFILE_ID)
    }

    const res = await axiosInstance.get(`/quickbooks/check-integration/${profileId}`)

    return res.data.content
}

export const syncCustomersAndInvoices = async (
    profileId: string | undefined,
): Promise<SyncResponse> => {
    if (!profileId) {
        throw new Error(NOT_FOUND_ERRORS.PROFILE_ID)
    }

    const res = await axiosInstance.post(`/quickbooks/sync/customers-invoices/${profileId}`)

    return res.data.content
}

export const unsyncedCustomersAndInvoices = async (
    profileId: string | undefined,
): Promise<SyncResponse> => {
    if (!profileId) {
        throw new Error(NOT_FOUND_ERRORS.PROFILE_ID)
    }

    const res = await axiosInstance.get(`/quickbooks/unsynced/customers-invoices/${profileId}`)

    return res.data.content
}
