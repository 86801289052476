import 'react-datepicker/dist/react-datepicker.css'
import { Card, CardContent, CardFooter } from '@/components/Card'
import SortableList from './SortableList'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { CreateJobType } from '@/api/job/schema'
import { getCounts, getJobsPolyline } from '@/api/routing'
import { ArrangementAndOptimizeType, RouteParamType, RoutePolylineType } from '@/api/routing/schema'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { isPolylineLoadingAtom, routePolylineAtom } from '@/store/route'
import { useFormContext } from 'react-hook-form'
import Tooltip from '@/components/Tooltip'
import { IoIosInformationCircle } from 'react-icons/io'
import { Checkbox } from '@/components/Checkbox'
import { isViewedAsAtom, viewedAsUserAtom, userAtom } from '@/store/auth'
import { useState, useEffect } from 'react'
import ApplyAlert from './ApplyAlert'

type JobListProps = {
    routeId: string | undefined
}

const JobList: React.FC<JobListProps> = ({ routeId }) => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const setPolylineList = useSetAtom(routePolylineAtom)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [isPolylineLoading, setIsPolylineLoading] = useAtom(isPolylineLoadingAtom)
    const { setValue } = useFormContext<RouteParamType>()

    const { mutate: getPolylineMu } = useMutation<RoutePolylineType, AxiosError, CreateJobType[]>({
        mutationFn: (jobs) => {
            setIsPolylineLoading(true)
            return getJobsPolyline(jobs)
        },
        onSuccess: (data: RoutePolylineType) => {
            setValue('encodedPolyline', data?.encodedPolyline ? data?.encodedPolyline : '')
            refetch()
            setPolylineList(data)

            setIsPolylineLoading(false)
        },
        onError: () => {
            setIsPolylineLoading(false)
        },
    })

    const { data: counts, refetch } = useQuery<unknown, AxiosError, ArrangementAndOptimizeType>({
        queryKey: ['counts'],
        queryFn: () => getCounts(user?.businessId as string),
    })

    useEffect(() => {
        if (counts?.arrangementCount! > 0 && !isPolylineLoading) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [counts, isPolylineLoading])

    return (
        <div className=' flex flex-col w-[30%] mr-4'>
            <div className='w-full flex flex-row text-zentive-blue font-semibold bg-zentive-gray-light pl-4 py-2 rounded-t-xl items-center'>
                <span>Jobs</span>
                <Tooltip
                    position='bottom'
                    alignment='start'
                    content={
                        <span>
                            Drag the tiles below to re-arrange the stops then click
                            <span className=' text-zentive-green-medium'> "Apply Changes" </span>
                            below to update the map.
                        </span>
                    }
                    fontstyle='font-sans'
                    width='64'
                >
                    <IoIosInformationCircle className=' text-black ml-2 h-5 w-5' />
                </Tooltip>
            </div>
            <Card className=' w-full rounded-none border-none'>
                <CardContent className='p-0'>
                    <SortableList routeId={routeId} />
                </CardContent>
                <CardContent className='p-2 flex items-center bg-zentive-gray-light mb-[0.5px]'>
                    <Checkbox
                        className='ml-2  accent-zentive-green-dark'
                        onCheckedChange={(val) => {
                            // @ts-ignore
                            setValue('isSavedPermanently', Boolean(val))
                        }}
                    />
                    <span className='ml-2 text-base'>Save This Route Permanently</span>
                    <Tooltip
                        position='bottom'
                        alignment='start'
                        content={
                            <span>
                                By saving this route permanently, it will be listed for future
                                occurrences. You can still modify it later.
                            </span>
                        }
                        fontstyle='font-sans'
                        width='64'
                    >
                        <IoIosInformationCircle className=' text-black ml-2 h-5 w-5' />
                    </Tooltip>
                </CardContent>
                <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB] pr-4 py-2 '>
                    <div className='flex flex-col items-center justify-center mx-auto mt-2'>
                        <div className='flex flex-row items-center justify-center mx-auto '>
                            <ApplyAlert
                                count={counts?.arrangementCount!}
                                getPolylineMu={getPolylineMu}
                                isDisabled={isDisabled}
                            />
                            <Tooltip
                                position='bottom'
                                alignment='start'
                                content={
                                    <span>
                                        You only have 10 chances a day to arrange your route. Click
                                        "Apply Changes" to update the map. Then click the "Save"
                                        button below to save your route.
                                    </span>
                                }
                                fontstyle='font-sans'
                                width='64'
                            >
                                <IoIosInformationCircle className='text-black ml-2 h-6 w-6 ' />
                            </Tooltip>
                        </div>
                        <span className=' text-sm text-zentive-gray-medium mt-2'>
                            Remaining arrangements for today: {counts?.arrangementCount} of 10
                        </span>
                    </div>
                </CardFooter>
            </Card>
        </div>
    )
}

export default JobList
