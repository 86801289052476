import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import { APPOINTMENT_TYPES, SHOULD_VALIDATE_TRUE } from '@/constants'
import { cn } from '@/utils/helper'
import { useFormContext } from 'react-hook-form'

const TAB_TRIGGER_CLASS =
    'w-full h-[54px] bg-white hover:bg-gray-200 hover:font-semibold flex justify-center text-[18px] text-zentive-gray-medium py-12'
const TAB_TRIGGER_CLASS_2 =
    'data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4 py-12'

const TAB_TRIGGER_CLASSES = cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS_2)

const TabTriggerContent = ({ type }: { type: string }) => {
    const isOneOff = type === APPOINTMENT_TYPES.ONEOFF_JOB
    const title = isOneOff ? 'One-Off Job' : 'Recurring Job'

    return (
        <span className='text-left'>
            <h1 className='font-bold text-xl'>{title}</h1>
        </span>
    )
}

export const JobType = () => {
    const { setValue } = useFormContext()

    const handleTabChange = (val: string) => {
        setValue('appointment.appointmentType', val, SHOULD_VALIDATE_TRUE)
        if (val === APPOINTMENT_TYPES.ONEOFF_JOB) {
            setValue('appointment.occurrences', 1, SHOULD_VALIDATE_TRUE)
        }
    }

    return (
        <Tabs
            defaultValue={APPOINTMENT_TYPES.ONEOFF_JOB}
            onValueChange={handleTabChange}
            className='mt-[26px] py-5'
        >
            <TabsList className='w-full mt-[26px]'>
                <TabsTrigger value={APPOINTMENT_TYPES.ONEOFF_JOB} className={TAB_TRIGGER_CLASSES}>
                    <TabTriggerContent type={APPOINTMENT_TYPES.ONEOFF_JOB} />
                </TabsTrigger>
                <TabsTrigger value={APPOINTMENT_TYPES.RECURRING} className={TAB_TRIGGER_CLASSES}>
                    <TabTriggerContent type={APPOINTMENT_TYPES.RECURRING} />
                </TabsTrigger>
            </TabsList>
        </Tabs>
    )
}
