import { NavigationProps } from '@/types/NavProps'

const RESTRICTED_PAGES = ['Invoices', 'Refunds', 'Payments', 'Reports']

export const getAllowedNavigationItems = (
    navigationItems: NavigationProps[],
    isConnectedToStripe: boolean = false,
    roleName: string = '',
): NavigationProps[] => {
    const filteredNavigationItems = navigationItems
        ?.map((item) => {
            if (
                item.allowedRoles.includes(roleName) &&
                isConnectedToStripe === false &&
                RESTRICTED_PAGES.includes(item.name)
            ) {
                return null
            }

            const filteredChildren = item.children?.filter(
                (child) =>
                    child.allowedRoles.includes(roleName) &&
                    !(isConnectedToStripe === false && child.name === 'Invoicing'),
            )

            const isParentAllowed =
                item.allowedRoles.includes(roleName) &&
                !(isConnectedToStripe === false && RESTRICTED_PAGES.includes(item.name))

            if (isParentAllowed || (filteredChildren && filteredChildren?.length > 0)) {
                return {
                    ...item,
                    children: filteredChildren,
                }
            }

            return null
        })
        .filter((item) => item !== null) as NavigationProps[]

    return filteredNavigationItems
}
