import { useState } from "react";
import { FAQS_SECTIONS } from "@/assets/static/privacyDocument"
import { FaChevronDown, FaChevronUp, FaPlus, FaMinus } from "react-icons/fa";


const Faqs = () => {

    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const [allExpanded, setAllExpanded] = useState(false);

    const handleToggle = (index: number) => {
        if (index === expandedIndex) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };

    const handleExpandCollapseAll = () => {
        setAllExpanded(!allExpanded);
        setExpandedIndex(!allExpanded ? 0 : null);
    };

    return (
        <div className='flex justify-center items-center z-0 crew-sign-in-bg w-full h-full relative'>
            <div className='flex flex-col justify-center sign-in-overlay w-full mx-[400px] my-20'>
                <h1 className='text-zentive-green-dark text-center font-semibold text-2xl'> Frequently Asked Questions </h1>
                <button
                    className='self-end px-4 py-2 text-zentive-blue underline px-8 mt-4 mb-2 flex flex-row items-center gap-4'
                    onClick={handleExpandCollapseAll}
                >
                    {allExpanded ? <> Collapse All <FaChevronUp /> </> :  <> Expand All <FaChevronDown /> </>}
                </button>
                <ol className='list-decimal list-inside mt-4 text-left w-full px-8'>
                    {FAQS_SECTIONS?.map((section, index) => (
                        <li key={index} className='flex flex-col mb-4'>
                            <div
                                className={`flex items-center justify-between mb-2 cursor-pointer`}
                                onClick={() => handleToggle(index)}
                            >
                                <h2 className={`font-semibold  hover:text-zentive-blue ${expandedIndex === index || allExpanded ? 'text-zentive-blue' : 'text-zentive-gray-medium'}`}>{section.title}</h2>
                                <span className='text-zentive-gray-medium'>
                                    {expandedIndex === index || allExpanded ? <FaMinus /> : <FaPlus />}
                                </span>
                            </div>
                            {(expandedIndex === index || allExpanded) && (
                                <div className='text-zentive-black'>
                                    {section.content}
                                </div>
                            )}
                        </li>
                    ))}
                </ol>
            </div>
        </div>
    )
}

export default Faqs
