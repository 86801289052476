import { cn } from '@/utils/helper'
import { VscTriangleRight } from 'react-icons/vsc'
import { IoIosPause } from 'react-icons/io'
import { FaSquare } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import ConfirmationModal from '../../../Common/ConfirmationModal'
import { useState } from 'react'
import {
    CreateCrewMemberDTRParams,
    createCrewMemberDtr,
    getCrewMemberDtr,
    updateJobStatus,
} from '@/api/job'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'
import { JobDtrType, ReadJobType, UpdateJobStatusType } from '@/api/job/schema'
import { useAtomValue, useSetAtom } from 'jotai'
import { timezoneAtom, userAtom } from '@/store/auth'
import dayjs from 'dayjs'
import {
    // FEATURE_RESTRICTIONS,
    ISO_8601_WITH_UTC_OFFSET,
    ISO_8601_WITH_UTC_OFFSET_IOS,
    JOB_STATUS,
} from '@/constants'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { isCrewJobsInitialFetch, selectedDateAtom, selectedDateFilterAtom } from '@/store/crew'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { formatDateTime } from '../../JobGallery/JobDateFilter/DateSelector'
// import { AxiosError } from 'axios'
// import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import CrewFeatureRestrictionModal from '@/components/CrewFeatureRestrictionModal'
import CompleteJobModal from '../../../Common/CompleteJobModal'
import { formatToUTCWithOffset } from '@/utils/time'
import { isIOS, isMacOs } from 'react-device-detect'

dayjs.extend(utc)
dayjs.extend(timezone)

interface IProgressButtonsProps {
    job: ReadJobType
    featureAvailable: boolean
}

const ProgressButtons = ({ job, featureAvailable }: IProgressButtonsProps) => {
    const queryClient = useQueryClient()
    const currentDate = isIOS || isMacOs ? Date.now() : dayjs().set('second', 0).format(ISO_8601_WITH_UTC_OFFSET)
    const { t } = useTranslation(['view-job', 'common'])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isCompleteJobModalOpen, setIsCompleteJobModalOpen] = useState<boolean>(false)
    const [actionType, setActionType] = useState<string>('')
    const { toast } = useToast()
    const user = useAtomValue(userAtom)
    const isUserForeman = user?.crewMemberId === job?.foreman?.crewMemberId
    const setHasFetchedInitialJobs = useSetAtom(isCrewJobsInitialFetch)
    const setSelectedDate = useSetAtom(selectedDateAtom)
    const setSelectedDateFilter = useSetAtom(selectedDateFilterAtom)
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const customerTimezone = job?.address?.timezone || "Asia/Singapore"
    const dtrData = queryClient.getQueryData<JobDtrType[]>(["crewMemberDtr"]) ?? []


    const getReturnJobCrewMemberId = (job: ReadJobType) => {
        if (job) {
            const foreman = job?.foreman
            const jobCrewMembers = job?.jobCrewMembers
            if (foreman?.crewMemberId === user?.crewMemberId) {
                return foreman?.jobCrewMemberId
            }
            for (const member of jobCrewMembers) {
                if (member?.crewMemberId === user?.crewMemberId) {
                    return member?.jobCrewMemberId
                }
            }
            return ''
        }

        return ''
    }

    const getCrewLastJobProgressAction =
        job?.jobCrewMembers &&
        job?.jobCrewMembers?.filter((crewMember) => crewMember?.crewMemberId === user?.crewMemberId)

    const jobCrewMemberId = getReturnJobCrewMemberId(job)

    const { data: crewMemberDtr, isLoading } = useQuery({
        queryKey: ['crewMemberDtr'],
        queryFn: () => getCrewMemberDtr(jobCrewMemberId),
        retry: 0,
    })

    const { mutate: updateJobStatusMu, isPending: isJobStatusUpdatePending } = useMutation({
        mutationFn: (data: UpdateJobStatusType) => updateJobStatus(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            setIsOpen(false)
        },
        onError: () =>
            toast({
                description: t(`Something went wrong, please try again.`),
                variant: 'crew_interface_destructive',
                duration: 2000,
            }),
    })

    const isJobRenderingStarted = () => {
        if (isUserForeman) {
            if (
                job?.foreman?.dailyTimeRecord &&
                job?.foreman?.dailyTimeRecord[job?.foreman?.dailyTimeRecord?.length - 1]?.type ==
                    'start'
            ) {
                return true
            } else {
                return false
            }
        } else {
            if (
                getCrewLastJobProgressAction?.length >= 1 &&
                getCrewLastJobProgressAction[0].dailyTimeRecord &&
                getCrewLastJobProgressAction[0].dailyTimeRecord[
                    getCrewLastJobProgressAction[0].dailyTimeRecord?.length - 1
                ]?.type == 'start'
            ) {
                return true
            } else {
                return false
            }
        }
    }

    const isJobPaused = () => {
        if (isUserForeman) {
            if (
                job?.foreman?.dailyTimeRecord &&
                job?.foreman?.dailyTimeRecord[job?.foreman?.dailyTimeRecord?.length - 1]?.type ===
                    'pause'
            ) {
                return true
            } else {
                return false
            }
        } else {
            if (
                getCrewLastJobProgressAction?.length >= 1 &&
                getCrewLastJobProgressAction[0]?.dailyTimeRecord &&
                getCrewLastJobProgressAction[0]?.dailyTimeRecord[
                    getCrewLastJobProgressAction[0]?.dailyTimeRecord?.length - 1
                ]?.type == 'pause'
            ) {
                return true
            } else {
                return false
            }
        }
    }

    const isJobRenderingEnded = () => {
        if (crewMemberDtr) {
            if (crewMemberDtr?.[crewMemberDtr?.length - 1]?.type === 'end') {
                return true
            }
            return false
        }

        return false
    }

    const isJobRenderingNotFinished = () => {
        if (crewMemberDtr) {
            if (crewMemberDtr?.[crewMemberDtr?.length - 1]?.type === 'pause') {
                return true
            }
            return false
        }

        return false
    }

    const handleTypeText = (type: string) => {
        switch (type) {
            case 'start':
                return { text: 'Start', success: 'started', status: JOB_STATUS.IN_PROGRESS }
            case 'pause':
                return { text: 'Pause', success: 'paused', status: JOB_STATUS.IN_PROGRESS }
            case 'end':
                return { text: 'End', success: 'ended', status: JOB_STATUS.COMPLETED }
            default:
                return { text: 'Start', success: 'started' }
        }
    }

    const checkTime = (data: CreateCrewMemberDTRParams) => {
        // if (actionType === "start") {
        //     if (dtrData?.length == 0) {
                
        //         const createCrew = createCrewMemberDtr(data)
        //         return createCrew
        //     } else {
        //         const formattedDtr = dtrData
        //         .filter((obj) => obj && obj.type === "pause")
        //         .sort((a, b) => {
        //             if (a?.createdAt && b?.createdAt) {
        //                 return b.createdAt > a.createdAt ? 1 : -1;
        //             }
        //             return 0; // or handle undefined case as needed
        //         })[0] || null;
        //         const sameTime = isTimeSame(formattedDtr.time)

        //         if (sameTime) {
        //             throw new Error("Start time cannot be the same time as pause time")
        //         }
        //     }
        // } 
        
        // if (actionType === "pause") {
        //     const formattedDtr = dtrData
        //         .filter((obj) => obj && obj.type === "start")
        //         .sort((a, b) => {
        //             if (a?.createdAt && b?.createdAt) {
        //                 return b.createdAt > a.createdAt ? 1 : -1;
        //             }
        //             return 0; // or handle undefined case as needed
        //         })[0] || null;
        //            const sameTime = isTimeSame(formattedDtr.time)

        //         if (sameTime) {
        //             throw new Error("Pause time cannot be the same time as start time")
        //         }
        // }

        // if (actionType === "end") {
        //     const formattedDtr = dtrData
        //         .filter((obj) => obj && obj.type === "start")
        //         .sort((a, b) => {
        //             if (a?.createdAt && b?.createdAt) {
        //                 return b.createdAt > a.createdAt ? 1 : -1;
        //             }
        //             return 0; // or handle undefined case as needed
        //         })[0] || null;
   
        //         const sameTime = isTimeSame(formattedDtr.time)

        //         if (sameTime) {
        //             throw new Error("End time cannot be the same time as start time")
        //         }
        // }

        const storedJobID = localStorage.getItem("storedJobID")

        if (!storedJobID) {
            const createCrew = createCrewMemberDtr(data)
            localStorage.setItem("utcStoredTime", new Date().toISOString())
            localStorage.setItem("storedJobID", job.jobId?.toString() || "")
            return createCrew
        } else {
            if (storedJobID !== job.jobId?.toString()) {
                const createCrew = createCrewMemberDtr(data)
                localStorage.setItem("utcStoredTime", new Date().toISOString())
                localStorage.setItem("storedJobID", job.jobId?.toString() || "")
                return createCrew
            }
        }
        
        if (dtrData.length == 0) {
            const createCrew = createCrewMemberDtr(data)
            localStorage.setItem("utcStoredTime", new Date().toISOString())
            return createCrew
        }

        const storedUTC = localStorage.getItem("utcStoredTime")

        if (storedUTC) {
            const currentUTC = new Date().toISOString()

            const storedDate = new Date(storedUTC)
            const currentDate = new Date(currentUTC)
            const storedMinutes = storedDate.getUTCMinutes()
            const currentMinutes = currentDate.getUTCMinutes()
            if (storedMinutes !== currentMinutes) {
                const createCrew = createCrewMemberDtr(data)
                localStorage.setItem("utcStoredTime", new Date().toISOString())
                return createCrew
            } else {
                throw new Error("Start, pause and end cannot be the same time")
            }
        } else {
            const createCrew = createCrewMemberDtr(data)
            localStorage.setItem("utcStoredTime", new Date().toISOString())
            return createCrew
        }
    }

    const { mutate: createCrewMemberTimeRecordMu, isPending } = useMutation({
        mutationFn: (data: CreateCrewMemberDTRParams) => checkTime(data),
        onSuccess: (res: JobDtrType) => {
            queryClient.invalidateQueries({ queryKey: ['crewMemberDtr'] })
            queryClient.invalidateQueries({ queryKey: ['jobById', job?.jobId] })
            setIsOpen(false)
            toast({
                description: t(`Job has been ${handleTypeText(actionType).success} successfully`),
                variant: 'crew_interface',
                duration: 2000,
            })
            if (!isJobStatusUpdatePending && isUserForeman && res?.type !== 'pause') {
                updateJobStatusMu({
                    jobId: res?.jobId,
                    status: handleTypeText(res.type).status as string,
                })
                setHasFetchedInitialJobs(false)
            }
            setSelectedDate({
                startDate: formatDateTime(
                    dayjs().startOf('day').toString(),
                    timezone?.timeZoneId as string,
                    ISO_8601_WITH_UTC_OFFSET_IOS,
                ),
                endDate: formatDateTime(
                    dayjs().endOf('day').toString(),
                    timezone?.timeZoneId as string,
                    ISO_8601_WITH_UTC_OFFSET_IOS,
                ),
            })

            setSelectedDateFilter({
                startDate: formatDateTime(
                    dayjs().startOf('day').toString(),
                    timezone?.timeZoneId as string,
                    ISO_8601_WITH_UTC_OFFSET_IOS,
                ),
                endDate: formatDateTime(
                    dayjs().endOf('day').toString(),
                    timezone?.timeZoneId as string,
                    ISO_8601_WITH_UTC_OFFSET_IOS,
                ),
            })
        },
        onError: () => {
            toast({
                description: t('Start, pause and end time must not be in the same time'),
                variant: 'crew_interface_destructive',
                duration: 2000,
            }),
                setIsOpen(false)
        },
    })

    return (
        <div className={cn('w-full max-w-[1024px] fixed bottom-0 bg-gray-500 mx-auto z-20')}>
            <nav className='flex flex-row space-x-2 justify-center items-center border-t border-gray-300 w-full h-20 bg-white px-1 sm:px-4'>
                <button
                    disabled={
                        isJobRenderingEnded() ||
                        isJobRenderingStarted() ||
                        job?.status === JOB_STATUS.CANCELED ||
                        job?.status === JOB_STATUS.COMPLETED ||
                        isLoading
                    }
                    onClick={() => {
                        setRestrictionModal(!featureAvailable)
                        if (featureAvailable) {
                            setActionType('start')
                        }
                        
                        setIsOpen(featureAvailable)

                         // 60 seconds
                    }}
                >
                    <div
                        className={cn(
                            'flex flex-col items-center rounded-md px-7 py-0.5',
                            isJobRenderingEnded() ||
                                isJobRenderingStarted() ||
                                job?.status === JOB_STATUS.CANCELED ||
                                job?.status === JOB_STATUS.COMPLETED
                                ? 'bg-gray-400'
                                : 'bg-zentive-green-dark',
                        )}
                    >
                        <VscTriangleRight className='w-5 h-5 items-center text-white pt-1' />
                        <p className='text-white'>{t('Start')}</p>
                    </div>
                </button>
                <button
                    disabled={
                        isJobPaused() ||
                        isJobRenderingEnded() ||
                        job?.status === JOB_STATUS.CANCELED ||
                        job?.status === JOB_STATUS.COMPLETED ||
                        job?.status === JOB_STATUS.ACTIVE ||
                        isLoading
                    }
                    onClick={() => {
                        setRestrictionModal(!featureAvailable)
                        if (featureAvailable) {
                            setActionType('pause')
                        }
                        if (job?.status == JOB_STATUS.IN_PROGRESS) {
                            setIsOpen(featureAvailable)
                        } else {
                            setIsOpen(featureAvailable)
                        }

                    }}
                >
                    <div
                        className={cn(
                            'flex flex-col items-center bg-zentive-blue rounded-md px-7 py-0.5',
                            isJobRenderingEnded() ||
                                isJobPaused() ||
                                job?.status === JOB_STATUS.CANCELED ||
                                job?.status === JOB_STATUS.COMPLETED ||
                                job?.status === JOB_STATUS.ACTIVE
                                ? 'bg-gray-400'
                                : 'bg-zentive-blue',
                        )}
                    >
                        <IoIosPause className='w-5 h-5 text-white pt-1' />
                        <p className='text-white'>{t('Pause')}</p>
                    </div>
                </button>
                <button
                    disabled={
                        isJobRenderingEnded() ||
                        isJobRenderingNotFinished() ||
                        job?.status === JOB_STATUS.CANCELED ||
                        job?.status === JOB_STATUS.COMPLETED ||
                        job?.status === JOB_STATUS.ACTIVE ||
                        isLoading
                    }
                    onClick={() => {
                        setRestrictionModal(!featureAvailable)
                        if (featureAvailable) {
                            setActionType('end')
                        }
                        if (job?.status == JOB_STATUS.IN_PROGRESS) {
                            if (isUserForeman) {
                                setIsCompleteJobModalOpen(featureAvailable)
                            } else {
                                setIsOpen(featureAvailable)
                            }
                        } else {
                            setIsOpen(featureAvailable)
                        }
                    }}
                >
                    <div
                        className={cn(
                            'flex flex-col items-center bg-zentive-red-dark rounded-md px-8 py-0.5',
                            isJobRenderingEnded() ||
                                isJobRenderingNotFinished() ||
                                job?.status === JOB_STATUS.CANCELED ||
                                job?.status === JOB_STATUS.COMPLETED ||
                                job?.status === JOB_STATUS.ACTIVE
                                ? 'bg-gray-400'
                                : 'bg-zentive-red-dark',
                        )}
                    >
                        <FaSquare className='w-5 h-5 text-white pt-1' />
                        <p className='text-white'>{t('End')}</p>
                    </div>
                </button>
            </nav>
            <ConfirmationModal
                isOpen={isOpen}
                title={t(`Are you sure you want to ${t(actionType)} this job?`)}
                text={t(handleTypeText(actionType).text)}
                onClose={() => setIsOpen(false)}
                onProceed={() => {
                    if (!isPending) {
                        createCrewMemberTimeRecordMu({
                            time: formatToUTCWithOffset(currentDate, customerTimezone, ISO_8601_WITH_UTC_OFFSET),
                            jobCrewMemberId: jobCrewMemberId ?? '',
                            type: actionType,
                            jobId: job?.jobId as string,
                        })
                        setIsCompleteJobModalOpen(false)
                    }
                }}
            />
            <CrewFeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
            <CompleteJobModal
                job={job}
                isOpen={isCompleteJobModalOpen}
                title={t(`Are you sure you want to ${t(actionType)} this job?`)}
                text={t(handleTypeText(actionType).text)}
                onClose={() => setIsCompleteJobModalOpen(false)}
                onProceed={() => {
                    if (!isPending) {
                        createCrewMemberTimeRecordMu({
                            time: formatToUTCWithOffset(currentDate, customerTimezone, ISO_8601_WITH_UTC_OFFSET),
                            jobCrewMemberId: jobCrewMemberId ?? '',
                            type: actionType,
                            jobId: job?.jobId as string,
                        })
                        setIsCompleteJobModalOpen(false)
                    }
                }}
            />
        </div>
    )
}

export default ProgressButtons
