import { formatToUTCWithOffset } from '@/utils/time'
import _ from 'lodash'
import { FaCircle } from 'react-icons/fa'
import JobLogTimeTable from './TimeTable'
import { useQuery } from '@tanstack/react-query'
import { getJobById } from '@/api/job'
import { getQuoteServiceNames } from '@/utils/helper'
import Spinner from '@/components/Spinner'

export type JobStatusType = 'ACT' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELED'| 'LATE_VISIT'
export type JobStatusColor = {
    text: string
    color: string
}

export const jobStatus: Record<JobStatusType, JobStatusColor> = {
    ACT: {
        text: 'Open',
        color: '#707070',
    },
    IN_PROGRESS: {
        text: 'In Progress',
        color: '#254284',
    },
    COMPLETED: {
        text: 'Completed',
        color: '#3B671A',
    },
    CANCELED: {
        text: 'Canceled',
        color: '#aa2929',
    },
    LATE_VISIT: {
        text: 'Late Visit',
        color: '#C0392B',
    }
}

interface IJobLogInfo {
    jobId: string
}

const JobLogInfo = ({ jobId }: IJobLogInfo) => {
    const { data: job, isLoading } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    return (
        <div>
            <div className='bg-white p-10 space-y-10'>
                {isLoading ? (
                    <div className='bg-white p-10 space-y-10'>
                        <Spinner variant='normal' size='10' />
                    </div>
                ) : (
                    <div className='flex flex-row border rounded-lg justify-between p-6 px-12'>
                        <div className='space-y-2'>
                            <p className='text-lg font-semibold text-gray-500'>
                                <p className='truncate'>
                                    {getQuoteServiceNames(job?.quote?.productServiceGroup)}
                                </p>
                            </p>
                            <p className='text-gray-500'>
                                Status:{' '}
                                <span className='ml-2 inline-block' style={{color: jobStatus?.[job?.status as JobStatusType]?.color}}>
                                    <FaCircle className='inline-block mb-1 w-2 h-2' />{' '}
                                    {jobStatus?.[job?.status as JobStatusType]?.text}
                                </span>
                            </p>
                            <p className='text-gray-500'>
                                Customer:{' '}
                                <span className='ml-2 text-black'>
                                    {job?.quote?.profile?.firstName +
                                        ' ' +
                                        job?.quote?.profile?.lastName}
                                </span>
                            </p>
                            <p className='text-gray-500'>
                                Date:{' '}
                                <span className='ml-2'>
                                    {formatToUTCWithOffset(
                                        job?.appointment?.startDateTime as string,
                                        job?.address?.timezone as string,
                                        'MM/DD/YYYY',
                                    )}
                                </span>
                            </p>
                        </div>
                        <div className='my-auto'>
                            <p className='text-gray-500'>
                                Crew:{' '}
                                <span className='text-black'>
                                    {job?.crew ? `Crew 00${job?.crew?.crewNumber}` : 'Unassigned'}
                                </span>
                            </p>
                            <div className='mt-2 flex flex-row'>
                                {job &&
                                    job?.jobCrewMembers?.slice(0, 5)?.map((member) => (
                                        <div key={member.crewMemberId}>
                                            {member?.memberProfile?.profileImage ? (
                                                <img
                                                    src={
                                                        typeof member?.memberProfile
                                                            ?.profileImage === 'string'
                                                            ? member?.memberProfile?.profileImage
                                                            : ''
                                                    }
                                                    alt='crew image'
                                                    className='h-[26px] w-[26px] rounded-full mr-1'
                                                    title={`${member?.memberProfile?.firstName} ${member?.memberProfile?.lastName}`}
                                                />
                                            ) : (
                                                <div
                                                    className='h-[26px] w-[26px] rounded-full bg-gray-300 flex justify-center items-center mr-1'
                                                    title={`${member?.memberProfile?.firstName} ${member?.memberProfile?.lastName}`}
                                                >
                                                    <span className='text-[#191A0A] text-base'>
                                                        {`${
                                                            member?.memberProfile?.firstName
                                                                ?.charAt(0)
                                                                ?.toUpperCase() ?? ''
                                                        }${
                                                            member?.memberProfile?.lastName
                                                                ?.charAt(0)
                                                                ?.toUpperCase() ?? ''
                                                        }`}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                {job?.foreman?.memberProfile?.profileImage && job?.crew ? (
                                    <img
                                        src={
                                            typeof job.foreman?.memberProfile?.profileImage ===
                                            'string'
                                                ? job?.foreman?.memberProfile?.profileImage
                                                : ''
                                        }
                                        alt='crew image'
                                        className='h-[26px] w-[26px] rounded-full mr-1'
                                    />
                                ) : (
                                    <div
                                        className='h-[26px] w-[26px] rounded-full bg-gray-300 flex justify-center items-center mr-1'
                                        title={`${job?.foreman?.memberProfile?.firstName} ${job?.foreman?.memberProfile?.lastName}`}
                                    >
                                        <span className='text-[#191A0A] text-base'>
                                            {`${
                                                job?.foreman?.memberProfile?.firstName
                                                    ?.charAt(0)
                                                    ?.toUpperCase() ?? ''
                                            }${
                                                job?.foreman?.memberProfile?.lastName
                                                    ?.charAt(0)
                                                    ?.toUpperCase() ?? ''
                                            }`}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    <p className='text-zentive-green-dark text-lg font-semibold'>Time</p>
                    {!isLoading ? (
                        job?.crewId || (job?.crew && !isLoading) ? (
                            <JobLogTimeTable job={job}/>
                        ) : (
                            <p>No Crew Assigned Yet</p>
                        )
                    ) : (
                        <Spinner variant='normal' size='10' />
                    )}
                </div>
            </div>
        </div>
    )
}
export default JobLogInfo
