import { ReadJobType } from '@/api/job/schema'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import CrewFeatureRestrictionModal from '@/components/CrewFeatureRestrictionModal'
import { FEATURE_RESTRICTIONS, JOB_STATUS } from '@/constants'
import { userAtom } from '@/store/auth'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaCircle, FaTools } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

interface IViewJobStatus {
    job: ReadJobType
}
const ViewJobStatus = ({ job }: IViewJobStatus) => {
    const { t } = useTranslation(['common'])
    const user = useAtomValue(userAtom)
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const isUserForeman = user?.crewMemberId === job?.foreman?.crewMemberId
    const navigate = useNavigate()
    const handleJobStatusColor = (status: string) => {
        switch (status) {
            case JOB_STATUS.ACTIVE:
                return { text: 'Open', color: '#707070', dot: '#707070', bg: '#EBEBEB' }
            case JOB_STATUS.IN_PROGRESS:
                return { text: 'In Progress', color: '#254284', dot: '#254284', bg: '#E7EAF1' }
            case JOB_STATUS.COMPLETED:
                return { text: 'Done', color: '#3B671A', dot: '#3B671A', bg: '#E0FBE2' }
            case JOB_STATUS.CANCELED:
                return { text: 'Canceled', color: '#EE4E4E', dot: '#EE4E4E', bg: '#FFEAEB' }
            case JOB_STATUS.RESCHEDULE:
                return { text: 'Reschedule', color: '#EE4E4E', dot: '#EE4E4E', bg: '#FFEAEB' }
            default:
                return { text: 'Open', color: '#707070', dot: '#707070', bg: '#EBEBEB' }
        }
    }

    const { mutate: checkSubsRestrictionMu } = useMutation<
        boolean,
        AxiosError,
        { profileId: string; name: string }
    >({
        mutationFn: ({ profileId, name }) => checkSubscriptionFeatureRestriction(profileId, name),
        onSuccess: (res) => {
            if (res && isUserForeman) {
                navigate(`/crew/jobs/status/${job?.jobId}`)
            } else {
                setRestrictionModal(true) // Open modal if there is a restriction
            }
        },
    })

    return (
        <div className='flex flex-col my-4'>
            <button
                type='button'
                className='w-10/12 mx-auto bg-white py-2 rounded-md p-2 shadow-md border border-gray-300 px-3'
                onClick={() =>
                    checkSubsRestrictionMu({
                        profileId: user?.profileId as string,
                        name: FEATURE_RESTRICTIONS.timeTracking,
                    })
                }
            >
                <div className='flex flex-col mt-2 text-sm gap-y-4'>
                    <div className='flex flex-row justify-between gap-x-2'>
                        <div className='flex flex-row gap-x-2'>
                            <FaTools className='text-zentive-blue h-4 w-4' />
                            <p className='font-medium text-gray-500 text-md'>{t('Job')}</p>
                        </div>
                        <div>
                            <p
                                className='flex items-center px-4 rounded-full text-sm'
                                style={{
                                    backgroundColor: handleJobStatusColor(job?.status ?? '').bg,
                                    color: handleJobStatusColor(job?.status ?? '').color,
                                }}
                            >
                                <span
                                    className='w-2 h-2 mr-1 rounded-full inline-block'
                                    style={{
                                        backgroundColor: handleJobStatusColor(job?.status ?? '')
                                            .dot,
                                    }}
                                ></span>
                                {t(handleJobStatusColor(job?.status ?? '').text)}
                            </p>
                        </div>
                    </div>
                    <div className='flex flex-row gap-x-2 ml-1 mb-2'>
                        {/* <p className='text-sm truncate pr-16'>
                            {job?.quote?.productServiceGroup
                                ?.filter((service) => service?.type === 'service')
                                ?.map((service) => service?.name ?? '')
                                .filter(Boolean)
                                .join(', ')}
                        </p> */}
                        <div className='flex flex-col'>
                            {job?.quote?.productServiceGroup
                                ?.filter((service) => service?.type === 'service')
                                ?.map((service, index) => (
                                    <div className='flex flex-row gap-x-2'>
                                        <FaCircle className='mt-2 text-zentive-gray-medium w-2 h-2' />
                                        <p key={index} className='text-sm'>
                                            {service?.name ?? ''}
                                        </p>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </button>
            <CrewFeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
        </div>
    )
}

export default ViewJobStatus
