import BreadCrumbs from '@/components/BreadCrumbs'
import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import MessageTable from './MessageTable'
import { customemrMessageListAtom, selectedCustomerMessageStatusAtom } from '@/store/customer'
import { useAtom, useAtomValue } from 'jotai'
import SearchBar from '@/components/InputSearchBox'
import { Button } from '@/components/Button'
import { useNavigate } from 'react-router-dom'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { getAllCustomerMessages } from '@/api/customer'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import debounce from 'debounce'
import { FullProfileType } from '@/api/profile/schema'
import { itemsPerPageAtom, paginationAtom } from '@/store/manageSubs'
import { Pagination } from '@/components/Pagination'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { cn } from '@/utils/helper'

const CustomerMessaging = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const [selected, setSelected] = useAtom(selectedCustomerMessageStatusAtom)
    const navigate = useNavigate()
    const [keyWord, setKeyWord] = useState<string>('')
    const [messageList, setMessageList] = useAtom(customemrMessageListAtom)
    const [pagination, setPagination] = useAtom(paginationAtom)
    const [selectedItemsPerPage] = useAtom(itemsPerPageAtom)

    const { isLoading: loading, data } = useQuery({
        queryKey: ['messagesList', pagination, keyWord, selected, messageList],
        queryFn: () =>
            getAllCustomerMessages(user?.profileId as string, selected, keyWord, pagination),
    })

    // Debounced search function
    const debouncedSearch = debounce((query: string) => {
        handleSearch(query)
    }, 500) // Adjust the debounce delay as per your requirement

    const handleSearch = (query: string) => {
        setKeyWord(query)
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }

    useEffect(() => {
        setMessageList(null)
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
            pageSize: selectedItemsPerPage,
        }))
    }, [setPagination, setMessageList, selected])

    useEffect(() => {
        setMessageList(data ?? null)
    }, [data])

    return (
        <div className={cn('w-full h-full', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs titleName='Customer Messaging' />
            <div className='flex justify-between py-6'>
                <div>
                    <SearchBar
                        placeHolder='Search for...'
                        disabled={!(messageList && messageList.content?.length) && !keyWord?.length}
                        onSearchChange={(e) => debouncedSearch(e.target.value)}
                    />
                </div>
                <div>
                    <Button
                        type={'button'}
                        className='bg-zentive-green-dark px-8 py-5'
                        onClick={() => navigate('/customers/customer-messaging/create')}
                    >
                        Create Message
                    </Button>
                </div>
            </div>
            <Tabs defaultValue={selected} onValueChange={(val) => setSelected(val)}>
                <TabsList className='w-full flex flex-row mt-[26px] space-x-4 bg-white rounded-md'>
                    <TabsTrigger
                        value='both'
                        className={
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                        }
                    >
                        All Messages
                    </TabsTrigger>
                    <TabsTrigger
                        value='sent'
                        className={
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                        }
                    >
                        Sent
                    </TabsTrigger>
                    <TabsTrigger
                        value='draft'
                        className={
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                        }
                    >
                        Draft
                    </TabsTrigger>
                </TabsList>
            </Tabs>

            {!loading && !!messageList?.content?.length && <MessageTable />}
            {loading && !messageList?.content?.length && <AppSkeletonLoadingState />}
            {data && messageList?.content?.length === 0 && (
                <div className='bg-white w-auto rounded-sm'>
                    <div className='mt-4 w-[1260px]'> </div>
                    <div className='flex justify-center'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-12 h-60'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
                </div>
            )}
            {!!messageList?.content?.length && (
                <div className='mb-5 mt-3'>
                    <Pagination
                        itemsPerPage={messageList.meta.pageSize ?? 5}
                        setPagination={setPagination}
                        totalRecords={messageList.meta.totalRecords ?? 1}
                        pagination={pagination}
                    />
                </div>
            )}
        </div>
    )
}

export default CustomerMessaging
