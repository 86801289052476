import { POLICY_SECTIONS } from "@/assets/static/privacyDocument";

const PrivacyPolicy = () => {

    const isSubsectionTitleWithDot = (title: string) => {
        return /^[a-z]\.\s/i.test(title);
    };

    return (
        <div className="flex justify-center items-center z-0 crew-sign-in-bg relative">
            <div className="flex flex-col justify-center sign-in-overlay w-full mx-28 my-20 h-full">
                <h1 className="text-zentive-green-dark text-center uppercase font-semibold text-lg"> ZENTIVE LLC PRIVACY POLICY </h1>
                <ol className="list-decimal list-inside mt-4 text-left w-full px-8">
                    {POLICY_SECTIONS?.map((section, index) => (
                        <li key={index} className="flex flex-col mb-6">
                            <div className="flex items-center text-zentive-gray-medium mb-2">
                                <h2 className="font-semibold">{section.title}</h2>
                            </div>
                            {section.content && (
                                <p className="text-zentive-black mb-2">
                                    {section.content}
                                </p>
                            )}
                            {section.subSections && (
                                <ol className="list-decimal list-inside ">
                                    {section.subSections?.map((subSection, subIndex) => (
                                        <li key={subIndex} className={`flex flex-col mb-2 ${!isSubsectionTitleWithDot(subSection.title ?? "") ? "pl-4" : ""}`}>
                                            {subSection.title && (
                                                <h3><strong> {subSection.title} </strong> {subSection.content}</h3>
                                            )}
                                        </li>
                                    ))}
                                </ol>
                            )}
                        </li>
                    ))}
                </ol>
                <span className="px-8 mt-6 text-zentive-gray-medium"> Effective Date [5.3.2024] </span>
            </div>
        </div>
    )
}

export default PrivacyPolicy;