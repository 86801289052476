import { ReactNode } from 'react';

interface IAgreementSection {
    title: string;
    content: string | ReactNode;
}

interface IPrivacySection {
    title: string;
    content?: string;
    subSections?: IAgreementSection[];
}

export const AGREEMENT_SECTIONS: IAgreementSection[] = [
    {
        title: '1. INTRODUCTION',
        content: `This Service Agreement ("Agreement") is between you ("User" or "you") and Zentive LLC ("Zentive," "we," "us," or "our"), 
                  governing your use of our web-based business management software designed for landscaping businesses, which includes invoicing, 
                  quoting, financial management reports, CRM, crew tracking, job creation, scheduling jobs, and marketing services ("Application"). 
                  By downloading or using the Application, you agree to be bound by the terms of this Agreement.`,
    },
    {
        title: '2. LICENSE',
        content: `We grant you a revocable, non-exclusive, non-transferable, limited license to access and use the Application solely for your personal, 
                  business purposes in accordance with this Agreement.`,
    },
    {
        title: '3. USAGE RESTRICTIONS',
        content: `The Application is intended for users who are at least 18 years old. You may not use the Application for any unlawful or prohibited activity.`,
    },
    {
        title: '4. ACCOUNT REGISTRATION AND ACCESS',
        content: `You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. 
                  You agree to immediately notify us of any unauthorized use of your account.`,
    },
    {
        title: '5. FEES AND PAYMENT',
        content: `Subscription fees are processed through Stripe and may be paid via credit card, debit card, or ACH. We do not offer refunds, but we provide a satisfaction guarantee under certain conditions.`,
    },
    {
        title: '6. USER DATA',
        content: `You retain ownership of your data, which we store securely. By using the Application, you grant us the right to use your data only as necessary to provide and improve the service.`,
    },
    {
        title: '7. INTELLECTUAL PROPERTY',
        content: `Zentive LLC reserves all rights in the Application and related content. No additional rights are granted beyond those expressly stated in this Agreement.`,
    },
    {
        title: '8. MODIFICATIONS AND TERMINATION',
        content: `We may modify this Agreement or the Application with notice provided via email and on our website. Either party may terminate the Agreement at any time. Upon termination, your right to use the Application ceases immediately.`,
    },
    {
        title: '9. DISPUTE RESOLUTION',
        content: `Any disputes under this Agreement will be resolved through binding arbitration, with such arbitration to be final and binding.`,
    },
    {
        title: '10. COMPLIANCE',
        content: `You are responsible for ensuring that your use of the Application complies with all applicable laws and regulations.`,
    },
    {
        title: '11. SUPPORT AND CONTACT',
        content: `Support is available via email at support@zentive.io. For any questions or concerns about the service, please contact us at the same email address.`,
    },
    {
        title: '12. GENERAL PROVISIONS',
        content: `This Agreement constitutes the entire agreement between you and Zentive LLC regarding the Application. It supersedes all prior agreements and understandings, whether written or oral.`,
    },
    {
        title: 'GOVERNING LAW:',
        content: `This Agreement and any disputes arising out of or related to it will be governed by and construed in accordance with the laws of the State of Wyoming, United States, without giving effect to any choice or conflict of law provision or rule.`,
    },
];


export const POLICY_SECTIONS: IPrivacySection[] = [
    {
        title: '',
        content: `This Service Agreement ("Agreement") is between you ("User" or "you") and Zentive LLC ("Zentive," "we," "us," or "our"), governing your use of our web-based business management software designed for landscaping businesses, which includes invoicing, quoting, financial management reports, CRM, crew tracking, job creation, scheduling jobs, and marketing services ("Application"). By downloading or using the Application, you agree to be bound by the terms of this Agreement.`,
    },
    {
        title: '1. INFORMATION WE COLLECT',
        subSections: [
            {   
                title: 'a. Personally Identifiable Information (PII):',
                content: `We collect information that can be used to identify you as an individual ("Personal Information"), such as your name, email address, physical address, telephone number, and payment information. Payment information is processed and stored by our third-party payment processor, Stripe, and is not stored by us.`
            },
            {   
                title: 'b. Non-Personally Identifiable Information: ',
                content: `We collect information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks.`
            },
        ],
    },
    {
        title: '2. HOW WE COLLECT INFORMATION',
        content: `We collect information directly from you when you:`,
        subSections: [
            {
                title: '•',
                content: `Register to use our Services`
            },
            {
                title: '•',
                content: ` Contact us for support or inquiries `
            },
            {
                title: '•',
                content: `Use our Services`
            },
            {
                title: '•',
                content: `We also collect information through the use of cookies and similar technologies to enhance our understanding of your needs and to provide tailored services.`
            }
        ],
    },
    {
        title: '3. USE OF YOUR INFORMATION',
        subSections: [
            {
                title: 'a. To Provide and Improve Our Services:',
                content: `We use your Personal Information to operate, maintain, and provide you with the features and functionality of our Services, including customer support and to improve our Services.`
            },
            {
                title: 'b. To Communicate with You:',
                content: `We use your information to communicate with you about your account and our Services, including updates, security alerts, and support messages.`
            },
            {
                title: 'c. For Marketing Purposes: ',
                content: `With your consent, we may use your information to send you marketing emails about promotions, new services, and other news about our company. You may opt-out of receiving these communications from us by following the unsubscribe link or instructions provided in any email we send.`
            },
        ],
    },
    {
        title: '4. SHARING AND DISCLOSURE OF INFORMATION',
        content: `We may share your information with third-party service providers to perform services on our behalf, such as data analysis, payment processing, information technology and related infrastructure provision, customer service, email delivery, and auditing services. These third parties are authorized to use your Personal Information only as necessary to provide these services to us and are obligated to keep your information confidential. We may also disclose your Personal Information as required by law, such as to comply with a subpoena, or similar legal process when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.`,
    },
    {
        title: '5. DATA SECURITY',
        content: `We are committed to ensuring the security of your Personal Information. We implement a variety of security measures to maintain the safety of your Personal Information when you enter, submit, or access your Personal Information. However, no Internet transmission is completely secure, and we cannot guarantee its absolute security.`,
    },
    {
        title: '6. YOUR RIGHTS',
        content: `You have the right to access, correct, or delete your Personal Information stored by us. You may exercise these rights by contacting us at the contact information provided below.`,
    },
    {
        title: '7. CHANGES TO THIS PRIVACY POLICY',
        content: `We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on our Services prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.`,
    },
    {
        title: '8. CONTACT US',
        content: `If you have any questions about this Privacy Policy, please contact us at: `,
        subSections: [
            {
                title: 'Email: ',
                content: `business@zentive.io`
            },
            {
                title: 'Address:',
                content: `1309 Coffeen Avenue STE 1200, Sheridan, Wyoming 82801`
            },
        ],
    },
    {
        title: 'CALIFORNIA PRIVACY RIGHTS',
        content: `If you are a resident of California, you are granted specific rights regarding access to your personal information. California Civil Code Section 1798.83 permits you to request information regarding the disclosure of your personal information by Zentive LLC to third parties for the third parties’ direct marketing purposes. To make such a request, please send an email to business@zentive.io or write us at the address above.`,
    }
];

export const FAQS_SECTIONS: IAgreementSection[] = [
    {
        title: 'What services do you offer?',
        content: (
            <>
                Zentive is an acclaimed platform that streamlines operations for small home service businesses. It simplifies tasks like <strong>job scheduling, crew management, customer invoicing, and custom data reporting.</strong><br /><br />
                Our field service software is a business management system that helps small and medium business owners eliminate manual processes with automated solutions. We make repetitive admin tasks a thing of the past.
            </>
        ),
    },
    {
        title: 'How much does your service cost?',
        content: ( <> The pricing of our services vary depending on your plan: <strong> Starter, Standard, Growth Pro. </strong> For more details, you can view our <a href='/sign-up' className='underline text-zentive-blue'> pricing</a> plans to see which is best for your service based business. </>),
    },
    {
        title: 'What industries do you serve?',
        content: (<> Zentive is a field service management (FSM) software that serves <strong> landscaping, lawn care, tree removal, and snow removal businesses.</strong> </>),
    },
];

