import { useNavigate, useParams } from 'react-router-dom'
import { TfiMenuAlt } from 'react-icons/tfi'
import { IoMdAddCircle } from 'react-icons/io'
import { MdPhotoSizeSelectActual } from 'react-icons/md'
import { PiNoteFill } from 'react-icons/pi'
import { FaAngleRight } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'

const MoreJobDetails = () => {
    const { t } = useTranslation(['view-job'])
    const { jobId } = useParams()
    const navigate = useNavigate()

    const moreJobDetails = [
        {
            name: t('List of Materials'),
            path: `/crew/job/materials/${jobId}`,
            icon: TfiMenuAlt,
        },
        {
            name: t('Additional Expenses'),
            path: `/crew/job/expenses/${jobId}`,
            icon: IoMdAddCircle,
        },
        {
            name: t('Notes'),
            path: `/crew/job/notes/${jobId}`,
            icon: MdPhotoSizeSelectActual,
        },
        {
            name: t('Photos'),
            path: `/crew/job/photos/${jobId}`,
            icon: PiNoteFill,
        },
    ]

    return (
        <div className='flex flex-col my-4 pb-24'>
            <div className='w-10/12 mx-auto bg-white py-2 rounded-md px-3 shadow-md border border-gray-300 space-y-6'>
                {moreJobDetails?.map((detail, index) => (
                    <button
                        key={index}
                        className='w-full flex flex-row justify-between mt-2 text-sm pb-4'
                        role='button'
                        onClick={() => navigate(detail.path)}
                    >
                        <div className='flex flex-row gap-x-2'>
                            <detail.icon className='w-4 h-4 text-zentive-blue' />
                            {detail.name}
                        </div>
                        <div>
                            <FaAngleRight />
                        </div>
                    </button>
                ))}
            </div>
        </div>
    )
}

export default MoreJobDetails
