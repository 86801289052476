import { Card, CardContent, CardFooter } from '@/components/Card'
import { RichTextEditorWrapper } from './RichTextEditor'
import { Form } from '@/components/Forms'
import { useForm } from 'react-hook-form'
import Fields from './Fields'
import { Button } from '@/components/Button'
import { EmailServiceParamType, emailServiceParamSchema } from '@/api/service/schema'
import { businessIdAtom, userAtom } from '@/store/auth'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAtomValue } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { getOwnerProfile, sendEmailNotes } from '@/api/service'
import { toast } from '@/hooks/useToast'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { FullProfileType } from '@/api/profile/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'

const CPServiceNotes = () => {
    const navigate = useNavigate()
    const user = useAtomValue(userAtom)
    const businessId = useAtomValue(businessIdAtom)

    if (businessId === undefined) {
        navigate('/customer-portal/select-business')
    }

    const methods = useForm<EmailServiceParamType>({
        mode: 'onSubmit',
        resolver: zodResolver(emailServiceParamSchema),
    })

    const { data: owner } = useQuery<unknown, AxiosError, FullProfileType>({
        queryKey: ['owner', businessId],
        queryFn: () => getOwnerProfile(businessId as string),
    })

    useEffect(() => {
        methods.setValue('email', owner?.email!)
        methods.setValue('senderName', `${user?.firstName!} ${user?.lastName!}`)
        methods.setValue(
            'address',
            `${user?.address.streetAddress}, ${user?.address.city}, ${user?.address.state} ${user?.address.zipCode}`,
        )
    }, [owner])

    const { mutate: sendEmailMu } = useMutation<ResponseType, AxiosError, EmailServiceParamType>({
        mutationKey: ['SendMessage'],
        mutationFn: (data) => sendEmailNotes(data),
        onSuccess: () => {
            toast({
                description: 'Successfully Submitted',
                variant: 'default',
            })
            navigate('/customer-portal/service-notes')
        },
    })

    const onSubmit = (data: EmailServiceParamType) => {
        sendEmailMu(data)
    }

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Service Notes' />
            <Form {...methods}>
                <Card className='mt-5 w-full'>
                    <CardContent className='p-4 pt-[36px] space-y-8'>
                        <Fields />
                        <RichTextEditorWrapper />
                    </CardContent>
                    <CardFooter className='mt-[56px] lg:flex lg:justify-end rounded-b-md bg-white '>
                        <div className='flex space-x-4 mt-[24px]'>
                            <Button
                                onClick={() => ''}
                                type='button'
                                variant={'outline'}
                                className='w-full h-[43px] text-zentive-green-dark border-zentive-green-dark rounded-[5px] font-sans font-semibold text-base'
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={methods.handleSubmit(onSubmit)}
                                type='submit'
                                className='w-full h-[43px] text-white bg-zentive-green-dark rounded-[5px] font-sans font-semibold text-base'
                            >
                                Submit
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </Form>
        </div>
    )
}
export default CPServiceNotes
