import { RouteOptimizeType } from '@/api/routing/schema'
import { routeOptimizeAtom } from '@/store/route'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import { HiLocationMarker } from 'react-icons/hi'
import { RxDragHandleDots2 } from 'react-icons/rx'

const SortableList: React.FC = () => {
    const [routeOptimize, setRouteOptimize] = useAtom(routeOptimizeAtom)
    const [excludedProps, setExcludedProps] = useState<RouteOptimizeType[]>([])
    const [includedProps, setIncludedProps] = useState<RouteOptimizeType[]>([])
    const [showPopup, setShowPopup] = useState<string | null>(null)

    useEffect(() => {
        setExcludedProps([])
        setIncludedProps([])
        if (routeOptimize) {
            routeOptimize?.map((prop) => {
                if (!prop.isIncluded) {
                    setExcludedProps((prevProps) => [...(prevProps ?? []), prop])
                } else {
                    setIncludedProps((prevProps) => [...(prevProps ?? []), prop])
                }
            })
        }
    }, [routeOptimize])

    const handleOnRemove = (profile: RouteOptimizeType) => {
        const updatedExcludedProps = [...excludedProps, { ...profile, isIncluded: false }]
        const updatedIncludedProps = includedProps.filter(
            (item) => item.profile.profileId !== profile.profileId,
        )

        setExcludedProps(updatedExcludedProps)
        setIncludedProps(updatedIncludedProps)
        setRouteOptimize([...updatedIncludedProps, ...updatedExcludedProps])
    }

    const handleOnAdd = (profile: RouteOptimizeType) => {
        const updatedIncludedProps = [...includedProps, { ...profile, isIncluded: true }]
        const updatedExcludedProps = excludedProps.filter(
            (item) => item.profile.profileId !== profile.profileId,
        )

        setExcludedProps(updatedExcludedProps)
        setIncludedProps(updatedIncludedProps)
        setRouteOptimize([...updatedIncludedProps, ...updatedExcludedProps])
    }

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return

        const items = Array.from(includedProps)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        setRouteOptimize([])
        setIncludedProps(items)
        setRouteOptimize(items)
        setRouteOptimize((prevProps) => [...(prevProps ?? []), ...excludedProps])
    }

    const handleItemClick = (id: string) => {
        setShowPopup(showPopup === id ? null : id)
    }

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId='tasks'>
                {(provided) => (
                    <ul
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className=' list-none p-0 max-h-[500px] overflow-y-auto gray-scrollbar'
                    >
                        {includedProps ? (
                            includedProps?.map((customer, index) => (
                                <Draggable
                                    key={customer.profileId ? customer.profileId : '0'}
                                    draggableId={customer.profileId ? customer.profileId : '0'}
                                    index={index}
                                >
                                    {(provided) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className='select-none p-0 mb-1 bg-white rounded shadow-sm '
                                            style={{ ...provided.draggableProps.style }}
                                        >
                                            <div
                                                onClick={() => handleItemClick(customer.profileId)}
                                            >
                                                <div className='flex flex-row'>
                                                    <RxDragHandleDots2 className='my-auto w-[8%]' />
                                                    <div className=' bg-zentive-blue w-[12%] text-white  items-center justify-center flex'>
                                                        <span className=''> {index + 1} </span>
                                                    </div>
                                                    <div className='flex-grow w-[80%] m-4'>
                                                        <div className='flex flex-row justify-between'>
                                                            <p className='text-base font-semibold'>
                                                                {customer.profile.firstName}{' '}
                                                                {customer.profile.lastName}
                                                            </p>
                                                            <HiLocationMarker className='text-zentive-green-dark text-2xl' />
                                                        </div>
                                                        <p className='text-sm text-zentive-gray-medium'>
                                                            {
                                                                customer.profile.address
                                                                    ?.streetAddress
                                                            }{' '}
                                                            {customer.profile.address?.city}
                                                        </p>
                                                        <p className='text-sm text-zentive-gray-medium'>
                                                            {customer.profile.address?.state},{' '}
                                                            {customer.profile.address?.zipCode}
                                                        </p>

                                                        {showPopup === customer.profileId && (
                                                            <div className=' text-right'>
                                                                <button
                                                                    className='mt-2 p-2 bg-zentive-red-dark text-white rounded '
                                                                    onClick={() =>
                                                                        handleOnRemove(customer)
                                                                    }
                                                                >
                                                                    Remove From Route
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </Draggable>
                            ))
                        ) : (
                            <></>
                        )}
                        {provided.placeholder}
                        {excludedProps && excludedProps?.length > 0 ? (
                            <div className=' bg-zentive-gray-light pl-2'>Excluded Properties</div>
                        ) : (
                            <></>
                        )}
                        {excludedProps ? (
                            excludedProps?.map((customer, _) => (
                                <li className='select-none p-0 mb-1 bg-white rounded shadow-sm cursor-pointer'>
                                    <div
                                        className='popup-container'
                                        onClick={() => handleItemClick(customer.profileId)}
                                    >
                                        <div className='flex flex-row'>
                                            <RxDragHandleDots2 className='my-auto w-[8%]' />
                                            <div className=' bg-zentive-red-dark w-[12%] text-white  items-center justify-center flex'>
                                                <span className=''> - </span>
                                            </div>
                                            <div className='flex-grow w-[80%] m-4'>
                                                <div className='flex flex-row justify-between'>
                                                    <p className='text-base font-semibold'>
                                                        {customer.profile.firstName}{' '}
                                                        {customer.profile.lastName}
                                                    </p>
                                                    <HiLocationMarker className=' text-zentive-red-dark text-2xl' />
                                                </div>
                                                <p className='text-sm text-zentive-gray-medium'>
                                                    {customer.profile.address?.streetAddress}{' '}
                                                    {customer.profile.address?.city}
                                                </p>
                                                <p className='text-sm text-zentive-gray-medium'>
                                                    {customer.profile.address?.state},{' '}
                                                    {customer.profile.address?.zipCode}
                                                </p>
                                                {showPopup === customer.profileId && (
                                                    <div className=' text-right'>
                                                        <button
                                                            className='mt-2 p-2 bg-zentive-green-dark text-white rounded'
                                                            onClick={() => handleOnAdd(customer)}
                                                        >
                                                            Add To Route
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <></>
                        )}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default SortableList
