import { CardTitle } from '@/components/Card'
import AddTaxDialog from './AddTaxDialog'
import { startTransition, useEffect, useState } from 'react'
import AddDiscountDialog from './AddDiscountDialog'
import { useFormContext } from 'react-hook-form'
import { customerInvoiceAtom, expenseAtom, invoiceContentAtom, invoiceTaxAtom } from '@/store/owner'
import { useAtom, useAtomValue } from 'jotai'
import { Dialog, DialogTrigger } from '@/components/Dialog'
import { CiTrash } from 'react-icons/ci'
import { InvoiceType } from '@/api/invoicing/schema'
import LaborCostDialog from '@/components/LaborCostDialog'
import { jobGenerateInvoiceAtom } from '@/store/job'
import { DEFAULT_QUOTE_TAX_VALUE } from '@/constants'
import { displayCurrency, displayWithDecimals } from '@/utils/helper'
import FinancialManagementTaxes from '@/components/FinancialManagement/Taxes'
import { calculateGrossIncome, calculateNetIncome } from '@/utils/calc'

const BillingAmount = () => {
    const invoiceContent = useAtomValue(invoiceContentAtom)

    const generateInvoiceData = useAtomValue(jobGenerateInvoiceAtom)
    const expenses = useAtomValue(expenseAtom)

    const [customer] = useAtom(customerInvoiceAtom)
    const [tax, setTax] = useState({ name: '', tax: 0, taxAmount: 0 })
    const [taxes, setTaxes] = useAtom(invoiceTaxAtom)

    const [discount, setDiscount] = useState({
        percentage: 0,
        amount: 0,
        isPercent: false,
        totalAfterDiscount: 0,
    })

    const [estimate, setEstimate] = useState(0)
    const [estimatePercentage, setEstimatePercentage] = useState(0)
    const [] = useState(0)
    const [subTotalWithDiscount, setSubTotalWithDiscount] = useState(0)

    const { setValue } = useFormContext<InvoiceType>()

    const totalInvoiceExpenses =
        expenses.length > 0
            ? expenses
                  .filter((exp) => !exp.isNotIncludedInInvoice)
                  .reduce((total, expense) => total + (expense.total || 0), 0)
            : 0

    const productsTaxAmount = invoiceContent.totalProduct * (taxes.productTax / 100)
    const servicesTaxAmount = invoiceContent.totalService * (taxes.serviceTax / 100)
    const expensesTaxAmount = totalInvoiceExpenses * (taxes.expenseTax / 100)

    const calcTotalTaxes = productsTaxAmount + servicesTaxAmount + expensesTaxAmount

    const handleRemoveDiscount = () => {
        setDiscount({
            percentage: 0,
            amount: 0,
            isPercent: false,
            totalAfterDiscount: Number(invoiceContent.subTotal.toFixed(2)),
        })
    }

    const handleRemoveTax = () => setTaxes(DEFAULT_QUOTE_TAX_VALUE)

    const getTaxesTotalPercentage = () => (calcTotalTaxes / discount.totalAfterDiscount) * 100

    useEffect(() => {
        if (customer?.quote != undefined && generateInvoiceData == null) {
            startTransition(() => {
                const discountAmount = discount.isPercent
                    ? (invoiceContent.subTotal * discount.percentage) / 100
                    : discount.amount

                const subTotalAfterDiscount = invoiceContent.subTotal - discountAmount

                setDiscount((prevDiscount: any) => ({
                    ...prevDiscount,
                    totalAfterDiscount: subTotalAfterDiscount,
                }))

                setSubTotalWithDiscount(subTotalAfterDiscount)

                const taxAmount = customer?.quote?.tax!
                setValue('tax', Number(calcTotalTaxes.toFixed(2)))

                const taxPercentage = (customer?.quote?.tax! / invoiceContent?.subTotal) * 100

                setTax((prevTax) => ({ ...prevTax, taxAmount: taxAmount, tax: taxPercentage }))
            })
        } else if (generateInvoiceData) {
            startTransition(() => {
                setDiscount((prevDiscount: any) => ({
                    ...prevDiscount,
                    isPercent: generateInvoiceData.isDiscountPercent,
                }))
                if (generateInvoiceData.isDiscountPercent) {
                    setDiscount((prevDiscount: any) => ({
                        ...prevDiscount,
                        isPercent: generateInvoiceData.isDiscountPercent,
                        percentage: generateInvoiceData.discount,
                        amount: (invoiceContent.subTotal * generateInvoiceData.discount!) / 100,
                    }))
                } else {
                    setDiscount((prevDiscount: any) => ({
                        ...prevDiscount,
                        amount: generateInvoiceData.discount,
                    }))
                }

                const discountAmount = discount.isPercent
                    ? (invoiceContent.subTotal * discount.percentage) / 100
                    : discount.amount

                const subTotalAfterDiscount = invoiceContent.subTotal - discountAmount

                setDiscount((prevDiscount: any) => ({
                    ...prevDiscount,
                    totalAfterDiscount: isNaN(subTotalAfterDiscount) ? 0 : subTotalAfterDiscount,
                }))

                setSubTotalWithDiscount(subTotalAfterDiscount)

                const taxAmount = generateInvoiceData.tax!
                setValue('tax', Number(calcTotalTaxes.toFixed(2)))

                const taxPercentage = (generateInvoiceData.tax! / invoiceContent.subTotal) * 100

                setTax((prevTax) => ({ ...prevTax, taxAmount: taxAmount, tax: taxPercentage }))
            })
        } else {
            startTransition(() => {
                setDiscount({
                    percentage: 0,
                    amount: 0,
                    isPercent: false,
                    totalAfterDiscount: Number(invoiceContent.subTotal.toFixed(2)),
                })
                const discountAmount = discount.isPercent
                    ? (invoiceContent.subTotal * discount.percentage) / 100
                    : discount.amount

                const taxAmount = subTotalWithDiscount * (tax.tax / 100)

                setValue('tax', Number(calcTotalTaxes.toFixed(2)))
                setTax((prevTax) => ({ ...prevTax, taxAmount }))
                setSubTotalWithDiscount(invoiceContent.subTotal - discountAmount)

                const totalAfterDiscountTemp = invoiceContent.subTotal - discountAmount
                setDiscount((prevDiscount: any) => ({
                    ...prevDiscount,
                    totalAfterDiscount: isNaN(totalAfterDiscountTemp) ? 0 : totalAfterDiscountTemp,
                }))
            })
        }
    }, [invoiceContent, generateInvoiceData, customer, calcTotalTaxes])

    useEffect(() => {
        startTransition(() => {
            const totalExpenses =
                expenses && expenses.length > 0
                    ? expenses
                          .filter((exp) => !exp.isNotIncludedInInvoice)
                          .reduce((total, expense) => total + (expense.total || 0), 0)
                    : 0

            setValue(
                'totalAmount',
                Number(discount.totalAfterDiscount.toFixed(2)) + Number(calcTotalTaxes.toFixed(2)),
            )
            setValue('subTotal', Number(invoiceContent.subTotal.toFixed(2)))
            setValue('discount', Number(discount.amount.toFixed(2)))
            setValue('laborCost', Number(invoiceContent?.laborCost.toFixed(2)))
            setValue('isDiscountPercent', discount.isPercent)
            setValue(
                'grossIncome',
                calculateGrossIncome(
                    Number(discount.totalAfterDiscount.toFixed(2)) +
                        Number(calcTotalTaxes.toFixed(2)),
                    Number(invoiceContent?.laborCost.toFixed(2)),
                    Number(invoiceContent?.totalProduct.toFixed()),
                ),
            )
            setValue(
                'netIncome',
                calculateNetIncome(
                    calculateGrossIncome(
                        Number(discount.totalAfterDiscount.toFixed(2)) +
                            Number(calcTotalTaxes.toFixed(2)),
                        Number(invoiceContent?.laborCost.toFixed(2)),
                        Number(invoiceContent?.totalProduct.toFixed()),
                    ),
                    Number(calcTotalTaxes.toFixed(2)),
                    Number(invoiceContent?.totalExpense.toFixed(2)),
                ),
            )
            const estimate = discount.totalAfterDiscount - totalExpenses - calcTotalTaxes

            setEstimate(estimate)
            setEstimatePercentage(Number((estimate / discount.totalAfterDiscount) * 100))
        })
    }, [discount, expenses, calcTotalTaxes, invoiceContent])

    return (
        <>
            <div className='border border-[#EBEBEB] p-6 my-16'>
                <CardTitle className='text-[18px] mb-6'>Billing Amount</CardTitle>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>
                        <div className='font-semibold'>Labor Cost</div>
                    </div>
                    {invoiceContent.laborCost ? (
                        <div className='px-8'>{displayCurrency(invoiceContent.laborCost)}</div>
                    ) : (
                        <LaborCostDialog fieldName='laborCost' />
                    )}
                </div>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Subtotal</div>
                    <div className='px-8'>{displayCurrency(invoiceContent.subTotal)}</div>
                </div>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    {discount.amount == 0 && discount.percentage == 0 ? (
                        <>
                            <Dialog>
                                <div className='font-semibold px-4'>Discount</div>
                                <DialogTrigger asChild>
                                    <button
                                        disabled={!customer}
                                        className={`${
                                            customer
                                                ? 'text-zentive-blue'
                                                : 'text-zentive-gray-medium cursor-not-allowed'
                                        } px-8 pb-2 font-semibold`}
                                    >
                                        Add Discount
                                    </button>
                                </DialogTrigger>
                                <AddDiscountDialog
                                    setDiscount={setDiscount}
                                    subTotal={invoiceContent.subTotal}
                                    handleDiscountTypeChange={(isPercent: boolean) => {
                                        setDiscount((prevDiscount: any) => ({
                                            ...prevDiscount,
                                            isPercent,
                                        }))
                                    }}
                                />
                            </Dialog>
                        </>
                    ) : (
                        <>
                            <div className='font-semibold px-4'>
                                Discount{' '}
                                {discount.isPercent ? (
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <button
                                                type='button'
                                                className='text-zentive-blue font-semibold hover:underline'
                                            >
                                                {discount.percentage}%{' '}
                                            </button>
                                        </DialogTrigger>
                                        <AddDiscountDialog
                                            setDiscount={setDiscount}
                                            subTotal={invoiceContent.subTotal}
                                            handleDiscountTypeChange={(isPercent: boolean) => {
                                                setDiscount((prevDiscount: any) => ({
                                                    ...prevDiscount,
                                                    isPercent,
                                                }))
                                            }}
                                        />
                                    </Dialog>
                                ) : (
                                    <> </>
                                )}
                            </div>
                            <div className='flex items-center'>
                                <div>{`-${displayCurrency(discount.amount)}`}</div>
                                <button
                                    type='button'
                                    className='ml-1'
                                    onClick={handleRemoveDiscount}
                                >
                                    <CiTrash className='size-7 text-zentive-red-dark' />
                                </button>
                            </div>
                        </>
                    )}
                </div>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Total After Discount</div>
                    <div className='px-8'>{displayCurrency(discount.totalAfterDiscount)}</div>
                </div>
                <div className='flex flex-row justify-between  h-10  text-zentive-gray-medium'>
                    {taxes.productTax == 0 && taxes.serviceTax == 0 && taxes.expenseTax == 0 ? (
                        <>
                            <Dialog>
                                <div className='font-semibold px-4'>Tax</div>
                                <DialogTrigger asChild>
                                    <button
                                        disabled={!customer}
                                        className={`${
                                            customer
                                                ? 'text-zentive-blue'
                                                : 'text-zentive-gray-medium cursor-not-allowed'
                                        } px-8 pb-2 font-semibold`}
                                    >
                                        Add Tax
                                    </button>
                                </DialogTrigger>
                                <AddTaxDialog />
                            </Dialog>
                        </>
                    ) : (
                        <>
                            <div className='font-semibold px-4'>
                                Tax <span className='text-zentive-blue'>{taxes.name}</span>
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <button
                                            type='button'
                                            className='text-zentive-blue font-semibold hover:underline'
                                        >
                                            &nbsp;
                                            {`(${displayWithDecimals(getTaxesTotalPercentage())}%)`}
                                        </button>
                                    </DialogTrigger>
                                    <AddTaxDialog />
                                </Dialog>
                            </div>
                            <div className='flex items-center pb-2'>
                                <div className=''>{displayCurrency(calcTotalTaxes)}</div>
                                <button type='button' className='ml-1' onClick={handleRemoveTax}>
                                    <CiTrash className='size-7 text-zentive-red-dark' />
                                </button>
                            </div>
                        </>
                    )}
                </div>
                {taxes.productTax > 0 || taxes.serviceTax > 0 || taxes.expenseTax > 0 ? (
                    <FinancialManagementTaxes
                        productsTaxAmount={productsTaxAmount}
                        servicesTaxAmount={servicesTaxAmount}
                        expensesTaxAmount={expensesTaxAmount}
                    />
                ) : null}
                <div className='flex flex-row justify-between pt-4 border-t-[6px] border-zentive-gray-medium h-16 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Total</div>
                    <div className='text-zentive-green-dark font-semibold px-8'>
                        {displayCurrency(
                            Number(discount.totalAfterDiscount.toFixed(2)) +
                                Number(calcTotalTaxes.toFixed(2)),
                        )}
                    </div>
                </div>
            </div>
            <div className='border border-[#EBEBEB] p-6 my-16 rounded-md'>
                <CardTitle className='text-[18px] mb-6'>Cost and Estimated Margin</CardTitle>
                <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold px-4'>Product and Service Cost</div>
                    <div className='px-8'>
                        {displayCurrency(Math.floor(invoiceContent.totalUnitPrice))}
                    </div>
                </div>
                <div className='flex flex-row justify-between h-10 mb-4 text-zentive-gray-medium'>
                    <div className='flex flex-col px-4'>
                        <div className='font-semibold'>Net Profit Margin</div>
                        <div className='font-semibold'>(Excluded of Tax)</div>
                    </div>
                    <div className='text-zentive-green-dark font-semibold px-8'>
                        {`${displayCurrency(estimate)} (${displayWithDecimals(
                            estimatePercentage,
                        )}%)`}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillingAmount
