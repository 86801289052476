import { z } from 'zod'
import { addressSchema } from '../address/schema'
import { baseSchema } from '../common/schema'
import { profileSchema } from '../profile/schema'
import { quoteSchema } from '../quoting/schema'
import {
    crewMemberDetailsSchema,
    crewMemberSchema,
    crewMemberShortSchema,
    crewSchema,
} from '../crew/schema'

import { imageValidator, stringValidator } from '@/utils/regex'

import { PaginationSchema } from '@/components/Pagination/schema'
import { appointmentSchema } from '../appointment/schema'
import { noteSchema } from '../notes/schema'
import { invoiceSchema } from '../invoicing/schema'
export type JobType = 'oneOffJob' | 'recurring'

export type ManipulateType = 'week' | 'month' | 'year' | 'day'
export type RepeatType = 'WEEKLY' | 'MONTHLY' | 'YEARLY' | 'BI_WEEKLY' | 'DAILY'

export type JobStatusType =
    | 'ACT'
    | 'IN_PROGRESS'
    | 'COMPLETED'
    | 'CANCELED'
    | 'LATE_VISIT'
    | 'RESCHEDULE'
    | 'ALL'

export type JobStatusLabelType =
    | 'Active'
    | 'In Progress'
    | 'Completed'
    | 'Canceled'
    | 'Late Visit'
    | 'Reschedule'

const jotFilterStatusSchema = z.array(
    z.enum(['ACT', 'ALL', 'IN_PROGRESS', 'COMPLETED', 'CANCELED', 'LATE_VISIT', 'RESCHEDULE']),
)

export const jobSchema = baseSchema.extend({
    jobId: z.string().optional(),
    jobNumber: z.number().optional(),
    businessId: z.string(),
    addressId: z.string(),
    address: addressSchema.optional(),
    quoteId: z.string(),
    quote: quoteSchema,
    appointment: appointmentSchema,
    crewId: z.string().optional(),
    foreman: crewMemberDetailsSchema.optional(),
    crewMemberIds: z.array(z.string()).optional(),
    defaultForemanId: z.string().optional(),
    crew: crewSchema.optional(),
    note: stringValidator('Note'),
    status: z.string().optional(),
    description: stringValidator('Description', {
        fieldType: 'textarea',
    }),
    jobImages: z.array(imageValidator).optional(),
})

export const createNewJobImageSchema = jobSchema.pick({
    jobImages: true,
})

export const jobImagesSchema = baseSchema.extend({
    jobImageId: z.string(),
    jobID: z.string(),
    jobImageUrl: z.string(),
    createdAt: z.string(),
    createdBy: z.string(),
    updatedAt: z.string(),
    updatedBy: z.string(),
})

export const dailyTimeRecordSchema = baseSchema.extend({
    dtrId: z.string(),
    jobId: z.string(),
    time: z.string(),
    jobCrewMemberId: z.string(),
    type: z.string(),
    is_done: z.boolean(),
    memberDetails: crewMemberSchema,
})

export const jobCrewMemberSchema = jobSchema
    .pick({ jobId: true })
    .merge(crewMemberDetailsSchema.pick({ crewMemberId: true }))
    .extend({
        memberProfile: crewMemberSchema,
    })
    .extend({
        dailyTimeRecord: z.array(dailyTimeRecordSchema).optional(),
        jobCrewMemberId: z.string().optional(),
        crewMemberId: z.string().optional(),
        totalWorkingHours: z.number().optional(),
        compensation: z.number().optional(),
    })

export const jobLogsInfoSchema = jobCrewMemberSchema.extend({
    dailyTimeRecords: z.array(dailyTimeRecordSchema).optional(),
})

export const viewJobSchema = jobSchema
    .omit({ note: true, jobImages: true, foreman: true, crewMemberIds: true })
    .extend({
        jobCrewMembers: z.array(jobCrewMemberSchema),
    })
    .extend({
        foreman: jobCrewMemberSchema,
    })
    .extend({
        crew: crewSchema.optional(),
    })
    .extend({
        note: z.array(noteSchema).optional(),
        jobImages: z.array(jobImagesSchema),
        totalCompensation: z.number(),
    })
    .extend({
        dailyTimeRecord: z.array(dailyTimeRecordSchema).optional(),
        invoice: invoiceSchema.optional(),
    })

export const convertToJobSchema = jobSchema.extend({
    quote: quoteSchema.optional(),
    profile: profileSchema.optional(),
})

export const manualJobSchema = jobSchema
    .extend({
        profileId: z.string(),
        profile: profileSchema.optional(),
    })
    .omit({
        quoteId: true,
    })

export const updateJobSchema = jobSchema.omit({
    jobNumber: true,
    quote: true,
    quoteId: true,
    status: true,
    note: true,
    address: true,
    appointment: true,
    crew: true,
})

export const profileJobSchema = profileSchema.extend({
    job: jobSchema,
})

export const jobResponseSchema = z.object({
    content: viewJobSchema,
})

export const jobListSchema = z.object({
    content: z.array(viewJobSchema),
    meta: PaginationSchema,
})

export const jobLogResponseSchema = viewJobSchema

export const updateJobStatusSchema = z.object({
    jobId: z.string(),
    status: z.string(),
})

export const updateJobStatusCrewInterfaceSchema = updateJobStatusSchema
    .pick({
        jobId: true,
        status: true,
    })
    .extend({
        dtrTime: z.string(),
    })

export const updateDtrSchema = z.object({
    startDtrId: z.string().optional(),
    startTime: z.string().optional(),
    endDtrId: z.string().optional(),
    endTime: z.string().optional(),
})

//temp
export const jobsRouteSchema = baseSchema.extend({
    jobId: z.string().optional(),
    jobNumber: z.string().optional(),
    businessId: z.string(),
    addressId: z.string(),
    address: addressSchema.optional(),
    quoteId: z.string(),
    quote: quoteSchema,
    appointment: appointmentSchema,
    crewId: z.string().optional(),
    foreman: jobCrewMemberSchema.optional(),
    crewMemberIds: z.array(z.string()).optional(),
    defaultForemanId: z.string().optional(),
    crew: crewSchema.optional(),
    note: stringValidator('Note'),
    status: z.string().optional(),
    description: stringValidator('Description', {
        fieldType: 'textarea',
    }),
    jobImages: z.array(imageValidator).optional(),
})

export const rescheduleJobSchema = jobSchema.omit({
    quote: true,
    quoteId: true,
    note: true,
    address: true,
    businessId: true,
    addressId: true,
})

export const jobGroupSchema = baseSchema
    .extend({
        jobGroupId: z.string().optional(),
        jobId: z.string(),
        job: jobsRouteSchema.optional(),
    })
    .merge(baseSchema.partial())

export type CreateJobWithQuoteType = z.infer<typeof convertToJobSchema>
export type CreateJobWithoutQuoteType = z.infer<typeof manualJobSchema>
export type UpdateJobType = z.infer<typeof updateJobSchema>
export type CreateJobType = z.infer<typeof viewJobSchema>
export type CreateNewJobImageType = z.infer<typeof createNewJobImageSchema>
export type UpdateJobDetailsType = z.infer<typeof jobSchema>
export type ReadJobType = z.infer<typeof viewJobSchema>
export type JobDtrType = z.infer<typeof dailyTimeRecordSchema>
export type CrewMemberType = z.infer<typeof crewMemberShortSchema>
export type JobLogsInfoType = z.infer<typeof jobLogsInfoSchema>

export type ProfileJobResponseType = z.infer<typeof profileJobSchema>
export type JobResponseType = z.infer<typeof jobResponseSchema>
export type JobLogResponseType = z.infer<typeof jobLogResponseSchema>
export type JobListType = z.infer<typeof jobListSchema>
export type JobImageType = z.infer<typeof jobImagesSchema>
export type UpdateJobStatusType = z.infer<typeof updateJobStatusSchema>
export type UpdateJobStatusCrewInterfaceType = z.infer<typeof updateJobStatusCrewInterfaceSchema>
export type UpdateCrewDtrType = z.infer<typeof updateDtrSchema>
export type JobCrewMemberType = z.infer<typeof jobCrewMemberSchema>
export type JobGroupType = z.infer<typeof jobGroupSchema>
export type JobFilterStatusType = z.infer<typeof jotFilterStatusSchema>

export type RescheduleJobType = z.infer<typeof rescheduleJobSchema>
