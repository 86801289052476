import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import SearchBar from '@/components/InputSearchBox'
import emptyPlaceholder from '@/assets/private/empty_placeholder_jobs.svg'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { FaPlus } from 'react-icons/fa'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FullProfileType } from '@/api/profile/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { itemsPerPageAtom } from '@/store/manageSubs'
import { Pagination } from '@/components/Pagination'
import JobTable from './JobTable'
import { getJobsByBusinessId } from '@/api/job'
import { jobGenerateInvoiceAtom, jobPaginationAtom, jobStatusFilterAtom } from '@/store/job'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { JobFilterStatusType, JobListType } from '@/api/job/schema'
import { BREADCRUMBS_PADDING_STYLE, DEFAULT_QUOTE_VALUE } from '@/constants'
import { cn } from '@/utils/helper'
import JobStatusFilterModal from './JobTable/JobStatusFilterModal'
import { BsFunnelFill } from 'react-icons/bs'
import { invoiceContentAtom } from '@/store/owner'

const Job = () => {
    const { navigateTo } = useAppNavigate()
    const [keyWord, setKeyWord] = useState<string>('')
    const [jobStatusFilterModal, setJobStatusFilterModal] = useState<boolean>(false)
    const [pagination, setPagination] = useAtom(jobPaginationAtom)
    const [selectedItemsPerPage] = useAtom(itemsPerPageAtom)
    const queryClient = useQueryClient()

    //handle view as owner/admin
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const statusFilter = useAtomValue(jobStatusFilterAtom)
    const setInvoiceContent = useSetAtom(invoiceContentAtom) 
    const setGenerateInvoiceData = useSetAtom(jobGenerateInvoiceAtom)                                    

    const handleSearch = (query: string) => {
        setKeyWord(query)
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }

    const jobQuery = useQuery<JobListType, Error>(
        {
            queryKey: ['jobList', user, keyWord, pagination, statusFilter],
            queryFn: () =>
                getJobsByBusinessId(
                    user.businessId,
                    keyWord,
                    '',
                    pagination,
                    statusFilter as JobFilterStatusType,
                ),
            staleTime: 0,
        },
        queryClient,
    )

    useEffect(() => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
            pageSize: selectedItemsPerPage,
        }))
        setInvoiceContent(DEFAULT_QUOTE_VALUE)
        setGenerateInvoiceData(null)
    }, [selectedItemsPerPage, setPagination, keyWord])

    return (
        <div className={cn('w-full h-full', BREADCRUMBS_PADDING_STYLE)}>
            <JobStatusFilterModal open={jobStatusFilterModal} setOpen={setJobStatusFilterModal} />
            <BreadCrumbs titleName='Jobs' />
            <div className={`flex py-6 ${!jobQuery?.data?.content?.length ? 'justify-end' : 'justify-between'}`}>
                {!!jobQuery?.data?.content?.length  &&
                <div>
                    <SearchBar
                        placeHolder='Search for...'
                        onSearchChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                }
                <div className='flex flex-row gap-x-4'>
                    <Button
                        variant={'link'}
                        className='text-black'
                        onClick={() => setJobStatusFilterModal(true)}
                    >
                        <BsFunnelFill className='h-6 w-6' />
                    </Button>
                    <Button
                        type={'button'}
                        className='bg-zentive-green-dark'
                        onClick={() => {
                            navigateTo('/schedule/jobs/new-job')
                        }}
                    >
                        <FaPlus className='w-4 h-4 mr-2' />
                        Add New Job
                    </Button>
                </div>
            </div>
            {jobQuery?.data?.content?.length ? <JobTable jobs={jobQuery} /> : null}
            {!!jobQuery?.data?.content?.length && (
                <div className='mb-5 mt-3'>
                    {pagination.pageSize !== undefined && (
                        <Pagination
                            itemsPerPage={pagination.pageSize}
                            setPagination={setPagination}
                            totalRecords={jobQuery?.data?.meta.totalRecords ?? 1}
                            pagination={pagination}
                        />
                    )}
                </div>
            )}
            {!jobQuery?.data?.content?.length && (
                <div className='bg-white rounded-sm p-20'>
                    <div className='flex justify-center'>
                        <img
                            src={emptyPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-12 h-60'
                        />
                    </div>
                    <p className='pb-2 text-center text-2xl text-zentive-green-dark font-semibold'> No Jobs Available </p>
                    <p className='pb-6 text-center text-base text-zentive-gray-medium'> There are currently no jobs to display. </p>
                </div>
            )}
        </div>
    )
}

export default Job
