import { checkQuickBooksIntegration } from '@/api/quickbooks'
import { useQuery } from '@tanstack/react-query'

const useQuickBooksStatus = (profileId: string | undefined, isEnabled: boolean = true) => {
    const queryData = useQuery({
        enabled: !!profileId && isEnabled,
        queryKey: ['qbStatus', profileId],
        queryFn: () => checkQuickBooksIntegration(profileId),
        staleTime: 5000,
    })

    return queryData
}

export default useQuickBooksStatus
