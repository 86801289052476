import { Button } from '@/components/Button'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
    DialogTrigger,
} from '@/components/Dialog'
import SearchBar from '@/components/InputSearchBox'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { customerQuoteAtom } from '@/store/owner'
import { useAtom, useAtomValue } from 'jotai'
import { ChangeEvent, startTransition, useEffect, useState } from 'react'
import { FiUserPlus } from 'react-icons/fi'
import { getAllCustomers } from '@/api/profile'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { useFormContext } from 'react-hook-form'
import { ImPencil } from 'react-icons/im'
import { paginationAtom } from '@/store/manageSubs'
import { CustomerProfileListType } from '@/api/profile/schema'
import { ViewAsProfileType } from '@/api/profile/schema'
import { QuoteType } from '@/api/quoting/schema'
import { Pagination } from '@/components/Pagination'
import { SORT, USER_STATUS } from '@/constants'

interface ClientDialogProps {
    isEditing: boolean
}

const ClientDialog: React.FC<ClientDialogProps> = ({ isEditing }) => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const [user] = useAtom(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const [, setCustomer] = useAtom(customerQuoteAtom)
    const { setValue } = useFormContext<QuoteType>()
    const [searchValue, setSearchValue] = useState('')
    const [selectedAddressId, setSelectedAddressId] = useState<string | null>(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [pagination, setPagination] = useAtom(paginationAtom)

    const { data } = useQuery<unknown, AxiosError, CustomerProfileListType>({
        queryKey: ['quoteCustomer', pagination, searchValue, user?.businessId],
        queryFn: () =>
            getAllCustomers(
                user?.businessId as string,
                USER_STATUS.ACTIVATED,
                searchValue,
                SORT.newest,
                pagination,
            ),
    })

    const [tempCustomer, setTempCustomer] = useState<ViewAsProfileType | null>(null)

    const handleOnSubmit = () => {
        setCustomer(tempCustomer)
        setValue('addressId', tempCustomer?.addressId ? tempCustomer.addressId : '')
        if (tempCustomer?.address) {
            setValue('address.addressId', tempCustomer?.addressId ? tempCustomer.addressId : '')
            setValue(
                'address.streetAddress',
                tempCustomer?.address.streetAddress ? tempCustomer.address.streetAddress : '',
            )
            setValue(
                'address.streetTwoAddress',
                tempCustomer?.address.streetTwoAddress ? tempCustomer.address.streetTwoAddress : '',
            )
            setValue('address.city', tempCustomer?.address.city ? tempCustomer.address.city : '')
            setValue('address.state', tempCustomer?.address.state ? tempCustomer.address.state : '')
            setValue(
                'address.zipCode',
                tempCustomer?.address.zipCode ? tempCustomer.address.zipCode : '',
            )
        }
        setValue('name', `${tempCustomer?.firstName ?? ''} ${tempCustomer?.lastName ?? ''}`)
        setValue('profileId', tempCustomer?.profileId ? tempCustomer.profileId : '')
        setValue('businessId', user?.businessId ? user.businessId : '')
        setValue('status', 'pending')
        setSearchValue('')
    }

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    const maxIndex = data?.content?.length ?? 0

    useEffect(() => {
        startTransition(() => {
            setIsButtonDisabled(!selectedAddressId)
        })
    }, [selectedAddressId])

    return (
        <Dialog>
            <DialogTrigger asChild>
                {isEditing ? (
                    <ImPencil className='w-[20px] h-[20px] ml-4 text-zentive-blue cursor-pointer' />
                ) : (
                    <Button
                        type='button'
                        variant='ghost'
                        className='text-zentive-blue px-8 py-6 border border-zentive-blue font-semibold mr-5 hover:bg-zentive-blue hover:text-white mx-5 w-[400px] h-[100px] text-[20px] my-6'
                    >
                        <FiUserPlus className='w-6 h-6 mr-2' />
                        Add a Client
                    </Button>
                )}
            </DialogTrigger>
            <DialogPortal>
                <DialogOverlay>
                    <DialogContent className='max-w-[750px] w-[750px] max-h-[700px] h-[688px] rounded-md '>
                        <DialogHeader>
                            <DialogTitle className='mb-2'>
                                <div className='text-[20px] font-semibold text-left mb-6'>
                                    Select Client
                                </div>
                                <p className='text-left font-normal text-[16px] mb-4'>
                                    Which client would you like to create this for?
                                </p>
                                <div className='font-light mb-4'>
                                    <SearchBar
                                        placeHolder='Search for...'
                                        onSearchChange={handleSearchChange}
                                    />
                                </div>
                            </DialogTitle>
                            <DialogDescription className='max-h-[25rem] overflow-y-auto no-scrollbar'>
                                <RadioGroup defaultValue='1' value={selectedAddressId!}>
                                    {data?.content &&
                                        data?.content?.map((data, index) => (
                                            <div
                                                className={`m-4 ${
                                                    index !== maxIndex - 1 ? 'border-b-2' : ''
                                                }`}
                                            >
                                                <label className='flex flex-row mb-3 text-black cursor-pointer'>
                                                    <RadioGroupItem
                                                        value={data.address.addressId!}
                                                        className={`mr-4 mt-1 text-white ${
                                                            data.address.addressId ===
                                                            selectedAddressId
                                                                ? 'border-[5px] border-zentive-green-dark'
                                                                : ''
                                                        }`}
                                                        onClick={() => {
                                                            setTempCustomer(data)
                                                            setSelectedAddressId(
                                                                data.address.addressId!,
                                                            )
                                                        }}
                                                        disabled={!data.customerId}
                                                    />
                                                    <div className='flex flex-col text-[16px] text-left w-full'>
                                                        <span className='font-semibold text-[16px] w-full mt-[2px] mb-2 capitalize'>
                                                            {data.firstName} {data.lastName}{' '}
                                                            {!data.customerId && (
                                                                <span className='rounded-lg text-[14px] bg-zentive-red-dark m-2 p-1 text-white'>
                                                                    {' '}
                                                                    Unverified{' '}
                                                                </span>
                                                            )}
                                                        </span>
                                                        <div className='flex flex-row'>
                                                            <div className='flex flex-col w-[60%]'>
                                                                <span className='mb-2'>
                                                                    {data.address.streetAddress}
                                                                </span>
                                                                <span>
                                                                    {data.address.city}{' '}
                                                                    {data.address.state}{' '}
                                                                    {data.address.zipCode}
                                                                </span>
                                                            </div>
                                                            <div className='flex flex-col w-[40%] '>
                                                                <span className='mb-2 '>
                                                                    {data.phoneNumber}
                                                                </span>
                                                                <span className='whitespace-pre-wrap '>
                                                                    {data?.email &&
                                                                    data.email?.length > 33
                                                                        ? data.email.slice(0, 30) +
                                                                          '\n' +
                                                                          data.email.slice(30)
                                                                        : data.email}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        ))}
                                </RadioGroup>
                            </DialogDescription>
                            <div className='pb-4 pt-4'>
                                {pagination.pageSize !== undefined && (
                                    <Pagination
                                        itemsPerPage={pagination.pageSize}
                                        setPagination={setPagination}
                                        totalRecords={data?.meta?.totalRecords ?? 1}
                                        pagination={pagination}
                                    />
                                )}
                            </div>
                        </DialogHeader>
                        <DialogFooter className='absolute bottom-0 self-end w-full py-3 px-6 mt-auto rounded-b-md justify-center items-end bg-zentive-gray-light'>
                            <div className='flex flex-row w-[50%]'>
                                <DialogClose asChild>
                                    <Button
                                        type='button'
                                        variant={'outline'}
                                        className='w-full mr-4'
                                        onClick={() => {
                                            setSearchValue('')
                                            setSelectedAddressId(null)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </DialogClose>
                                <DialogClose asChild>
                                    <Button
                                        type='submit'
                                        className='w-full'
                                        disabled={isButtonDisabled}
                                        onClick={() => handleOnSubmit()}
                                    >
                                        Save
                                    </Button>
                                </DialogClose>
                            </div>
                        </DialogFooter>
                    </DialogContent>
                </DialogOverlay>
            </DialogPortal>
        </Dialog>
    )
}

export default ClientDialog
