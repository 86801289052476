import { IoLocationSharp } from 'react-icons/io5'
import { FaScrewdriverWrench } from 'react-icons/fa6'
import { GoClockFill } from 'react-icons/go'
import { selectedJobStatusAtom } from '@/store/job'
import { useAtomValue } from 'jotai'
import emptyAllPlaceHolder from '@/assets/private/empty-all.png'
import emptyCompletedPlaceHolder from '@/assets/private/empty-done.png'
import emptyInProgressPlaceHolder from '@/assets/private/empty-inprogress.png'
import emptyOpenPlaceHolder from '@/assets/private/empty-open.png'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    COMPLETE_FORMAT_WITHOUT_TIME,
    JOB_STATUS,
    SPANISH_FORMAT,
    TWELVE_HOUR_CLOCK_WITH_MERIDIEM,
    TWENTY_FOUR_HOUR_CLOCK,
} from '@/constants'
import { timezoneAtom } from '@/store/auth'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { formatDateTime } from '../JobDateFilter/DateSelector'
import { FaCalendarAlt } from 'react-icons/fa'
import { FC } from 'react'
import { ReadJobType } from '@/api/job/schema'
import { filterJobListByStatus } from '@/utils/job'

export type JobStatusType = 'ACT' | 'IN_PROGRESS' | 'COMPLETED' | 'CANCELED' | 'LATE_VISIT' | 'RESCHEDULE'
export type JobStatusColor = {
    text: string
    color: string
    dot: string
    bg: string
}

type JobListProps = {
    jobList: ReadJobType[]
}

const JobList: FC<JobListProps> = ({ jobList }) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation(['jobs'])
    const selectedStatus = useAtomValue(selectedJobStatusAtom)
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const jobStatus: Record<JobStatusType, JobStatusColor> = {
        ACT: {
            text: t('Open'),
            color: '#707070',
            dot: '#707070',
            bg: '#EBEBEB',
        },
        IN_PROGRESS: {
            text: t('In Progress'),
            color: '#254284',
            dot: '#254284',
            bg: '#E7EAF1',
        },
        COMPLETED: {
            text: t('Completed'),
            color: '#3B671A',
            dot: '#3B671A',
            bg: '#E0FBE2',
        },
        CANCELED: {
            text: t('Canceled'),
            color: '#EE4E4E',
            dot: '#EE4E4E',
            bg: '#FFEAEB',
        },
        LATE_VISIT: {
            text: t('Late Visit'),
            color: '#EE4E4E',
            dot: '#EE4E4E',
            bg: '#FFEAEB',
        },
        RESCHEDULE: {
            text: t('Reschedule'),
            color: '#EE4E4E',
            dot: '#EE4E4E',
            bg: '#FFEAEB',
        },
    }

    const getEmptyState = () => {
        switch (selectedStatus) {
            case JOB_STATUS.IN_PROGRESS:
                return {
                    imgSrc: emptyInProgressPlaceHolder,
                    title: t('No In Progress Jobs Listed'),
                    message: t('There are no in progress jobs at this moment.'),
                }
            case JOB_STATUS.COMPLETED:
                return {
                    imgSrc: emptyCompletedPlaceHolder,
                    title: t('No Completed Jobs Listed'),
                    message: t('There are no completed jobs listed at this moment.'),
                }
            case JOB_STATUS.ACTIVE:
                return {
                    imgSrc: emptyOpenPlaceHolder,
                    title: t('No Open Jobs Listed'),
                    message: t('There are no open jobs listed at this moment.'),
                }
            default:
                return {
                    imgSrc: emptyAllPlaceHolder,
                    title: t('No Jobs Listed'),
                    message: t('There are no jobs listed at this moment'),
                }
        }
    }

    const filteredJobs = filterJobListByStatus(jobList, selectedStatus)

    return (
        <div className='flex flex-col space-y-3 mt-6 mb-32'>
            {filteredJobs?.length > 0 ? (
                filteredJobs?.map((job, index) => (
                    <div
                        key={index}
                        role='button'
                        onClick={() => navigate(`/crew/job/${job.jobId}`)}
                        className='w-10/12 mx-auto bg-white border border-zentive-green-dark py-2 rounded-md p-2'
                    >
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row'>
                                {job?.quote?.profile?.profileImage ? (
                                    <img
                                        src={
                                            typeof job?.quote?.profile?.profileImage === 'string'
                                                ? job?.quote?.profile?.profileImage
                                                : ''
                                        }
                                        alt={`Profile Image`}
                                        className='h-[26px] w-[26px] rounded-full mr-1'
                                    />
                                ) : (
                                    <div className='h-[20px] w-[20px] rounded-full bg-zentive-blue flex justify-center items-center mr-1'>
                                        <span className='text-xs text-white'>
                                            {`${
                                                job?.quote?.profile?.firstName
                                                    ?.charAt(0)
                                                    ?.toUpperCase() ?? ''
                                            }${
                                                job?.quote?.profile?.lastName
                                                    ?.charAt(0)
                                                    ?.toUpperCase() ?? ''
                                            }`}
                                        </span>
                                    </div>
                                )}
                                <p className='text-zentive-green-dark font-semibold text-base 3xs:text-xs 3xs:py-1'>
                                    {job?.quote?.profile?.firstName +
                                        ' ' +
                                        job?.quote?.profile?.lastName}
                                </p>
                            </div>
                            <p
                                className='flex items-center px-4 3xs:px-1 rounded-full text-sm 3xs:text-xs 3xs:my-1'
                                style={{
                                    backgroundColor: jobStatus?.[job?.status as JobStatusType]?.bg,
                                    color: jobStatus?.[job?.status as JobStatusType]?.color,
                                }}
                            >
                                <span
                                    className='w-2 h-2 mr-1 rounded-full inline-block 3xs:w-1 3xs:h-1'
                                    style={{
                                        backgroundColor:
                                            jobStatus?.[job?.status as JobStatusType]?.dot,
                                    }}
                                ></span>
                                {jobStatus?.[job?.status as JobStatusType]?.text}
                            </p>
                        </div>
                        <div className='flex flex-col mt-2 text-sm gap-y-1'>
                            <div className='flex flex-row gap-x-2 mr-1 3xs:text-xs'>
                                <IoLocationSharp className='text-zentive-blue h-5 w-5' />
                                <p>{`${job?.address?.streetAddress + ','} ${
                                    job?.address?.streetTwoAddress !== 'undefined' &&
                                    job?.address?.streetTwoAddress !== undefined
                                        ? job?.address?.streetTwoAddress + ', '
                                        : ''
                                }${job?.address?.city + ','} ${job?.address?.state + ','} ${job
                                    ?.address?.zipCode}`}</p>
                            </div>
                            <div className='flex flex-row gap-x-2'>
                                <FaScrewdriverWrench className='text-zentive-blue h-3 w-3 mt-1' />
                                <div className='flex flex-col'>
                                    {job?.quote?.productServiceGroup
                                        ?.filter((service) => service?.type === 'service')
                                        ?.map((service, index) => (
                                            <div className='flex flex-row gap-x-2 ml-1' key={index}>
                                                <p key={index} className='text-sm'>
                                                    {service?.name ?? ''}
                                                </p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className='flex flex-row gap-x-2'>
                                <FaCalendarAlt className='text-zentive-blue h-3 w-3 mt-1' />
                                <p className='ml-1 3xs:text-xs'>
                                    <span>
                                        {formatDateTime(
                                            job?.appointment?.startDateTime,
                                            timezone?.timeZoneId as string,
                                            i18n.language === 'es'
                                                ? SPANISH_FORMAT
                                                : COMPLETE_FORMAT_WITHOUT_TIME,
                                        )}
                                    </span>
                                </p>
                            </div>
                            {job.appointment.startDateTime && (
                                <div className='flex flex-row gap-x-2'>
                                    <GoClockFill className='text-zentive-blue h-3 w-3 mt-1' />
                                    <p className='ml-1 3xs:text-xs'>
                                        {t('Estimated time of arrival is')}{' '}
                                        <span className='font-semibold'>
                                            {formatDateTime(
                                                job?.appointment?.startDateTime,
                                                timezone?.timeZoneId as string,
                                                i18n.language === 'es'
                                                    ? TWENTY_FOUR_HOUR_CLOCK
                                                    : TWELVE_HOUR_CLOCK_WITH_MERIDIEM,
                                            )}
                                        </span>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                ))
            ) : (
                <div className='mx-4'>
                    <div className='flex justify-center bg-white rounded-md'>
                        <img
                            src={getEmptyState().imgSrc}
                            alt='empty-table-placeholder'
                            className='my-8 h-36'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-white font-semibold mt-4'>
                        {getEmptyState().title}
                    </p>
                    <p className='pb-4 text-center text-white'>{getEmptyState().message}</p>
                </div>
            )}
        </div>
    )
}

export default JobList
