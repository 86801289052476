import { checkEmailValidity, crewForgotPassword } from '@/api/auth'
import { CrewForgotPasswordType, EmailValidityType } from '@/api/auth/schema'
import { Button } from '@/components/Button'
import { encryptString } from '@/utils/crypto'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { cn } from '@/utils/helper'
import { useTranslation } from 'react-i18next'

interface ActionButtonProps {
    buttonContainerClassName?: string
}

const CrewActionButton: React.FC<ActionButtonProps> = ({ buttonContainerClassName }) => {
    const { t } = useTranslation(['forgot-password'])
    const navigate = useNavigate()
    const [email, setEmail] = useState<string>('')

    const { handleSubmit, setError, watch } = useFormContext<CrewForgotPasswordType>()

    const lang = watch('lang')

    const { mutate: checkEmailValidityMu } = useMutation({
        mutationFn: checkEmailValidity,
        onSuccess: (data: EmailValidityType) => {
            const emailOnState = email

            if (data.isEmailExist === true) {
                resetPasswordMu({ email: emailOnState, lang: lang })
            } else {
                setError('email', { message: 'Email does not exist.' })
            }
        },
        onError: (err: AxiosError) => console.log(err),
    })

    const { mutate: resetPasswordMu, isPending: isPasswordResetLoading } = useMutation({
        mutationFn: (data: CrewForgotPasswordType) => crewForgotPassword(data),
        onSuccess: () => {
            const encryptedEmail = encryptString(email)
            navigate(`/crew/check-email?email=${encryptedEmail}`)
            setEmail('')
        },
        onError: (err: AxiosError) => {
            const defaultErrMessage = t('Error occurred, please try again later')
            if (err.request.status === 500) {
                setError('email', {
                    type: 'manual',
                    message: t('Unverified email'),
                })
            } else {
                setError('email', {
                    type: 'manual',
                    message: defaultErrMessage,
                })
            }
        },
    })

    const onSubmit = (data: CrewForgotPasswordType) => {
        setEmail(data.email)
        checkEmailValidityMu({ email: data.email })
    }

    return (
        <div
            className={cn(
                'flex flex-col justify-center items-center space-x-0 mt-2 rounded-md space-y-2 mx-0 md:mx-auto',
                buttonContainerClassName,
            )}
        >
            <Button
                className='w-[260px] max-w-[w-[260px]'
                disabled={isPasswordResetLoading}
                type='submit'
                onClick={handleSubmit(onSubmit)}
            >
                {t('Send Reset Link')}
            </Button>
            <Button
                className='w-[260px] max-w-[w-[260px]'
                onClick={() => navigate(-1)}
                variant={'outline'}
            >
                {t('Back')}
            </Button>
        </div>
    )
}

export default CrewActionButton
