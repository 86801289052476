import {
    BulkProfileUpdateStatusType,
    CustomerProfileListType,
    CustomerProfileType,
} from '@/api/profile/schema'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import Tooltip from '@/components/Tooltip'
import { customerProfileAtom } from '@/store/customer'
import { cn, formatPhoneNumber } from '@/utils/helper'
import { useAtom } from 'jotai'
import { E164Number } from 'libphonenumber-js/core'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { NavLink } from 'react-router-dom'

const tableHeader = [
    {
        name: 'Account Number',
    },
    {
        name: 'Name',
    },
    {
        name: 'Email Address',
    },
    {
        name: 'Address',
    },
    {
        name: 'Contact Number',
    },
]
interface CustomerTableProps {
    customers: CustomerProfileListType | null
    setProfilesToUpdate: React.Dispatch<
        React.SetStateAction<{
            profileIds: string[]
            status?: string | undefined
            businessId?: string
        }>
    >
    setIsCheckAll: React.Dispatch<React.SetStateAction<boolean>>
    isCheckAll: boolean
    profilesToUpdate: BulkProfileUpdateStatusType
}

const CustomerTable: React.FC<CustomerTableProps> = ({
    customers,
    setProfilesToUpdate,
    profilesToUpdate,
    isCheckAll,
    setIsCheckAll,
}) => {
    const [customerProfileData, setCustomerProfileData] = useAtom(customerProfileAtom)

    const handleCheckClick = (subscriber: CustomerProfileType, isCheck: boolean) => {
        if (isCheck) {
            const updatedProfileIds = [...profilesToUpdate.profileIds, subscriber.profileId]
            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: updatedProfileIds,
            })
        } else {
            const updatedProfileIds = profilesToUpdate.profileIds.filter(
                (id) => id !== subscriber.profileId,
            )

            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: updatedProfileIds,
            })
            if (updatedProfileIds?.length === customers?.content?.length) {
                setIsCheckAll(true)
            } else {
                setIsCheckAll(false)
            }
        }
    }

    const handleCheckAll = (checked: boolean) => {
        setIsCheckAll(!isCheckAll)
        if (customers?.content && checked) {
            const updatedProfileIds = [...profilesToUpdate.profileIds]
            customers.content?.forEach((subscriber) => {
                updatedProfileIds.push(subscriber.profileId)
            })
            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: updatedProfileIds,
            })
        } else {
            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: [],
            })
        }
    }

    const xl_vw_already = useMediaQuery({ maxWidth: 1370 })
    const xxl_vw_already = useMediaQuery({ maxWidth: 1620 })

    useEffect(() => {
        setCustomerProfileData(null)
    }, [customerProfileData])

    return (
        <Card className={cn(xxl_vw_already ? 'bg-white overflow-x-auto green-scrollbar' : '')}>
            <CardContent className='mt-4'>
                <Table>
                    <TableHeader style={{ marginBottom: '10px' }}>
                        <TableRow>
                            {tableHeader?.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                    )}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <div className='inline-flex whitespace-nowrap'>
                                        {header.name === 'Account Number' && (
                                            <Checkbox
                                                id={'business-name'}
                                                className=''
                                                onCheckedChange={handleCheckAll}
                                                checked={isCheckAll}
                                            />
                                        )}

                                        <span
                                            className={cn(
                                                header.name === 'Account Number'
                                                    ? 'pl-5 -mt-1'
                                                    : '',
                                            )}
                                        >
                                            {header.name}
                                        </span>
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {customers?.content?.map((data, index) => (
                            <TableRow key={index} className='whitespace-nowrap'>
                                <TableCell className='text-start flex items-center text-zentive-black text-base'>
                                    <Checkbox
                                        id={'business-name' + ' ' + data.accountNumber}
                                        checked={profilesToUpdate?.profileIds?.some(
                                            (p) => p === data.profileId,
                                        )}
                                        className='mr-5'
                                        onCheckedChange={(val) => {
                                            setIsCheckAll(
                                                profilesToUpdate.profileIds?.length + 1 ===
                                                    customers?.content?.length,
                                            )
                                            handleCheckClick(data, val as boolean)
                                        }}
                                    ></Checkbox>
                                    <NavLink
                                        to={`/customers/customer-list/${data.profileId}`}
                                        onClick={() => setCustomerProfileData(data)}
                                    >
                                        {data.accountNumber}
                                    </NavLink>
                                </TableCell>
                                <TableCell className='text-start text-zentive-black text-base'>
                                    <NavLink
                                        to={`/customers/customer-list/${data.profileId}`}
                                        onClick={() => setCustomerProfileData(data)}
                                    >
                                        {data.firstName + '  ' + data.lastName}
                                    </NavLink>
                                </TableCell>
                                <TableCell
                                    className={cn('text-start text-zentive-black text-base')}
                                >
                                    <Tooltip position='top' content={data.email}>
                                        <NavLink
                                            to={`/customers/customer-list/${data.profileId}`}
                                            onClick={() => setCustomerProfileData(data)}
                                        >
                                            <p
                                                className={cn(
                                                    xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                                    xl_vw_already && 'w-full',
                                                )}
                                            >
                                                {data.email}
                                            </p>
                                        </NavLink>
                                    </Tooltip>
                                </TableCell>
                                <TableCell
                                    className={cn('text-start text-zentive-black text-base')}
                                >
                                    <Tooltip position='top' content={data.address.streetAddress}>
                                        <NavLink to={`/customers/customer-list/${data.profileId}`}>
                                            <p
                                                className={cn(
                                                    xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                                    xl_vw_already && 'w-full',
                                                )}
                                            >
                                                {data.address.streetAddress}
                                            </p>
                                        </NavLink>
                                    </Tooltip>
                                </TableCell>
                                <TableCell className='text-start text-zentive-black text-base'>
                                    <NavLink to={`/customers/customer-list/${data.profileId}`}>
                                        {formatPhoneNumber(data.phoneNumber as E164Number)}
                                    </NavLink>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    )
}
export default CustomerTable
