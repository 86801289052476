import { ReadJobType } from '@/api/job/schema'
import { IoLocationSharp } from 'react-icons/io5'
import { BsBoxArrowUpRight } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IViewAddress {
    job: ReadJobType
}
const ViewAddress = ({ job }: IViewAddress) => {
    const { t } = useTranslation(['view-job'])
    return (
        <div className='flex flex-col my-4'>
            <div className='w-10/12 mx-auto bg-white py-2 rounded-md p-2 shadow-md border border-zentive-blue px-3'>
                <div className='flex flex-col mt-2 text-sm gap-y-4'>
                    <div className='flex flex-row justify-between gap-x-2'>
                        <div className='flex flex-row gap-x-2'>
                            <IoLocationSharp className='text-zentive-blue h-5 w-5' />
                            <p className='font-medium text-gray-500 text-md'>
                                {t('View Address in Maps')}
                            </p>
                        </div>
                        <div>
                            <Link
                                target='_blank'
                                to={`https://www.google.com/maps/search/?api=1&query=${job?.address?.gmapLat},${job?.address?.gmapLng}`}
                            >
                                <BsBoxArrowUpRight className='w-4 h-4' />
                            </Link>
                        </div>
                    </div>
                    <div className='flex flex-row gap-x-2 ml-1 mb-2'>
                        <Link
                            target='_blank'
                            to={`https://www.google.com/maps/search/?api=1&query=${job?.address?.gmapLat},${job?.address?.gmapLng}`}
                        >
                            <p className='underline text-zentive-blue'>{`${
                                job?.address?.streetAddress + ','
                            } ${
                                job?.address?.streetTwoAddress !== 'undefined' &&
                                job?.address?.streetTwoAddress !== undefined
                                    ? job?.address?.streetTwoAddress + ', '
                                    : ''
                            }${job?.address?.city + ','} ${job?.address?.state + ','} ${job?.address
                                ?.zipCode}`}</p>
                        </Link>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    )
}

export default ViewAddress
