import { SubscriptionType } from '@/api/subscription/schema'
import { Button } from '@/components/Button'
import { CardFooter } from '@/components/Card'
import { useNavigate } from 'react-router-dom'

const SubscriptionButtons = ({ subscriptionTypes }: { subscriptionTypes: SubscriptionType[] }) => {
    const navigate = useNavigate()
    const handleClick = (planId: string) => navigate(`/sign-up/form?planid=${planId}`)

    const sortedSubscriptionTypes = subscriptionTypes
        .filter((plan) => plan.name !== 'Free Trial')
        .sort((a, b) => {
            const order = ['Starter', 'Standard', 'Growth Pro']
            return order.indexOf(a.name as string) - order.indexOf(b.name as string)
        })

    return (
        <CardFooter className='bg-zentive-gray-light p-6 flex justify-end'>
            <div className='w-[492.59px] flex justify-between'>
                {sortedSubscriptionTypes?.map((plan) => (
                    <Button
                        className='w-32 bg-zentive-blue'
                        key={plan.subscriptionTypeId}
                        onClick={() => handleClick(plan.subscriptionTypeId as string)}
                        type='button'
                    >
                        {`Get ${plan.name}`}
                    </Button>
                ))}
            </div>
        </CardFooter>
    )
}

export default SubscriptionButtons
