import { useNavigate } from 'react-router-dom'
import Confetti from '@/assets/public/create-password-success.png'
import '../index.css'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Button'
const CreatePasswordSuccess = () => {
    const { t } = useTranslation(['forgot-password'])
    const navigate = useNavigate()
    return (
        <div className='crew-bg flex flex-col justify-center items-center z-0'>
            <div className='bg-white flex flex-col justify-center items-center space-y-2 rounded-md py-10'>
                <img
                    src={Confetti}
                    alt='confetti'
                    className='h-[60px] w-[80px] cursor-pointer my-4'
                />
                <h1 className='w-64 md:w-100 text-center text-lg text-zentive-green-dark font-semibold'>
                    {t('Success!')}
                </h1>
                <div className='text-gray-400 w-107 text-left'>
                    <p className='text-center my-4'>
                        {t(`You have successfully created your password.`)}{' '}
                    </p>
                </div>
                <div className='my-4'>
                    <Button
                        type={'button'}
                        onClick={() => navigate('/crew/sign-in')}
                    >
                        {' '}
                        {t('Login to Zentive')}
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default CreatePasswordSuccess