import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useAtom } from 'jotai'
import { selectedCrewTabAtom } from '@/store/employee'
import UpdateCrew from './UpdateCrew'
import CrewSettings from './CrewSettings'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { cn } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import { getCrewById } from '@/api/crew'
import { useParams } from 'react-router-dom'

const ManageCrew = () => {
    const { crewId } = useParams()
    const [selected, setSelected] = useAtom(selectedCrewTabAtom)

    const { data: crew } = useQuery({
        enabled: !!crewId,
        queryKey: ['getCrewById', crewId],
        queryFn: () => getCrewById(crewId),
    })

    const formattedCrewName = crew?.content?.foreMan?.crewProfile?.firstName
        ? `${crew?.content?.foreMan?.crewProfile?.firstName}'s Crew`
        : ''

    return (
        <div className={cn('w-full', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs
                titleName={formattedCrewName}
                pathId={`${crew?.content?.crewId}`}
                label={formattedCrewName}
            />
            <Tabs defaultValue={selected} onValueChange={(val) => setSelected(val)}>
                <TabsList className='w-full flex flex-row mt-[26px] space-x-4 bg-white rounded-md'>
                    <TabsTrigger
                        value='CI'
                        className={
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                        }
                    >
                        Crew Information
                    </TabsTrigger>
                    <TabsTrigger
                        value='SET'
                        className={
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                        }
                    >
                        Settings
                    </TabsTrigger>
                </TabsList>
            </Tabs>
            <div className=''>
                {
                    {
                        CI: <UpdateCrew />,
                        SET: <CrewSettings />,
                    }[selected]
                }
            </div>
        </div>
    )
}
export default ManageCrew
