import { useAtom } from 'jotai'
import { CustomerFilter } from './CustomerFilter'
import { ExportButton } from './ExportButton'
import { JobTypeFilter } from './JobTypeFilter'
import SearchBar from '@/components/InputSearchBox'
import { netGrossFilterAtom } from '@/store/reports'

export const GrossNetFilter = () => {
    const [netGrossFilter, setNetGrossFilter] = useAtom(netGrossFilterAtom)

    return (
        <div className='flex flex-row justify-between p-5 bg-white border border-zentive-gray-medium/50 border-1'>
            <div className='flex items-center'>
                <SearchBar
                    onSearchChange={(e) => {
                        setNetGrossFilter({
                            profileIds: netGrossFilter?.profileIds || [],
                            productServices: netGrossFilter?.productServices || [],
                            keyword: e.target.value,
                        })
                    }}
                    placeHolder='Search for...'
                />
            </div>
            <div className='flex flex-row gap-5'>
                <CustomerFilter />
                <JobTypeFilter />
                <ExportButton />
            </div>
        </div>
    )
}
