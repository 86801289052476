import { Button } from '@/components/Button'
import { useState } from 'react'
import CrewFilter from './CrewFilter'
import StatusFilter from './StatusFilter'
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { BsFunnelFill } from 'react-icons/bs'
import {
    completedFilterCheckAtom,
    isAllCrewSelectedFilterAtom,
    openFilterCheckAtom,
    selectedCrewFilterIdsAtom,
    selectedCrewIdsAtom,
    selectedStatusAtom,
    selectedStatusFilterAtom,
} from '@/store/jobLog'
import { useAtom, useSetAtom } from 'jotai'
import { useQueryClient } from '@tanstack/react-query'

const JobLogFilterButton = () => {
    const queryClient = useQueryClient()
    const [open, setOpen] = useState<boolean>(false)
    const setIsCheckAll = useSetAtom(isAllCrewSelectedFilterAtom)
    const [selectedCrewIdFilter, setSelectedCrewIdFilter] = useAtom(selectedCrewFilterIdsAtom)
    const [selectedStatusFilter, setSelectedStatusFilter] = useAtom(selectedStatusFilterAtom)
    const setSelectedStatus = useSetAtom(selectedStatusAtom)
    const setSelectedCrewIds = useSetAtom(selectedCrewIdsAtom)
    const [openChecked, setOpenChecked] = useAtom(openFilterCheckAtom)
    const [completedChecked, setCompletedChecked] = useAtom(completedFilterCheckAtom)

    const handleSave = () => {
        setOpen(false)
        setSelectedCrewIds(selectedCrewIdFilter)
        setSelectedStatus(selectedStatusFilter)
        queryClient.invalidateQueries({ queryKey: ['jobList'] })
    }

    const handleClearFilter = () => {
        setSelectedCrewIds([])
        setSelectedStatus('')
        setSelectedCrewIdFilter([])
        setIsCheckAll(false)
        setSelectedStatusFilter('')
        setOpenChecked(false)
        setCompletedChecked(false)
        setOpen(false)
        queryClient.invalidateQueries({ queryKey: ['jobList'] })
    }

    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)} open={open}>
            <AlertDialogTrigger asChild>
                <Button variant='ghost'>
                    <BsFunnelFill className='size-[25px] mt-2 cursor-pointer' />
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white rounded-2xl max-w-[550px]'>
                <AlertDialogDescription>
                    <p className='font-semibold text-2xl text-black'>Job Log Filter</p>
                </AlertDialogDescription>
                <AlertDialogDescription>
                    <p className='font-semibold text-md text-gray-600'>Status</p>
                    <StatusFilter />
                </AlertDialogDescription>
                <AlertDialogDescription>
                    <p className='font-semibold text-md text-gray-600'>Crew</p>
                    <CrewFilter />
                </AlertDialogDescription>

                <AlertDialogFooter className='rounded-b-2xl'>
                    <div className='flex justify-between gap-2'>
                        <div>
                            <Button
                                variant='link'
                                className='hover:bg-transparent hover:no-underline font-semibold text-lg text-zentive-blue'
                                onClick={handleClearFilter}
                            >
                                Clear Filters
                            </Button>
                        </div>
                        <div className='flex gap-x-2'>
                            <Button
                                variant='ghost'
                                className='w-[92px] h-11 text-base font-semibold bg-white text-zentive-green-dark ring-zentive-green-dark border border-zentive-green-dark'
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={handleSave}
                                disabled={!openChecked && !completedChecked}
                                className='w-[92px] h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default JobLogFilterButton
