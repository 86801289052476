import { TableHead, TableHeader, TableRow } from '@/components/Table'
import { Checkbox } from '@/components/Checkbox'
import { QuoteListType } from '@/api/quoting/schema'

const tableHeader = ['Customer Name', 'Property', 'Total', 'Status']

type QuotingTableHeadProps = {
    quote: QuoteListType | null
    setQuotesToUpdate: React.Dispatch<
        React.SetStateAction<{
            quoteIds: string[]
        }>
    >
    setIsCheckAll: React.Dispatch<React.SetStateAction<boolean>>
    isCheckAll: boolean
}

const QuotingTableHead: React.FC<QuotingTableHeadProps> = ({
    quote,
    setQuotesToUpdate,
    isCheckAll,
}) => {
    const handleCheckAll = (checked: boolean) => {
        if (checked) {
            const updatedProfileIds = quote?.content
                ?.map((data) => data.quoteId)
                .filter(Boolean) as string[];
    
            setQuotesToUpdate((prev) => ({
                ...prev,
                quoteIds: [...new Set([...prev.quoteIds, ...updatedProfileIds])], 
            }));
        } else {
            const currentQuoteIds = quote?.content
                ?.map((data) => data.quoteId)
                .filter(Boolean) as string[];
    
            setQuotesToUpdate((prev) => ({
                ...prev,
                quoteIds: prev.quoteIds.filter(id => !currentQuoteIds.includes(id)) 
            }));
        }
    }

    return (
        <TableHeader className='bg-white'>
            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                    <Checkbox
                        className='mr-[16px] mt-[2px]'
                        onCheckedChange={handleCheckAll}
                        checked={isCheckAll}
                    />
                    Quote Number
                </TableHead>
                {tableHeader?.map((header, index) => (
                    <TableHead
                        className={`font-semibold text-zentive-gray-medium text-base ${
                            index == 2 ? 'text-right w-[200px]' : ''
                        }${index >= 3 ? 'text-center w-[300px]' : ''}`}
                    >
                        {header}
                    </TableHead>
                ))}
            </TableRow>
        </TableHeader>
    )
}

export default QuotingTableHead