import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import {
    JobCimpletionFilterType,
    JobCompletionReportType,
    JobsCompletedByEmployeeFilterType,
    JobsCompletedByEmployeeReportType,
    NetGrossFilterType,
    NetGrossReportType,
} from './schema'
// import dayjs from 'dayjs'
// import { ISO_8601_WITH_UTC_OFFSET } from '@/constants'

export const getNetGrossReport = async (
    businessId: string,
    filter: NetGrossFilterType | null,
    p: PaginationType,
): Promise<NetGrossReportType> => {
    const res = await axiosInstance.get('/reports/gross-net-income', {
        params: {
            businessId: businessId,
            page: p.page,
            pageSize: p.pageSize,
            profileIds: filter?.profileIds?.join(','),
            productServices: filter?.productServices?.join(','),
            keyword: filter?.keyword,
        },
    })

    return res.data
}

export const getJobCompletionReport = async (
    businessid: string,
    filter: JobCimpletionFilterType | null,
    p: PaginationType,
): Promise<JobCompletionReportType> => {
    const res = await axiosInstance.get('/reports/job-completion', {
        params: {
            businessId: businessid,
            page: p.page,
            pageSize: p.pageSize,
            svcIds: filter?.svcIds?.join(','),
            keyword: filter?.keyword,
        },
    })

    return res.data
}

export const getJobCompletedByEmployeeReport = async (
    businessId: string,
    filter: JobsCompletedByEmployeeFilterType | null,
    p: PaginationType,
): Promise<JobsCompletedByEmployeeReportType> => {
    const res = await axiosInstance.get('/reports/jobs-completed-by-employee', {
        params: {
            businessId: businessId,
            page: p.page,
            pageSize: p.pageSize,
            svcIds: filter?.svcIds?.join(','),
            profileIds: filter?.employeeIds?.join(','),
            keyword: filter?.keyword,
            // start: dayjs(filter?.startDate).format(ISO_8601_WITH_UTC_OFFSET),
            // end: dayjs(filter?.endDate).format(ISO_8601_WITH_UTC_OFFSET),
        },
    })

    return res.data
}
