import { ReadJobType } from '@/api/job/schema'
import { Card, CardContent, CardTitle } from '@/components/Card'
import { US_FORMAT } from '@/constants'
import { editJobAtom } from '@/store/job'
import { formatTo24HourClock } from '@/utils/time'
import { useAtomValue } from 'jotai'
import EditJobPhotos from './EditPhotos'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import EditDropDown from './EditDropDown'
import EditJobAttachmentDescription from './EditNotes'
import { FileInfo } from '@/pages/common/Schedule/Job/NotesAndAttachments'
import { FieldError, useFormContext } from 'react-hook-form'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Textarea } from '@/components/TextArea'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
import { MdClose } from 'react-icons/md'
import {
    RemoveJobImagesParams,
    UpdateJobAttachmentDescription,
    removeJobImages,
    updateJobAttachmentDescription,
} from '@/api/job'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useToast } from '@/hooks/useToast'

interface InternalNotesAndAttachments {
    job: ReadJobType
}

const InternalNotesAndAttachments = ({ job }: InternalNotesAndAttachments) => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const formattedCreatedAt = formatTo24HourClock(job?.createdAt as string, US_FORMAT)

    const [toDeletePhotoIds, setToDeletePhotoIds] = useState<string[]>([])
    const [isPhotosEditing, setIsPhotosEditing] = useState<boolean>(false)
    const [isNotesEditing, setIsNotesEditing] = useState<boolean>(false)

    const isEditing = useAtomValue(editJobAtom)

    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext()

    const dragDropRef = useRef<HTMLDivElement | null>(null)

    const fileInputLocalRef = useRef<HTMLInputElement | null>(null)
    // This watches for changes in 'jobImages' field of your form
    const uploadedFiles: File[] = watch('jobImages')

    const uploadedFilesErrors = errors?.jobImages ? errors.jobImages : []

    const imagePreviews = useMemo(
        () =>
            uploadedFiles?.map((file) =>
                URL.createObjectURL(
                    new Blob(Array(file), {
                        type: file.type,
                    }),
                ),
            ) || [],
        [uploadedFiles],
    )

    const { mutate: removeJobImagesMu } = useMutation({
        mutationFn: (data: RemoveJobImagesParams) => removeJobImages(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            toast({
                description: 'Successfully updated!',
                variant: 'default',
            })
        },
        onError: () =>
            toast({
                description: 'Unable to remove job images.',
                variant: 'destructive',
            }),
    })

    const { mutate: updateJobAttachmentDescriptionMu } = useMutation({
        mutationFn: (data: UpdateJobAttachmentDescription) => updateJobAttachmentDescription(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            toast({
                description: 'Successfully updated!',
                variant: 'default',
            })
            setValue('description', '')
        },
        onError: () =>
            toast({
                description: 'Unable to update job status.',
                variant: 'destructive',
            }),
    })

    const handleFileChange = (fileList: FileList | null, onChange: (val: FileInfo[]) => void) => {
        if (!fileList) return

        const file: File = fileList[0]

        const existingFiles: File[] = uploadedFiles

        // Ensure existingFiles is an array
        if (!Array.isArray(existingFiles)) {
            console.error('existingFiles is not an array')
            return
        }

        // // Update form value for 'jobImages' with new files info
        onChange([...existingFiles, file])
    }

    const handleFiles = useCallback(
        (files: FileList) => {
            const fileArray = Array.from(files)
            setValue('jobImages', [...uploadedFiles, ...fileArray], { shouldValidate: true })
        },
        [setValue, uploadedFiles],
    )

    const handleDragOver = useCallback((e: DragEvent) => {
        e.preventDefault()
    }, [])

    const handleDrop = useCallback(
        (e: DragEvent) => {
            e.preventDefault()
            if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files?.length > 0) {
                handleFiles(e.dataTransfer.files)
                e.dataTransfer.clearData()
            }
        },
        [handleFiles],
    )

    const handleFileRemove = (val: number) => {
        const filesToRetain = uploadedFiles.filter((_, index) => index !== val)

        setValue('jobImages', filesToRetain, { shouldValidate: true })
    }

    useEffect(() => {
        const div = dragDropRef.current
        if (div) {
            // Note: Directly casting event handlers to the expected type for addEventListener
            div.addEventListener('dragover', handleDragOver as (e: Event) => void)
            div.addEventListener('drop', handleDrop as (e: Event) => void)

            return () => {
                div.removeEventListener('dragover', handleDragOver as (e: Event) => void)
                div.removeEventListener('drop', handleDrop as (e: Event) => void)
            }
        }
    }, [handleDragOver, handleDrop])

    useEffect(() => {
        return () => {
            imagePreviews.forEach((url) => window.URL.revokeObjectURL(url))
        }
    }, [imagePreviews, uploadedFiles, job])

    useEffect(() => {
        if (job?.jobImages) {
            const photoIds = job.jobImages?.map((img) => img.jobImageId)
            setToDeletePhotoIds(photoIds)
        }
    }, [job?.jobImages])

    return (
        <Card className='mt-[58px] rounded-none bg-transparent'>
            <CardTitle className='px-[21.55px] h-[55px] flex items-center text-[17.07px] font-semibold'>
                Internal Notes & Images (Optional)
            </CardTitle>
            <CardContent className='border-t px-[21.55px] pb-0 flex flex-col divide-y-2'>
                <div className='space-y-[12.49px] pt-[17.01px] pb-[32px]'>
                    {isEditing && (
                        <>
                            {!job?.description && (
                                <FormField
                                    control={control}
                                    name='description'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Textarea
                                                    className='border p-4 h-24 w-full max-w-full bg-white border-gray-400 text-[#191A0A] text-[16px] focus-visible:ring-0 focus-visible:ring-offset-0'
                                                    placeholder='Description'
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            )}

                            <div
                                className='bg-[#F1F8DF] h-[65.02px] flex flex-row gap-2 items-center justify-center mt-6'
                                ref={dragDropRef}
                            >
                                <p className='text-[13px]'>Drag your image here or</p>
                                <Button
                                    className='h-[22px] w-[143px] text-[13px] bg-transparent text-zentive-green-dark font-bold border-zentive-blue hover:bg-transparent'
                                    onClick={() => {
                                        if (fileInputLocalRef.current) {
                                            fileInputLocalRef.current.click()
                                        }
                                    }}
                                    variant='outline'
                                    type='button'
                                >
                                    Upload Image
                                </Button>
                                <FormField
                                    control={control}
                                    name='jobImages'
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className='hidden'
                                                    type='file'
                                                    multiple={true}
                                                    onChange={(evt) =>
                                                        handleFileChange(
                                                            evt.target.files,
                                                            field.onChange,
                                                        )
                                                    }
                                                    name={field.name}
                                                    onBlur={field.onBlur}
                                                    ref={(e) => {
                                                        fileInputLocalRef.current = e
                                                        field.ref(e)
                                                    }}
                                                />
                                            </FormControl>
                                        </FormItem>
                                    )}
                                />
                                {Object.keys(uploadedFilesErrors)?.length ? (
                                    <div className='mt-[24px]'>
                                        {Object.keys(uploadedFilesErrors)?.map((index) => (
                                            <p
                                                className='text-base text-zentive-red-dark'
                                                key={`error=${index}`}
                                            >
                                                {`File ${Number(index) + 1} ${(
                                                    uploadedFilesErrors as FieldError[]
                                                )[Number(index)]?.message}`}
                                            </p>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        </>
                    )}
                    {!isNotesEditing ? (
                        <div className='flex flex-row justify-between'>
                            <p className='font-semibold'>
                                {job?.quote?.profile?.firstName +
                                    ' ' +
                                    job?.quote?.profile?.lastName}
                            </p>
                            <div className='flex flex-row gap-x-3'>
                                <p className='text-xs text-zentive-gray-medium'>
                                    {formattedCreatedAt}
                                </p>
                                {!isNotesEditing && isEditing && job?.description && (
                                    <EditDropDown
                                        isEditing={isNotesEditing}
                                        setIsEditing={setIsNotesEditing}
                                        type='notes'
                                        deleteFn={() =>
                                            updateJobAttachmentDescriptionMu({
                                                jobId: job?.jobId as string,
                                                description: '',
                                            })
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    ) : (
                        <EditJobAttachmentDescription job={job} setIsOpen={setIsNotesEditing} />
                    )}
                    <p className='w-fit break-all'>
                        {job?.description || 'No description provided.'}
                    </p>
                </div>
                <div className='space-y-[12.49px] pt-[17.01px] pb-[32px]'>
                    <div className='flex flex-row justify-between'>
                        <p className='font-semibold'>
                            {job?.quote?.profile?.firstName + ' ' + job?.quote?.profile?.lastName}
                        </p>
                        <div className='flex flex-row gap-x-3'>
                            <p className='text-xs text-zentive-gray-medium'>{formattedCreatedAt}</p>
                            {!isPhotosEditing && isEditing && job?.jobImages && (
                                <EditDropDown
                                    isEditing={isPhotosEditing}
                                    setIsEditing={setIsPhotosEditing}
                                    type='photo'
                                    deleteFn={() =>
                                        removeJobImagesMu({ jobImageIds: toDeletePhotoIds })
                                    }
                                />
                            )}
                        </div>
                    </div>
                    {!isPhotosEditing ? (
                        <div
                            className={`flex flex-row ${
                                job?.jobImages?.length ? 'gap-[16px]' : ''
                            }`}
                        >
                            {job?.jobImages?.length
                                ? job.jobImages?.map((img) => (
                                      <img
                                          className='h-[80px] w-[82px] cursor-pointer'
                                          src={img.jobImageUrl}
                                          key={img.jobImageId}
                                      />
                                  ))
                                : ''}

                            {uploadedFiles?.length && imagePreviews?.length
                                ? imagePreviews?.map((src, index) => (
                                      <div className='relative h-[80px] w-[82px]' key={src}>
                                          <img
                                              key={index}
                                              src={src}
                                              alt={uploadedFiles[index].name}
                                              className='h-full w-full object-cover'
                                          />
                                          <div
                                              className='absolute top-0 right-0 cursor-pointer'
                                              onClick={() => handleFileRemove(index)}
                                          >
                                              <MdClose className='h-5 w-5 absolute top-0 right-0 p-0 text-white bg-zentive-gray-medium' />
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>
                    ) : (
                        <EditJobPhotos job={job} setIsOpen={setIsPhotosEditing} />
                    )}
                    <p className='w-fit break-all'>
                        {!imagePreviews?.length && !job?.jobImages && 'No uploaded images yet'}
                    </p>
                </div>
            </CardContent>
        </Card>
    )
}

export default InternalNotesAndAttachments
