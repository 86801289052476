import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { LAPTOP_MAX_WIDTH, ROLE } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { NavigationProps } from '@/types/NavProps'
import { atom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { AiOutlineFileText } from 'react-icons/ai'
import { BsCreditCard } from 'react-icons/bs'
import { HiOutlineCurrencyDollar, HiOutlineKey, HiOutlineUsers } from 'react-icons/hi'
import {
    HiOutlineClock,
    // HiOutlineDocumentChartBar,
    HiOutlineHome,
    HiOutlineMegaphone,
    HiOutlineReceiptRefund,
} from 'react-icons/hi2'
import { PiCallBell, PiChatTextBold } from 'react-icons/pi'
import { TbFileTime } from 'react-icons/tb'
import { useMediaQuery } from 'react-responsive'
import { useLocation, useNavigate } from 'react-router-dom'
import { MenuChildren } from './MenuChild/index'
import useStripeConnectStatus from '@/hooks/useStripeConnectStatus'
import { getAllowedNavigationItems } from '@/utils/navigation'

export const navigationItems: NavigationProps[] = [
    {
        name: 'Dashboard',
        icon: HiOutlineHome,
        href: '/dashboard',
        allowedRoles: [ROLE.ADMIN, ROLE.OWNER, ROLE.CREW, ROLE.CO_OWNER],
    },
    {
        name: 'Customers',
        icon: HiOutlineUsers,
        href: '#',
        allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
        children: [
            {
                name: 'Customer List',
                href: '/customers/customer-list',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
            {
                name: 'Customer Messaging',
                href: '/customers/customer-messaging',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
            {
                name: 'Lead Management (CRM)',
                href: '/customers/lead-management',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
        ],
    },
    {
        name: 'Schedule',
        icon: HiOutlineClock,
        href: '#',
        allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
        children: [
            {
                name: 'Calendar',
                href: '/schedule/calendar',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
            {
                name: 'Employees',
                href: '/schedule/employee',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
            {
                name: 'Jobs',
                href: '/schedule/jobs',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
            {
                name: 'Crew',
                href: '/schedule/crew',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
            {
                name: 'Job Log',
                href: '/schedule/job-log',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
            {
                name: 'Routing',
                href: '/schedule/routing',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
        ],
    },
    {
        name: 'Financial Management',
        icon: HiOutlineCurrencyDollar,
        href: '#',
        allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
        children: [
            {
                name: 'Quoting',
                href: '/financial-management/quoting',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
            {
                name: 'Invoicing',
                href: '/financial-management/invoicing',
                allowedRoles: [ROLE.OWNER, ROLE.CO_OWNER],
            },
        ],
    },
    // {
    //     name: 'Reports',
    //     icon: HiOutlineDocumentChartBar,
    //     href: '#',
    //     allowedRoles: [ROLE.ADMIN, ROLE.OWNER],
    //     children: [
    //         {
    //             name: 'Gross and Net Income',
    //             href: '/reports/gross-net-income',
    //             allowedRoles: [ROLE.ADMIN, ROLE.OWNER, ROLE.CO_OWNER],
    //         },
    //         {
    //             name: 'Job Completion Reports',
    //             href: '/reports/job-completion-reports',
    //             allowedRoles: [ROLE.ADMIN, ROLE.OWNER, ROLE.CO_OWNER],
    //         },
    //         {
    //             name: 'Jobs Completed By Employee',
    //             href: '/reports/jobs-completed-employee-reports',
    //             allowedRoles: [ROLE.ADMIN, ROLE.OWNER, ROLE.CO_OWNER],
    //         },
    //     ],
    // },
    {
        name: 'User Roles',
        icon: HiOutlineKey,
        href: '#',
        allowedRoles: [ROLE.ADMIN],
        children: [
            {
                name: 'User Roles child',
                href: '#',
                allowedRoles: [ROLE.ADMIN],
            },
        ],
    },
    {
        name: 'Manage Subscription',
        icon: HiOutlineMegaphone,
        href: '#',
        allowedRoles: [ROLE.ADMIN],
        children: [
            {
                name: 'Subscribers',
                href: '/manage-subs/subscribers',
                allowedRoles: [ROLE.ADMIN],
            },
            { name: 'Trash', href: '/manage-subs/trash', allowedRoles: [ROLE.ADMIN] },
        ],
    },
    {
        name: 'Payments',
        icon: BsCreditCard,
        href: '/customer-portal/payments',
        allowedRoles: [ROLE.CUSTOMER],
    },
    {
        name: 'Quotes',
        icon: AiOutlineFileText,
        href: '/customer-portal/quotes',
        allowedRoles: [ROLE.CUSTOMER],
    },
    {
        name: 'Invoices',
        icon: AiOutlineFileText,
        href: '/customer-portal/invoices',
        allowedRoles: [ROLE.CUSTOMER],
    },
    {
        name: 'History',
        icon: TbFileTime,
        href: '/customer-portal/history',
        allowedRoles: [ROLE.CUSTOMER],
    },
    {
        name: 'Service Notes',
        icon: PiChatTextBold,
        href: '/customer-portal/service-notes',
        allowedRoles: [ROLE.CUSTOMER],
    },
    {
        name: 'Request Service',
        icon: PiCallBell,
        href: '/customer-portal/request-service',
        allowedRoles: [ROLE.CUSTOMER],
    },
    {
        name: 'Refunds',
        icon: HiOutlineReceiptRefund,
        href: '/customer-portal/refunds',
        allowedRoles: [ROLE.CUSTOMER],
    },
]

export const navAtom = atom<boolean>(false)

export const Sidebar = () => {
    const [currentTab, setCurrentTab] = useState(0)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const currentUser = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const navigate = useNavigate()
    const location = useLocation()

    const { data: isConnectedToStripe } = useStripeConnectStatus()

    useEffect(() => {
        const currentItem = navigationItems.find((item) => item.href === location.pathname)

        const roleNameToCheck = currentUser?.role.roleName as string

        if (currentItem?.allowedRoles && !currentItem.allowedRoles.includes(roleNameToCheck)) {
            navigate('/dashboard')
        }
    }, [location.pathname, currentUser, isViewedAsOwner])

    const allowedNavigationItems = getAllowedNavigationItems(
        navigationItems,
        isConnectedToStripe,
        currentUser?.role?.roleName,
    )

    const xl_vw_already = useMediaQuery({ maxWidth: LAPTOP_MAX_WIDTH })

    return (
        <>
            {!xl_vw_already && (
                <div className='hidden lg:flex lg:flex-col lg:fixed lg:inset-y-0 lg:shadow-lg lg:w-72 lg:shadow-gray-300'>
                    <div
                        className={
                            'flex grow flex-col gap-y-5 overflow-y-auto pb-4 border-gray-200 bg-white'
                        }
                    >
                        <div className='flex item-center justify-center mt-3'>
                            <button
                                onClick={() => {
                                    navigate('/dashboard')
                                    setCurrentTab(0)
                                }}
                            >
                                <img
                                    src={zentiveLogo}
                                    alt='Zentive Logo'
                                    className='h-[44px] w-[125px] cursor-pointer'
                                />
                            </button>
                        </div>
                        <nav
                            className='w-72 flex-auto space-y-2 bg-white overflow-auto no-scrollbar'
                            aria-label='Sidebar'
                        >
                            {allowedNavigationItems?.map((item, index) => (
                                <div key={`${item?.name}-${index}`}>
                                    <MenuChildren
                                        index={index}
                                        isOpen={index === currentTab}
                                        item={item}
                                        isStripeLater={!!!isConnectedToStripe}
                                        setCurrentTab={setCurrentTab}
                                    />
                                </div>
                            ))}
                        </nav>
                    </div>
                </div>
            )}
        </>
    )
}
