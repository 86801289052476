import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useAtom } from 'jotai'
import { selectedEmployeeTabAtom } from '@/store/employee'
import EmployeeNotes from './EmployeeNotes'
import EmployeeSettings from './EmployeeSettings'
import UpdateEmployee from './UpdateEmployee'
import { useLocation, useParams } from 'react-router-dom'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { cn, formatFullName } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import { getEmployeeById } from '@/api/profile'

const ManageEmployee = () => {
    const [selected, setSelected] = useAtom(selectedEmployeeTabAtom)
    const location = useLocation()

    const { profileId } = useParams()

    const { data: employeeData } = useQuery({
        queryKey: ['employee', profileId],
        queryFn: () => getEmployeeById(profileId),
    })

    const fullNameEmployee = formatFullName(employeeData?.firstName, employeeData?.lastName)

    return (
        <div className={cn('w-full', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs
                titleName={fullNameEmployee}
                label={fullNameEmployee}
                pathId={employeeData?.profileId}
            />
            <Tabs defaultValue={selected} onValueChange={(val) => setSelected(val)}>
                <TabsList className='w-full flex flex-row mt-[26px] space-x-4 bg-white rounded-md'>
                    <TabsTrigger
                        value='PI'
                        className={
                            'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                        }
                    >
                        Personal Information
                    </TabsTrigger>
                    {location.pathname.includes('/schedule/employee/update') && (
                        <TabsTrigger
                            value='NOTES'
                            className={
                                'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                            }
                        >
                            Notes
                        </TabsTrigger>
                    )}
                    {location.pathname.includes('/schedule/employee/update') && (
                        <TabsTrigger
                            value='SET'
                            className={
                                'w-1/3 h-[54px] text-[18px] text-zentive-gray-medium data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4 sm:truncate ...'
                            }
                        >
                            Settings
                        </TabsTrigger>
                    )}
                </TabsList>
            </Tabs>
            <div className=''>
                {
                    {
                        PI: <UpdateEmployee />,
                        NOTES: <EmployeeNotes />,
                        SET: <EmployeeSettings />,
                    }[selected]
                }
            </div>
        </div>
    )
}
export default ManageEmployee
