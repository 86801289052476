import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/Select'
import { FC } from 'react'
import { ModalVariants } from '..'

type ExportSelectionProps = {
    onValueChange: (val: ModalVariants) => void
    value: string
}

const ExportSelection: FC<ExportSelectionProps> = ({ onValueChange }) => {
    return (
        <>
            <Select onValueChange={onValueChange} value={''} disabled>
                <SelectTrigger className='h-[44px] w-[108px] bg-zentive-bg-100 border border-zentive-green-dark font-semibold text-zentive-green-dark px-[20px]'>
                    <SelectValue placeholder='Export' />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem value='create' disabled>
                            Export as PDF
                        </SelectItem>
                        <SelectItem value='import' disabled>
                            Export as CSV
                        </SelectItem>
                    </SelectGroup>
                </SelectContent>
            </Select>
        </>
    )
}

export default ExportSelection
