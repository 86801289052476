import { lazy, Suspense } from 'react'
import Spinner from '@/components/Spinner'
import useStripeConnectStatus from '@/hooks/useStripeConnectStatus'
import useQuickBooksStatus from '@/hooks/useQuickBooksStatus'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const AuthorizeQuickBooks = lazy(() => import('./AuthorizeQuickBooks'))
const LinkedQuickBooks = lazy(() => import('./LinkedQuickBooks'))
const SetUpStripeConnect = lazy(() => import('./SetUpStripeConnect'))

const QuickBooks = () => {
    const user = useViewedAsUser()

    const {
        data: isStripeConnected,
        isLoading: isStripeConnectedLoading,
        error: stripeConnectError,
    } = useStripeConnectStatus()

    const {
        data: isQBIntegrated,
        isLoading: isQBIntegratedLoading,
        error: qbIntegrationError,
    } = useQuickBooksStatus(user?.profileId, isStripeConnected)

    if (isStripeConnectedLoading || isQBIntegratedLoading) {
        return <Spinner size='50' variant='normal' />
    }

    if (stripeConnectError || qbIntegrationError) {
        return (
            <p className='text-[16px] text-zentive-red-dark font-semibold'>
                {(stripeConnectError || qbIntegrationError)?.message || 'An error occured'}
            </p>
        )
    }

    return (
        <Suspense fallback={<Spinner size='50' variant='normal' />}>
            {/* If isStripeConnected is false, prompts the business owner to set up stripe connect */}
            {!isStripeConnected ? <SetUpStripeConnect /> : null}

            {/* If isStripeConnect is true, and isQBIntegrated is false, prompts the business owner to authorize quickbooks 
                isQBIntegrated 
            */}
            {isStripeConnected && !isQBIntegrated ? <AuthorizeQuickBooks /> : null}

            {/* If isStripeConnected and isQBIntegrated are both true, renders page for authorzied quickbooks */}
            {isStripeConnected && isQBIntegrated ? <LinkedQuickBooks /> : null}
        </Suspense>
    )
}

export default QuickBooks
