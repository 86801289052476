import { InvoiceType } from '@/api/invoicing/schema'
import { Button } from '@/components/Button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { INVOICE_STATUS } from '@/constants'
import { computeInvoice } from '@/utils/calc'
import { cn } from '@/utils/helper'
import { useNavigate } from 'react-router-dom'

interface IInvoiceContent {
    invoice: InvoiceType
}

const tableHeader = ['Product / Service', 'Description', 'Quantity', 'Price', 'Total']

const InvoiceContent = ({ invoice }: IInvoiceContent) => {
    const navigate = useNavigate()
    const {
        productsTaxAmount,
        servicesTaxAmount,
        expensesTaxAmount,
        totalTaxPercentage,
        totalTaxAmount,
    } = computeInvoice(invoice)

    const getTotalServicePrice = (quantity: number, price: number) => {
        return price * quantity
    }

    const getTotalAfterDiscount = (totalAmount: number, discount: number) => {
        return totalAmount - discount
    }

    return (
        <div>
            <div>
                <Table>
                    <TableHeader>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={`${header}-head`}
                                    className={cn(
                                        'flex-row bg-zentive-green-dark items-center text-center text-white border-4 border-white font-semibold text-base',
                                    )}
                                >
                                    <div className='inline-flex gap-[10px]'>{header}</div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody className='border-4c'>
                        {invoice?.quote?.productServiceGroup.map((item, index) => (
                            <TableRow
                                key={index}
                                className='whitespace-nowrap border-4 border-white bg-zentive-gray-light'
                            >
                                <TableCell className='flex-row items-start border border-r-4 border-white text-start  text-[#191A0A] text-base'>
                                    {item.name}
                                </TableCell>
                                <TableCell className='flex-row items-start border-r-4 border-white text-[#191A0A] text-base '>
                                    {item.description ?? '--'}
                                </TableCell>
                                <TableCell className='flex-row text-right border-r-4 border-white text-[#191A0A] text-base '>
                                    {item.quantity ?? 1}
                                </TableCell>
                                <TableCell className='flex-row text-right border-r-4 border-white text-[#191A0A] text-base '>
                                    {' '}
                                    $
                                    {item?.cost?.toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}
                                </TableCell>
                                <TableCell className='flex-row text-right text-[#191A0A] text-base '>
                                    {' '}
                                    $
                                    {getTotalServicePrice(
                                        item.quantity ?? 1,
                                        item.cost,
                                    )?.toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <div className='flex flex-col px-3 py-4 mx-1 border-b border-r border-l border-zentive-gray-light'>
                {invoice?.expense?.length &&
                    invoice?.expense
                        ?.filter((exp) => !exp.isNotIncludedInInvoice)
                        ?.map((exp, index) => (
                            <div className='flex flex-row justify-between' key={index}>
                                <p>{exp.accountName}</p>
                                <p>
                                    {' '}
                                    $
                                    {exp?.total?.toLocaleString(undefined, {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    })}
                                </p>
                            </div>
                        ))}
            </div>
            <div className='flex flex-row justify-between mx-1 my-8'>
                <div>
                    <p className='font-semibold'>Notes</p>
                    <div className='h-44 w-[450px] max-w-[450px] border-2 rounded-lg'>
                        {invoice.quote?.internalNotes ?? 'No notes available'}
                    </div>
                </div>
                <div className='flex flex-col bg-zentive-green-light my-6 h-auto w-[580px] max-w-[580px] space-y-4 px-6'>
                    <div className='flex flex-row justify-between border-b-2 py-2 border-dotted border-zentive-gray-semi-medium'>
                        <p className='text-zentive-gray-medium'>Labor Cost</p>
                        <p>
                            {' '}
                            $
                            {invoice?.laborCost?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </p>
                    </div>
                    <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-semi-medium'>
                        <p className='text-zentive-gray-medium'>Subtotal</p>
                        <p>
                            {' '}
                            $
                            {invoice?.subTotal?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </p>
                    </div>
                    <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-semi-medium'>
                        <p className='text-zentive-gray-medium'>Discount</p>
                        <p>
                            {' '}
                            -$
                            {invoice?.discount?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </p>
                    </div>
                    <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-semi-medium'>
                        <p className='text-zentive-gray-medium'>Total After Discount</p>
                        <p>
                            {' '}
                            $
                            {getTotalAfterDiscount(
                                invoice?.totalAmount as number,
                                invoice?.discount as number,
                            )?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </p>
                    </div>
                    <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-semi-medium'>
                        <div className='text-zentive-gray-medium'>
                            Tax <span className='text-zentive-blue'>{invoice.taxName}</span>
                            <span className='text-zentive-blue'>
                                {`(${totalTaxPercentage.toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    minimumFractionDigits: 2,
                                })}%)`}
                            </span>
                        </div>
                        <div>
                            $
                            {totalTaxAmount.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </div>
                    </div>
                    <div className='flex flex-col justify-between h-24 text-zentive-gray-medium mb-6'>
                        {(invoice?.productTax as number) > 0 && (
                            <div className='flex flex-row justify-between px-2'>
                                <p className='pl-1'>
                                    - Products {(invoice?.productTax as number) / 100}%{' '}
                                </p>
                                <p>${productsTaxAmount.toFixed(2)}</p>
                            </div>
                        )}
                        {(invoice?.serviceTax as number) > 0 && (
                            <div className='flex flex-row justify-between px-2'>
                                <p className='pl-1'>
                                    - Services {(invoice?.serviceTax as number) / 100}%{' '}
                                </p>
                                <p>${servicesTaxAmount.toFixed(2)}</p>
                            </div>
                        )}
                        {(invoice?.expenseTax as number) > 0 && (
                            <div className='flex flex-row justify-between px-2'>
                                <p className='pl-1'>
                                    - Expenses {(invoice?.serviceTax as number) / 100}%{' '}
                                </p>
                                <p>${expensesTaxAmount.toFixed(2)}</p>
                            </div>
                        )}
                    </div>
                    <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-semi-medium'>
                        <p className='text-zentive-gray-medium text-2xl'>Total</p>
                        <p>
                            {' '}
                            $
                            {invoice?.totalAmount?.toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                        </p>
                    </div>
                </div>
            </div>
            {invoice.status === INVOICE_STATUS.PAID && (
                <>
                    <p>To pay please click the button below:</p>
                    <div className='justify-center align-center text-center py-4'>
                        <Button
                            type={'button'}
                            className='px-24'
                            onClick={() => {
                                navigate('/customer-portal/payments')
                            }}
                        >
                            Pay Now
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}

export default InvoiceContent
