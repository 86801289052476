import { cn } from '@/utils/helper'
import { FaCheckCircle } from 'react-icons/fa'

export type StepKey = 1 | 2

type PaymentStepProps = {
    currentStep: StepKey
    stepNumber: StepKey
}

const PAYMENT_STEPS: Record<StepKey, string> = {
    1: 'Payment Information',
    2: 'Set up Stripe Account',
}

const PaymentStep = ({ currentStep, stepNumber }: PaymentStepProps) => {
    const isStepOneDone = stepNumber > currentStep
    const isActiveStep = currentStep === stepNumber

    return (
        <div className='w-full h-full'>
            <div className='h-[20px] flex flex-row gap-[12px] w-full items-center justify-center'>
                {isActiveStep ? (
                    <div className='shrink-0 mt-[0.5px] h-[16px] w-[16px] rounded-full bg-zentive-blue outline outline-1 outline-offset-1 outline-zentive-blue' />
                ) : (
                    <FaCheckCircle
                        className={cn(
                            'shrink-0 h-[20px] w-[20px] bg-white',
                            isStepOneDone ? 'text-zentive-gray-medium' : 'text-zentive-green-dark',
                        )}
                    />
                )}
                <hr
                    className={cn(
                        'h-[3px] w-full border-none',
                        isActiveStep
                            ? 'bg-zentive-blue'
                            : isStepOneDone
                              ? 'bg-zentive-gray-medium'
                              : 'bg-zentive-green-dark',
                    )}
                />
            </div>

            <span
                className={cn(
                    'text-[12px] mt-2 block',
                    isActiveStep
                        ? 'text-zentive-blue'
                        : isStepOneDone
                          ? 'text-zentive-gray-medium'
                          : 'text-zentive-green-dark',
                )}
            >
                Step {stepNumber}
            </span>
            <p>{PAYMENT_STEPS[stepNumber]}</p>
        </div>
    )
}

export default PaymentStep
