import { Avatar, AvatarComponent, AvatarFallback } from '@/components/Avatar'
import { Card, CardContent, CardFooter, CardHeader } from '@/components/Card'
import ImageUploader from '@/components/Common/ImageUploader'
import { getFirstChar } from '@/utils/helper'
import { FC } from 'react'
import EmployeeButtons from './EmployeeButtons'
import EmployeeDetailsFields from './EmployeeDetailsFields'
import EmployeeCompensationFields from './EmployeeCompensationFields'
import { USER_STATUS } from '@/constants'
import { useFormContext } from 'react-hook-form'
import { EmployeeType } from '@/api/employee/schema'
import CompleteAddress from '@/components/CompleteAddress'
import { useAtom } from 'jotai'
import { employeeImgAtom } from '@/store/employee'

export type ActionMode = 'create' | 'update'

type BaseEmployeeProps = {
    isUpdated?: boolean
    mode: ActionMode
    user?: EmployeeType | undefined
}

const BaseEmployee: FC<BaseEmployeeProps> = ({ isUpdated = true, mode, user = undefined }) => {
    const { getValues, setValue } = useFormContext()
    const [selectedImage, setSelectedImage] = useAtom(employeeImgAtom)

    const employee = user || (getValues('') as EmployeeType)

    const firstNameInitial = getFirstChar(employee?.firstName)
    const lastNameInitial = getFirstChar(employee?.lastName)

    return (
        <div className='w-full'>
            <CardHeader className='bg-white flex justify-center items-center shadow-lg rounded-t-lg'>
                <Avatar className='w-[74px] h-[74px]'>
                    {mode === 'update' && (
                        <>
                            <AvatarComponent user={employee} selectedImage={selectedImage} />
                            <AvatarFallback className='text-2xl font-semibold'>
                                {firstNameInitial + lastNameInitial}
                            </AvatarFallback>
                        </>
                    )}
                    {mode === 'create' && (
                        <AvatarComponent user={null} selectedImage={selectedImage} />
                    )}
                </Avatar>
                {employee?.status !== USER_STATUS.INACTIVE && (
                    <ImageUploader
                        onFilesChange={(data) => {
                            setSelectedImage(data)
                            setValue('profileImage', data[0]?.file)
                        }}
                        maxSize={4.9}
                        accept='.png,.jpeg,.jpg'
                        label={mode === 'update' ? 'Change photo' : 'Upload photo'}
                        labelClassname='text-zentive-blue text-decoration-line: underline'
                    />
                )}
            </CardHeader>
            <CardContent className='bg-white shadow-lg'>
                <EmployeeDetailsFields mode={mode} />
                <CompleteAddress className='mt-[14px]' />
                <EmployeeCompensationFields />
            </CardContent>
            <Card className='w-full text-center'>
                <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-[#EBEBEB]'>
                    <EmployeeButtons isUpdated={isUpdated} mode={mode} />
                </CardFooter>
            </Card>
        </div>
    )
}

export default BaseEmployee
