import { axiosInstance } from '../axiosInstance'
import { CreateExpenseType, QuoteListType } from '@/api/quoting/schema'
import { PaginationType } from '@/components/Pagination/schema'
import { BulkDeleteQuoteType, GetJobQuoteType, QuoteType } from './schema'

export const getAllQuotes = async (
    businessId: string,
    keyword: string,
    filter: string,
    filterApprove: string,
    p: PaginationType,
): Promise<QuoteListType> => {
    const { page, pageSize } = p
    const response = await axiosInstance.get(
        `/quotes?businessId=${businessId}&keyword=${keyword}&filter=${filter}&filterApprove=${filterApprove}&page=${page}&pageSize=${pageSize}`,
    )
    return response.data
}

export const saveQuote = async (quote: QuoteType) => {
    const res = await axiosInstance.post(`/quotes/create`, quote)
    return res.data
}

export const getCustomerQuotes = async (profileId: string, businessId: string) => {
    const response = await axiosInstance.get(
        `/quotes/customer?profileId=${profileId}&businessId=${businessId}`,
    )
    return response.data.content
}

export const getQuoteNumber = async (businessId: string): Promise<number> => {
    const res = await axiosInstance.get(`/quotes/next-quote-number/${businessId}`)
    return res.data.content
}

export const getQuote = async (quoteId: string) => {
    const response = await axiosInstance.get(`/quotes/${quoteId}`)
    return response.data.content as QuoteType
}

export const deleteQuote = async (quoteIds: BulkDeleteQuoteType) => {
    const response = await axiosInstance.delete('/quotes/delete', {
        data: quoteIds,
    })

    return response.data
}

export const createExpense = async (data: CreateExpenseType, quoteId: string) => {
    const response = await axiosInstance.post(`/expenses?quoteId=${quoteId}`, data)

    return response.data
}

export const deleteExpenseById = async (expenseId: string) => {
    const response = await axiosInstance.delete(`/expenses/delete?expenseId=${expenseId}`)

    return response.data
}

export const updateQuoteStatus = async (quoteId: string | undefined, status: string) => {
    if (!quoteId) {
        throw new Error('quoteId undefined')
    }
    const response = await axiosInstance.post(
        `/quotes/approval?quoteId=${quoteId}&status=${status}`,
    )
    return response.data
}

export const getJobQuote = async (quoteId: string): Promise<GetJobQuoteType> => {
    const response = await axiosInstance.get(`/quotes/get-job-quote?quoteId=${quoteId}`)

    return response.data.content
}
