import { Card, CardContent, CardFooter, CardTitle } from '@/components/Card'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import UseForm from './UseForm'
import ActionButton from './ActionButton'
import { ResetPasswordType, resetPasswordSchema } from '@/api/auth/schema'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const CreateCrewPassword = () => {
    const { pathname } = useLocation()
    const { t } = useTranslation(['reset-password'])
    const methods = useForm<ResetPasswordType>({
        mode: 'all',
        resolver: zodResolver(resetPasswordSchema),
    })

    return (
        <div className='flex mt-[5%] justify-center'>
            <FormProvider {...methods}>
                <Card className='w-[435px] p-7'>
                    <CardTitle className='text-zentive-green-dark text-center py-6'>
                        {t('Create New Password')}
                    </CardTitle>
                    <CardContent>
                        <p className='font-sans-pro text-gray-500'>
                            {pathname.startsWith('/crew')
                                ? t(
                                      'To enhance the security of your account your password must contain the following:',
                                  )
                                : 'To enhance the security of your account your password must contain the following:'}
                        </p>
                        <UseForm />
                    </CardContent>
                    <CardFooter>
                        <ActionButton />
                    </CardFooter>
                </Card>
            </FormProvider>
        </div>
    )
}

export default CreateCrewPassword
