import { useAtom } from 'jotai'
import SearchBar from '@/components/InputSearchBox'
import { jobCompletionFilterAtom } from '@/store/reports'
import { JobTypeFilter } from '../JobTypeFilter'
import { ExportButton } from '../ExportButton'

export const JobCompletionFilter = () => {
    const [jobCompletionFilter, setJobCompletionFilter] = useAtom(jobCompletionFilterAtom)

    return (
        <div className='flex flex-row justify-between p-5 bg-white border border-zentive-gray-medium/50 border-1'>
            <div className='flex items-center'>
                <SearchBar
                    onSearchChange={(e) => {
                        setJobCompletionFilter({
                            svcIds: jobCompletionFilter?.svcIds || [],
                            keyword: e.target.value,
                        })
                    }}
                    placeHolder='Search for...'
                />
            </div>
            <div className='flex flex-row gap-5'>
                <JobTypeFilter />
                <ExportButton />
            </div>
        </div>
    )
}
