import { useFormContext } from 'react-hook-form'

import { AppRichTextBox } from '@/components/Common/AppRichTextbox'
import { EmailServiceParamType } from '@/api/service/schema'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/auth'
import dayjs from 'dayjs'
import { ABBREVIATED_MONTH_DAY_YEAR } from '@/constants'

export const RichTextEditorWrapper = () => {
    const { setValue } = useFormContext<EmailServiceParamType>()
    const currentDate = dayjs().format(ABBREVIATED_MONTH_DAY_YEAR)

    const user = useAtomValue(userAtom)

    const preBody = `<br><br><p>Customer Details</p>
                    <p>Date: ${currentDate}</p>
                    <p>Client Address: ${user?.address.streetAddress + ','} ${
                        user?.address.streetTwoAddress !== 'undefined'
                            ? user?.address.streetTwoAddress + ', '
                            : ''
                    }${user?.address.city + ','} ${user?.address.state + ','} ${user?.address
                        .zipCode}</p>
                    <p>Contact Information: ${user?.email} - ${user?.phoneNumber}</p>
                    <br>
                    <br>
                    <br>
                    <p>Please do not reply to this email.</p>
                    `

    const onBodyChange = (val: string) => {
        const modifiedValue = val
            .replace(/<p><\/p>/g, '<br>')
            .replace(/\\/g, '')
            .replace(/<p\s*style\s*=\s*"text-align:\s*center"\s*><\/p>/g, '<br>')
            .replace(/<p\s*style\s*=\s*"text-align:\s*right"\s*><\/p>/g, '<br>')
        setValue('body', modifiedValue + preBody)
    }

    return (
        <div className='space-y-4 mt-[36px]'>
            <AppRichTextBox name='email' onChange={(val) => onBodyChange(val)} />
        </div>
    )
}
