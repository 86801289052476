import { FullProfileType } from '@/api/profile/schema'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { isViewedAsAtom, viewedAsUserAtom } from '@/store/auth'
import { userAtom } from '@/store/auth'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { RefundType } from '@/api/invoicing/schema'
import { getOwnerRefund } from '@/api/invoicing'
import dayjs from 'dayjs'

const Reimbursements = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    // UPDATE USING useStripeConnectStatus
    // useEffect(() => {
    //     if (isSetupLater && isRestricted) {
    //         navigate('/403')
    //     }
    // }, [isRestricted, isSetupLater])

    const { data: refundList, isLoading } = useQuery<unknown, AxiosError, RefundType[]>({
        queryKey: ['subscriptionsInfo'],
        queryFn: () => getOwnerRefund(user?.businessId as string),
    })

    return (
        <section>
            <div className='bg-white pt-5 pb-7 px-5'>
                <Table>
                    <TableHeader>
                        <TableRow className='whitespace-nowrap'>
                            <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                Invoice Number
                            </TableHead>
                            <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                Date
                            </TableHead>
                            <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                Customer
                            </TableHead>
                            <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                Amount
                            </TableHead>
                            <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                Status
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <> Loading . . .</>}
                        {!isLoading && (
                            <>
                                {refundList?.map((data) => (
                                    <TableRow key={data.invoiceId} className='whitespace-nowrap'>
                                        <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                            {data.invoice?.invoiceName}
                                        </TableCell>
                                        <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                            {dayjs(data.createdAt).format('MMMM DD, YYYY')}
                                        </TableCell>
                                        <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                            {data.profile?.firstName} {data.profile?.lastName}
                                        </TableCell>
                                        <TableCell className='flex-row items-start text-start  text-[#191A0A] text-[16px] '>
                                            ${data.amount}
                                        </TableCell>
                                        {data.status === 'succeeded' ? (
                                            <TableCell className='text-zentive-green-dark flex-row items-start text-start text-[16px]'>
                                                Successful
                                            </TableCell>
                                        ) : (
                                            <TableCell className='  flex-row items-start text-start text-[16px]'>
                                                <div className='flex text-zentive-blue'>
                                                    Pending
                                                </div>
                                                <div className='flex text-zentive-gray-medium text-[12px]'>
                                                    5-10 days processing
                                                </div>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </div>
        </section>
    )
}

export default Reimbursements
