import { JobStatusLabelType, JobStatusType, ManipulateType, RepeatType } from '@/api/job/schema'
import { AccountSortType } from '@/api/profile/schema'

//Importing Notications
import bulk_customer_imported from '@/assets/private/notificationIcon/bulk_customer_imported.svg'
import completed_job from '@/assets/private/notificationIcon/completed_job.svg'
import created_customer from '@/assets/private/notificationIcon/created_customer.svg'
import created_employee from '@/assets/private/notificationIcon/created_employee.svg'
import crew_created from '@/assets/private/notificationIcon/crew_created.svg'
import invoice_follow_up from '@/assets/private/notificationIcon/invoice_follow_up.svg'
import job_status_start from '@/assets/private/notificationIcon/job_status_start.svg'
import lead_status_update from '@/assets/private/notificationIcon/lead_status_update.svg'
import quickbooks_sync_success from '@/assets/private/notificationIcon/quickbooks_sync_success.svg'
import quote_accepted from '@/assets/private/notificationIcon/quote_accepted.svg'
import quote_rejected from '@/assets/private/notificationIcon/quote_rejected.svg'
import reset_job from '@/assets/private/notificationIcon/reset_job.svg'
import created_job from '@/assets/private/notificationIcon/created_job.svg'
import stripe_linked from '@/assets/private/notificationIcon/stripe_linked.svg'
import subscription_renewal_cancel from '@/assets/private/notificationIcon/subscription_renewal_cancel.svg'
import user_verified from '@/assets/private/notificationIcon/user_verified.svg'

type NotificationIcons = {
    [key: string]: string
}

export const notificationIcons: NotificationIcons = {
    bulk_customer_imported,
    completed_job,
    created_customer,
    created_employee,
    crew_created,
    invoice_follow_up,
    job_status_start,
    lead_status_update,
    quickbooks_sync_success,
    quote_accepted,
    quote_rejected,
    reset_job,
    created_job,
    stripe_linked,
    subscription_renewal_cancel,
    user_verified,
}

// Validation error messages
export const AT_LEAST_TWO_CHARACTERS = 'must be at least 2 characters long'
export const AT_LEAST_FOUR_CHARACTERS = 'must be at least 4 characters long'
export const EMPTY_UUID = '00000000-0000-0000-0000-000000000000'
export const INVALID = 'is invalid'
export const INVALID_CURRENT_PASSWORD = 'Invalid current password'
export const INVALID_WEBSITE = 'Invalid website format. Example: example.com'
export const NO_INVALID_CHARACTERS = 'must not contain invalid characters'
export const NO_OLD_PASSWORD = 'New password must differ from your current password.'
export const NO_WHITE_SPACES = 'must not contain white spaces'
export const NO_WHITE_SPACES_END = 'cannot end with a space'
export const NO_WHITE_SPACES_START = 'cannot start with a space'
export const PHONE_NUMBER = 'Phone Number'
export const REQUIRED = 'is required'
export const SELECT_VALID_STREET_ADDRESS =
    'Please type and select a valid Street Address from the dropdown'

export const ROLE = {
    ADMIN: 'ADMIN' as string,
    OWNER: 'OWNER' as string,
    CREW: 'CREW' as string,
    CUSTOMER: 'CUSTOMER' as string,
    CO_OWNER: 'CO_OWNER' as string,
} as const

export const SUBSCRIPTION_TYPES = {
    FREE_TRIAL: 'Free Trial',
    GROWTH_PRO: 'Growth Pro',
    STANDARD: 'Standard',
    STARTER: 'Starter',
} as const

export const USER_STATUS = {
    DELETED: 'DEL',
    BILLING_PAUSED: 'BP',
    TERMINATED: 'TER',
    ACTIVATED: 'ACT',
    INACTIVE: 'INA',
    UNPAID: 'UNP',
    PENDING: 'PEN',
} as const

export const JOB_STATUS = {
    ACTIVE: 'ACT',
    ALL: 'ALL',
    CANCELED: 'CANCELED',
    COMPLETED: 'COMPLETED',
    IN_PROGRESS: 'IN_PROGRESS',
    LATE_VISIT: 'LATE_VISIT',
    RESCHEDULE: 'RESCHEDULE',
} as const

export type JobStatus = (typeof JOB_STATUS)[keyof typeof JOB_STATUS]

export const JOB_STATUS_LABEL: Record<any | JobStatusType, JobStatusLabelType> = {
    ACT: 'Active',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    CANCELED: 'Canceled',
    LATE_VISIT: 'Late Visit',
    RESCHEDULE: 'Reschedule',
}

export const getJobStatusClasses = (jobStatus: string): string => {
    switch (jobStatus) {
        case JOB_STATUS.ACTIVE:
            return 'bg-zentive-green-medium text-white px-6'
        case JOB_STATUS.IN_PROGRESS:
            return 'bg-zentive-blue text-white px-6'
        case JOB_STATUS.COMPLETED:
            return 'bg-zentive-green-dark text-white px-6'
        case JOB_STATUS.CANCELED:
            return 'bg-zentive-gray-medium text-white px-6'
        case JOB_STATUS.LATE_VISIT:
            return 'bg-zentive-red-dark text-white px-6'
        case JOB_STATUS.RESCHEDULE:
            return 'bg-zentive-blue text-white px-6'
        default:
            return ''
    }
}

export const getJobFontColor = (jobStatus: string): string => {
    switch (jobStatus) {
        case JOB_STATUS.ACTIVE:
            return 'text-zentive-green-medium'
        case JOB_STATUS.IN_PROGRESS:
            return 'text-zentive-blue'
        case JOB_STATUS.COMPLETED:
            return 'text-zentive-green-dark'
        case JOB_STATUS.CANCELED:
            return 'text-zentive-gray-medium'
        case JOB_STATUS.LATE_VISIT:
            return 'text-zentive-red-dark'
        case JOB_STATUS.RESCHEDULE:
            return 'text-zentive-blue'
        default:
            return ''
    }
}

export const ROLE_NAME = {
    ADMIN: 'Super Admin' as string,
    OWNER: 'Owner' as string,
    CREW: 'Crew' as string,
} as const

export const MESSAGE_TYPE = {
    SMS: 'sms' as string,
    EMAIL: 'email' as string,
    BOTH: 'both' as string,
    DRAFT: 'draft' as string,
} as const

export const APPOINTMENT_TYPES = {
    RECURRING: 'RECURRING',
    ONEOFF_JOB: 'ONE-OFF_JOB',
} as const

// CREATE A NEW APPOINTMENT_TYPES
export const RECURRING_TYPES: Record<any | RepeatType, RepeatType> = {
    WEEKLY: 'WEEKLY',
    BI_WEEKLY: 'BI_WEEKLY',
    MONTHLY: 'MONTHLY',
    YEARLY: 'YEARLY',
    DAILY: 'DAILY',
} as const

export const DURATION_TYPES = {
    DAILY: 'Day(s)' as string,
    WEEKLY: 'Week(s)' as string,
    BI_WEEKLY: 'Week(s)' as string,
    MONTHLY: 'Month(s)' as string,
    YEARLY: 'Year(s)' as string,
} as const

export const DURATION_ARGUMENTS: Record<RepeatType, ManipulateType> = {
    WEEKLY: 'week',
    BI_WEEKLY: 'week',
    MONTHLY: 'month',
    YEARLY: 'year',
    DAILY: 'day',
} as const

export const CUSTOMER_EMAIL_VALIDITY = {
    EXISTING_IN_OTHER_BUSINESS: 'EXISTING_IN_OTHER_BUSINESS',
    NOT_EXISTING: 'NOT_EXISTING',
    EXISTING_IN_CURRENT_BUSINESS: 'EXISTING_IN_CURRENT_BUSINESS',
    OTHER_ROLE_TAKEN: 'OTHER_ROLE_TAKEN',
    DECLINED: 'DECLINED_PREV_INVITATION',
    PENDING: 'PENDING_APPROVAL',
    NO_INVITE_AMOUNT_LEFT: 'NO_INVITE_AMOUNT_LEFT',
} as const

// Styles
export const PAYMENT_BTN_STYLE = 'w-full h-[43px] rounded-[4px]'
export const PAYMENT_FIELD_STYLE =
    'w-full rounded-sm border-solid border-[1px] border-zentive-gray-medium px-3 py-3.5 text-sm h-[45px]'
export const SKLTN_PAYMENT_BTN_STYLE = 'w-full h-[43px] rounded-[4px] bg-[#f7f7f7]'
export const BREADCRUMBS_PADDING_STYLE = 'px-[70px] pt-[50px]'

// Time Format
export const ABBREVIATED_DATE_12_HOUR = 'MMM DD, YYYY h:mm A'
export const ABBREVIATED_MONTH_DAY_YEAR = 'MMM, DD, YYYY'
export const BASE_DATE = '2000-01-01'
export const DURATION = ['1', '2', '3', '4', '5', '6', '7', '8', '9']
export const FULL_MONTH_WITH_DAY_AND_FULL_YEAR = 'MMMM DD, YYYY'
export const ISO_8601 = 'YYYY-MM-DD'
export const ISO_8601_WITH_UTC_OFFSET = 'YYYY-MM-DD HH:mm:ss ZZ'
export const ISO_8601_WITH_UTC_OFFSET_IOS = 'YYYY-MM-DD HH:mm:ssZ'
export const TWELVE_HOUR_CLOCK_WITH_MERIDIEM = 'h:mm A'
export const TWENTY_FOUR_HOUR_CLOCK = 'HH:mm'
export const US_FORMAT = 'MM/DD/YYYY'
export const COMPLETE_FORMAT_WITHOUT_TIME = 'MMMM DD, YYYY'
export const SPANISH_FORMAT_WITH_TIME = 'DD/MM/YYYY HH:mm'
export const SPANISH_FORMAT = 'DD/MM/YYYY'
export const US_FORMAT_WITH_TIME_MERIDIEM = 'MM/DD/YYYY, h:mm A'
export const US_FORMAT_WITH_TIME_MERIDIEM_DAY = 'DD/MM/YYYY, HH:mm'
export const WEEKDAY_FULLNAME = 'dddd'
export const WEEK_LABELS = ['first', 'second', 'third', 'fourth', 'fifth']

// screen constant
export const LAPTOP_MAX_WIDTH = 1425
export const TABLET_MAX_WIDTH = 1024

export const QUOTE_STATUS: Record<string, string> = {
    APPROVED: 'approved',
    PENDING: 'pending',
    DECLINED: 'declined',
}

export const INVOICE_STATUS = {
    OPEN: 'open',
    PAID: 'paid',
    PAID_OUT_OF_BAND: 'paid_out_of_band', // invoices that is paid outside stripe example: via cash
}

export const statusColor = {
    [QUOTE_STATUS.PENDING]: 'text-zentive-blue',
    [QUOTE_STATUS.APPROVED]: 'text-zentive-green-dark',
    [QUOTE_STATUS.DECLINED]: 'text-zentive-red-dark',
    default: 'text-black',
}

export const PRODUCT_SERVICE_TYPES = ['Product', 'Service']

export const DEFAULT_ADDRESS = {
    streetAddress: '',
    streetTwoAddress: '',
    city: '',
    state: '',
    zipCode: '',
    timeOffset: 0,
    timezone: '',
}

export const DEFAULT_EXPENSE_VALUE = {
    accountName: '',
    description: '',
    date: '',
    total: 0,
}

export const DEFAULT_PRODUCT_SERVICE_GROUP_ITEM = {
    productServiceId: '',
    name: '',
    cost: 0,
    markup: 0,
    unitPrice: 0,
    description: '',
    quantity: 1,
}

export const DEFAULT_QUOTE_VALUE = {
    totalUnitPrice: 0,
    totalServicePrice: 0,
    totalProduct: 0,
    totalService: 0,
    totalExpense: 0,
    discount: 0,
    laborCost: 0,
    subTotal: 0,
    internalNotes: '',
    quantity: 1,
}

export const DEFAULT_QUOTE_TAX_VALUE = {
    name: '',
    productTax: 0,
    isProductsTaxable: false,
    serviceTax: 0,
    isServicesTaxable: false,
    expenseTax: 0,
    isExpensesTaxable: false,
}

export const SORT = {
    newest: 'desc',
    oldest: 'asc',
}

export const SORT_LABEL: Record<AccountSortType, string> = {
    desc: 'Newest',
    asc: 'Oldest',
}

export const SHOULD_VALIDATE_TRUE = {
    shouldValidate: true,
}

export const NOT_FOUND_ERRORS = {
    PROFILE_ID: 'Profile ID not found',
    // Add more NOT_FOUND error messages as needed team
} as const

export const MSG_TYPES = {
    sms: 'sms',
    email: 'email',
    both: 'both',
}

export const FEATURE_RESTRICTIONS = {
    customerMessaging: 'customer_messaging',
    jobDetailsImages: 'job_details_images',
    jobLogImages: 'job_log_images',
    emailMarketing: 'email_marketing',
    timeTracking: 'time_tracking',
    customFields: 'custom_fields',
    userCount: 'user_count',
    liveTracking: 'live_tracking',
    quickBooks: 'quick_books',
    crm: 'crm',
}

export const MAIL_CHIMP_INTEGRATION = {
    invalidCredentials: 'invalid_credentials',
    invalidApiKey: 'invalid_api_key',
    invalidListId: 'invalid_list_id',
    conflictingApiKey: 'conflicting_api_key',
    success: 'success',
}

export const LEAD_CONTACT_TYPES = {
    BLDR: 'Builder',
    CMPNY: 'Company',
    HO: 'Homeowner',
    OTHR: 'Other',
} as const

export const LEAD_SOURCES = {
    ANGI: 'Angi',
    EMAIL: 'Email',
    FB: 'Facebook',
    GOOGLE: 'Google',
    IG: 'Instagram',
    PC: 'Phone Call',
    RC: 'Repeat Customer',
    WOM: 'Word of Mouth',
    YS: 'Yarn Sign',
    WS: 'Website',
    OTHR: 'Other',
} as const

export const NET_GROSS_TABS = {
    gross: 'gross',
    net: 'net',
}
export const DROPDOWN_LABELS = {
    servicesOrProducts: 'Select Service Or Product',
}

export const MODAL_VARIANTS = {
    CREATE: 'create',
    DELETE: 'delete',
    IMPORT: 'import',
    DEAL_STAGE: 'deal_stage',
    NONE: 'none',
    RESTRICT: 'restrict',
    UPDATE: 'update',
    VIEW: 'view',
} as const
