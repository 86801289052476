import { AGREEMENT_SECTIONS } from "@/assets/static/privacyDocument"

const ServiceAgreement = () => {
    return (
        <div className='flex justify-center items-center z-0 crew-sign-in-bg relative'>
            <div className='flex flex-col justify-center sign-in-overlay w-full mx-28 my-20 h-full overflow-y-sroll'>
                <h1 className='text-zentive-green-dark text-center uppercase font-semibold text-lg'> Service Agreement </h1>
                <ol className='list-decimal list-inside mt-4 text-left w-full px-8'>
                    {AGREEMENT_SECTIONS?.map((section, index) => (
                        <li key={index} className='flex flex-col mb-4'>
                            <div className='flex items-center text-zentive-gray-medium mb-2'>
                                <h2 className='font-semibold'>{section.title}</h2>
                            </div>
                            <p className='text-zentive-black'>
                                {section.content}
                            </p>
                        </li>
                    ))}
                </ol>
                <span className="px-8 mt-6 text-zentive-gray-medium"> Revision [3.12.24] </span>
            </div>
        </div>
    )
}

export default ServiceAgreement
