import { selectedJobStatusAtom } from '@/store/job'
import { cn } from '@/utils/helper'
import { useAtom } from 'jotai'
import { useTranslation } from 'react-i18next'
import useEmblaCarousel from 'embla-carousel-react'
import './index.css'
import { JOB_STATUS } from '@/constants'
import { ReadJobType } from '@/api/job/schema'
import { FC } from 'react'
import { filterJobListByStatus } from '@/utils/job'

type StatusFilterProps = {
    jobList: ReadJobType[]
}

const StatusFilter: FC<StatusFilterProps> = ({ jobList }) => {
    const { t } = useTranslation(['jobs'])
    const [selectedStatus, setSelectedStatus] = useAtom(selectedJobStatusAtom)
    const openJobsCount = filterJobListByStatus(jobList, JOB_STATUS.ACTIVE)?.length
    const inProgressJobsCount = filterJobListByStatus(jobList, JOB_STATUS.IN_PROGRESS)?.length
    const completedJobsCount = filterJobListByStatus(jobList, JOB_STATUS.COMPLETED)?.length
    const [emblaRef] = useEmblaCarousel()

    const statuses = [
        {
            title: t('All'),
            value: JOB_STATUS.ALL,
            count: jobList?.length ?? 0,
        },
        {
            title: t('Open'),
            value: JOB_STATUS.ACTIVE,
            count: openJobsCount ?? 0,
        },
        {
            title: t('In Progress'),
            value: JOB_STATUS.IN_PROGRESS,
            count: inProgressJobsCount ?? 0,
        },
        {
            title: t('Done'),
            value: JOB_STATUS.COMPLETED,
            count: completedJobsCount ?? 0,
        },
    ]

    return (
        <div className='embla'>
            <div className='embla__viewport' ref={emblaRef}>
                <div className='embla__container'>
                    {statuses?.map((status, index) => (
                        <div className='embla__slide' key={index}>
                            <div
                                className={cn(
                                    'embla__slide__statuses 3xs:text-sm',
                                    status.value === selectedStatus && 'bg-zentive-blue text-white',
                                )}
                                role='button'
                                onClick={() => {
                                    setSelectedStatus(status.value)
                                }}
                            >
                                {status?.title + ` (${status.count})`}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default StatusFilter
