import { getJobCompletionReport } from '@/api/reports'
import { JobCompletionRecordType, JobCompletionReportType } from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { US_FORMAT } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { jobCompletionExportAtom, jobCompletionFilterAtom } from '@/store/reports'
import { cn, displayCurrency } from '@/utils/helper'
import { formatWorkingHours } from '@/utils/time'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const tableHeader = [
    'Job Number',
    'Crew Name',
    'Job Type',
    'Date Completed',
    'Invoice Amount',
    'Labor Cost',
    'Time Spent',
    'Address',
    'Invoice Number',
    'Quote Number',
]

const JobCompletionTable = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const navigate = useNavigate()

    // const jobCompletionFilter = useAtomValue(jobCompletionFilterAtom)
    const jobCompletionFilter = useAtomValue(jobCompletionFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(jobCompletionExportAtom)

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data } = useQuery<JobCompletionReportType>({
        queryKey: ['jobCompletionReport', user?.businessId, jobCompletionFilter],
        queryFn: () =>
            getJobCompletionReport(user?.businessId as string, jobCompletionFilter, pagination),
    })

    const handleCheckChange = (jobId: string) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new invoiceId
                return {
                    jobIds: [jobId],
                }
            }

            // Check if the invoiceId already exists in the array
            const updatedInvoiceIds = prev.jobIds?.includes(jobId)
                ? prev?.jobIds?.filter((id) => id !== jobId) // Remove the invoiceId
                : [...(prev.jobIds || []), jobId] // Add the invoiceId if it doesn't exist

            return {
                jobIds: updatedInvoiceIds,
            }
        })
    }

    return (
        <Card>
            <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                <Table className='table-auto whitespace-normal mx-auto'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-md whitespace-nowrap',
                                    )}
                                >
                                    <div className='inline-flex gap-2'>{header}</div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Array.isArray(data?.content?.report) &&
                            data?.content?.report?.length > 0 &&
                            data?.content?.report?.map((r: JobCompletionRecordType) => (
                                <TableRow
                                    key={r.quoteNumber as number}
                                    className='whitespace-nowrap'
                                    onClick={() =>
                                        navigate(
                                            `/reports/jobs-completion-report-details/${r.jobId}`,
                                        )
                                    }
                                >
                                    <TableCell className='text-[#191A0A] text-md flex flex-row gap-2 items-center'>
                                        <Checkbox
                                            onCheckedChange={() =>
                                                handleCheckChange(r.jobId as string)
                                            }
                                            checked={exportAtom?.jobIds?.some(
                                                (id) => r.jobId === id,
                                            )}
                                        />
                                        <NavLink
                                            to={r.jobNumber ? `/schedule/jobs/${r.jobId}` : '#'}
                                        >
                                            <Button
                                                className='text-md text-zentive-blue font-semibold'
                                                variant={'link'}
                                            >
                                                {r.jobNumber ? 'JN-00' + r.jobNumber : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-[#191A0A] text-md'>
                                        {r.crewName}
                                    </TableCell>
                                    <TableCell className='text-[#191A0A] text-md'>
                                        <p>{r.services.join(', ')}</p>
                                    </TableCell>
                                    <TableCell className='text-[#191A0A] text-md'>
                                        <p>{dayjs(r.date).format(US_FORMAT)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{displayCurrency(r.invoiceAmount)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{displayCurrency(r.laborCost)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{formatWorkingHours(r.timeSpent)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{r.address}</p>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink
                                            to={
                                                r.invoiceId
                                                    ? `/financial-management/invoicing/view-invoice/${r.invoiceId}`
                                                    : '#'
                                            }
                                        >
                                            <p className='text-md text-zentive-blue font-semibold'>
                                                {r.invoiceNumber as string}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink
                                            to={
                                                r.quoteId
                                                    ? `/financial-management/quoting/view?quoteId=${r.quoteId}`
                                                    : '#'
                                            }
                                        >
                                            <p className='text-md text-zentive-blue font-semibold'>
                                                QN-{r.quoteNumber as string}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {data?.content?.report && (
                            <TableRow key='total-row' className='whitespace-nowrap'>
                                <TableCell>
                                    <p className='font-bold text-md'>Total</p>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <p className='font-bold text-md'>
                                        {displayCurrency(data?.content?.totalInvoiceAmount ?? 0)}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className='font-bold text-md'>
                                        {displayCurrency(data?.content?.totalLaborCost ?? 0)}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className='font-bold text-md'>
                                        {formatWorkingHours(data?.content?.totalTimeSpent ?? 0)}
                                    </p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
            <div className='p-5 pb-0 flex flex-row items-center'>
                <Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                    itemsPerPage={data?.meta?.pageSize ?? 5}
                    totalRecords={data?.meta?.totalRecords ?? 0}
                />
            </div>
        </Card>
    )
}
export default JobCompletionTable
