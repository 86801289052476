import { SingleProductService } from '@/api/quoting/schema'
import { JobCimpletionFilterType } from '@/api/reports/schema'
import { getServiceList } from '@/api/service'
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import SearchBar from '@/components/InputSearchBox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { RadioGroup, RadioGroupItem } from '@/components/RadioGroup'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { jobCompletionFilterAtom } from '@/store/reports'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'

export const JobTypeFilter = () => {
    const [open, setIsOpen] = useState<boolean>(false)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const [searchValue, setSearchValue] = useState('')
    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const [jobCompletionFilter, setJobCompletionFilter] = useAtom(jobCompletionFilterAtom)
    const [jobTypeFilter, setJobTypeFilter] = useState<JobCimpletionFilterType | null>(null)

    const handleGetCheckState = (productSvcId: string) => {
        return jobTypeFilter?.svcIds?.some((id) => productSvcId === id)
    }

    const { data: services, isPending } = useQuery({
        queryKey: ['reportingJobTypes', searchValue, open],
        queryFn: () => getServiceList(user?.businessId as string, searchValue, pagination),
        enabled: !!user?.businessId,
    })

    const handleAddJobFilter = (productSvcId: string) => {
        setJobTypeFilter((prevFilter) => {
            if (!prevFilter) {
                // If there is no existing filter, create one with the new productSvcId
                return {
                    svcIds: [productSvcId],
                    keyword: '',
                }
            }

            // Check if the productSvcId already exists in the array
            const updatedProductSvcId = prevFilter.svcIds?.includes(productSvcId)
                ? prevFilter?.svcIds?.filter((id) => id !== productSvcId) // Remove the productSvcId
                : [...(prevFilter.svcIds || []), productSvcId] // Add the productSvcId if it doesn't exist

            return {
                ...prevFilter,
                svcIds: updatedProductSvcId,
            }
        })
    }

    const handleSaveJobFilter = () => {
        setJobCompletionFilter((prevNetGrossFilter) => {
            if (!jobTypeFilter) {
                // If there is no jobTypeFilter, return the previous netGrossFilter
                return prevNetGrossFilter
            }

            const productSvcIds = jobTypeFilter.svcIds || []
            const netGrossProductSvc = prevNetGrossFilter?.svcIds || []
            const keyword = prevNetGrossFilter?.keyword || ''

            // Filter out productServices that are in netGrossProductSvc but not in productSvcIds
            const filteredProductSvc = netGrossProductSvc.filter((id) => productSvcIds.includes(id))

            // Add productServices that are in productSvcIds but not in filteredProductSvc
            const updatedProductSvc = [
                ...filteredProductSvc,
                ...productSvcIds.filter((id) => !filteredProductSvc.includes(id)),
            ]

            return {
                ...prevNetGrossFilter,
                svcIds: updatedProductSvc,
                keyword: keyword,
            }
        })

        setIsOpen(false)
    }

    const handleClearFilters = () => {
        setJobCompletionFilter({
            svcIds: [],
            keyword: '',
        })

        setIsOpen(false)
    }

    useEffect(() => {
        setSearchValue('')
        if (open) {
            setJobTypeFilter({
                svcIds: jobCompletionFilter?.svcIds || [],
                keyword: jobCompletionFilter?.keyword ?? '',
            })
        }
    }, [open])

    return (
        <AlertDialog open={open} onOpenChange={setIsOpen} key={'customer-filter'}>
            <AlertDialogTrigger asChild onClick={() => {}}>
                <button type='button' className='button-outline'>
                    Job Type Filter
                </button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white rounded-[15px] p-0 max-w-[38rem]'>
                <div className='flex flex-col gap-5 px-16'>
                    <AlertDialogTitle className='text-left font-semibold text-lg text-[#191A0A] py-10 pb-0'>
                        <p className='text-2xl font-bold text-left'>Filter by Job Type</p>
                    </AlertDialogTitle>
                    <SearchBar
                        onSearchChange={(e) => setSearchValue(e.target.value)}
                        placeHolder='Search For...'
                    />

                    <div className='p-5 flex flex-row border border-1 border-zentive-gray-light'>
                        <RadioGroup>
                            {services && !isPending ? (
                                services?.content?.map((s: SingleProductService) => (
                                    <div
                                        className='flex flex-row gap-2 items-center'
                                        key={s.productServiceId}
                                    >
                                        <RadioGroupItem
                                            checked={handleGetCheckState(
                                                s.productServiceId as string,
                                            )}
                                            value={s.productServiceId as string}
                                            onClick={() =>
                                                handleAddJobFilter(s.productServiceId as string)
                                            }
                                            className='text-zentive-gray-medium'
                                        />
                                        <p>{s.name}</p>
                                    </div>
                                ))
                            ) : (
                                <li>No Products and Services Yet</li>
                            )}
                        </RadioGroup>
                    </div>

                    <Pagination
                        itemsPerPage={pagination.pageSize ?? 5}
                        setPagination={setPagination}
                        totalRecords={services?.meta?.totalRecords ?? 1}
                        pagination={pagination}
                    />
                </div>

                <AlertDialogFooter className='py-5 w-full rounded-b-md items-center justify-center bg-zentive-gray-light max-w-[38rem]'>
                    <div className='flex flex-row gap-2'>
                        <Button
                            type='button'
                            variant='link'
                            className='w-[8rem] font-bold text-zentive-blue text-md'
                            onClick={handleClearFilters}
                        >
                            Clear Filters
                        </Button>
                        <div className='flex flex-row gap-5 mr-5'>
                            <Button
                                type='button'
                                variant='outline'
                                className='w-[11rem]'
                                onClick={() => {
                                    setIsOpen(!open)
                                    setJobTypeFilter(null)
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='button'
                                disabled={!jobTypeFilter}
                                className={`w-[11rem]`}
                                onClick={handleSaveJobFilter}
                            >
                                Apply
                            </Button>
                        </div>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}
