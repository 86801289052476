import { getNetGrossReport } from '@/api/reports'
import { NetGrossRecordType, NetGrossReportType } from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { US_FORMAT } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { netGrossExportAtom, netGrossFilterAtom } from '@/store/reports'
import { cn, displayCurrency } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

const tableHeader = [
    'Job Number',
    'Job Type',
    'Customer Name',
    'Date Completed',
    'Gross Income',
    'Paid Via',
    'Invoice Number',
    'Quote Number',
]

const GrossTable = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const netGrossFilter = useAtomValue(netGrossFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(netGrossExportAtom)

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data: report } = useQuery<NetGrossReportType>({
        queryKey: ['netGrossReport', user?.businessId, netGrossFilter],
        queryFn: () => getNetGrossReport(user?.businessId as string, netGrossFilter, pagination),
    })

    const handleCheckChange = (invoiceId: string) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new invoiceId
                return {
                    invoiceIds: [invoiceId],
                }
            }

            // Check if the invoiceId already exists in the array
            const updatedInvoiceIds = prev.invoiceIds?.includes(invoiceId)
                ? prev?.invoiceIds?.filter((id) => id !== invoiceId) // Remove the invoiceId
                : [...(prev.invoiceIds || []), invoiceId] // Add the invoiceId if it doesn't exist

            return {
                invoiceIds: updatedInvoiceIds,
            }
        })
    }

    return (
        <Card>
            <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                <Table className='table-auto whitespace-normal mx-auto'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-md whitespace-nowrap',
                                    )}
                                >
                                    <div className='inline-flex gap-2'>{header}</div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Array.isArray(report?.content?.report) &&
                            report?.content?.report?.length > 0 &&
                            report?.content?.report?.map((r: NetGrossRecordType) => (
                                <TableRow
                                    key={r.quoteNumber as number}
                                    className='whitespace-nowrap'
                                >
                                    <TableCell className='text-[#191A0A] text-md flex flex-row gap-2 items-center'>
                                        <Checkbox
                                            onCheckedChange={() =>
                                                handleCheckChange(r.invoiceId as string)
                                            }
                                            checked={exportAtom?.invoiceIds?.some(
                                                (id) => r.invoiceId === id,
                                            )}
                                        />
                                        <NavLink
                                            to={r.jobNumber ? `/schedule/jobs/${r.jobId}` : '#'}
                                        >
                                            <Button
                                                className='text-md text-zentive-blue font-semibold'
                                                variant={'link'}
                                            >
                                                {r.jobNumber ? 'JN-00' + r.jobNumber : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-[#191A0A] text-md'>
                                        <p>{r.services.join(', ')}</p>
                                    </TableCell>
                                    <TableCell className='text-[#191A0A] text-md'>
                                        <p>{r.customerName}</p>
                                    </TableCell>
                                    <TableCell className='text-[#191A0A] text-md'>
                                        <p>{dayjs(r.date).format(US_FORMAT)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{displayCurrency(r.grossIncome)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{r.paidVia}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{r.invoiceNumber as string}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{r.quoteNumber as string}</p>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {report && report?.content?.report?.length > 0 && (
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <p className='font-bold'>
                                        {displayCurrency(report?.content?.totalGrossIncome)}
                                    </p>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
            <div className='p-5 pb-0 flex flex-row items-center'>
                <Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                    itemsPerPage={report?.meta?.pageSize ?? 5}
                    totalRecords={report?.meta?.totalRecords ?? 0}
                />
            </div>
        </Card>
    )
}
export default GrossTable
