import { Button } from '@/components/Button'
import { BREADCRUMBS_PADDING_STYLE, FEATURE_RESTRICTIONS } from '@/constants'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useNavigate } from 'react-router-dom'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import RoutingTable from './RoutingTable'
import { useQuery } from '@tanstack/react-query'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { useState } from 'react'
import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import { useAtomValue } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { UpgradeBadge } from '@/components/UpgradeBadge'

const Routing = () => {
    const navigate = useNavigate()

    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['routingFeatureSubscription', user?.profileId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.profileId as string,
                FEATURE_RESTRICTIONS.liveTracking,
            ),
    })

    const handleRouteValidation = (nav: string) => {
        setRestrictionModal(!isFeatureAvailable)
        if (isFeatureAvailable) {
            navigate(nav)
        }
    }

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <div className='flex flex-row gap-5'>
                <BreadCrumbs titleName='Routing' />
                {!isFeatureAvailable && !featureLoading && (
                    <span>
                        <UpgradeBadge className='-ml-5' />
                    </span>
                )}
            </div>
            <div className='flex items-start text-left justify-between mt-10 bg-white py-5 px-5 rounded-xl mb-10'>
                <span className='my-auto text-zentive-blue font-semibold text-[20px]'>Routes</span>
                <div className='flex flex-row'>
                    <Button
                        type='button'
                        onClick={() => navigate('/customers/customer-messaging')}
                        className='border bg-zentive-blue text-sm  text-white tracking-wide font-normal hover:text-zentive-green-light mr-2'
                        disabled={featureLoading}
                    >
                        Send a Message
                    </Button>
                    <Button
                        type='button'
                        onClick={() =>
                            handleRouteValidation('/schedule/routing/route-optimization')
                        }
                        className='border border-zentive-green-dark tracking-wide bg-white text-sm text-zentive-green-dark font-semibold hover:text-zentive-green-light mr-2'
                        disabled={featureLoading}
                    >
                        <FaArrowRotateLeft className='mr-1' />
                        Route Optimization
                    </Button>
                    <Button
                        type='button'
                        onClick={() => handleRouteValidation('/schedule/routing/map')}
                        className='border bg-zentive-green-dark text-zentive-green-light tracking-wide font-normal hover:text-zentive-green-light'
                        disabled={featureLoading}
                    >
                        <HiOutlineLocationMarker className='mr-1' />
                        Create Job Route
                    </Button>
                </div>
            </div>
            <div className=' border-t-2 w-full'>
                <RoutingTable />
            </div>
            <FeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
        </div>
    )
}

export default Routing
