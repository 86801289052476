import { Button } from '@/components/Button'
import { crewInterfaceFilterAtom, selectedDateAtom, selectedDateFilterAtom } from '@/store/crew'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { IoMdClose } from 'react-icons/io'
import JobDateSelector, { formatDateTime } from './DateSelector'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { timezoneAtom } from '@/store/auth'
import { TimeZoneResult } from '@/hooks/useGeocode'
import { ISO_8601_WITH_UTC_OFFSET_IOS } from '@/constants'

const JobFilterDrawer = () => {
    const { t } = useTranslation(['jobs'])
    const setSelectedDate = useSetAtom(selectedDateAtom)
    const setSelectedDateFilter = useSetAtom(selectedDateFilterAtom)
    const [isDateFilterOpen, setIsDateFilterOpen] = useAtom(crewInterfaceFilterAtom)
    const timezone = useAtomValue(timezoneAtom) as TimeZoneResult

    const handleResetButton = () => {
        setSelectedDateFilter({
            startDate: formatDateTime(
                dayjs().startOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
            endDate: formatDateTime(
                dayjs().endOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
        })
        setSelectedDate({
            startDate: formatDateTime(
                dayjs().startOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
            endDate: formatDateTime(
                dayjs().endOf('day').toString(),
                timezone?.timeZoneId as string,
                ISO_8601_WITH_UTC_OFFSET_IOS,
            ),
        })
        setIsDateFilterOpen(false)
    }

    const handleCloseButton = () => {
        setIsDateFilterOpen(false)
    }

    return (
        <div className='fixed bottom-0 w-full z-50 max-w-[1024px]'>
            <div
                className={`sticky transform ${
                    isDateFilterOpen
                        ? 'translate-y-4 h-[70dvh] overflow-y-auto'
                        : 'translate-y-1/4 h-[0dvh] '
                } transition-all duration-500 bg-white`}
            >
                <div className='flex flex-row justify-between w-full border border-b h-10 items-center'>
                    <div>
                        <p className='pl-4 text-gray-500 font-semibold'>{t('Filter')}</p>
                    </div>
                    <div className='flex flex-row gap-x-2 mx-4'>
                        <div>
                            <Button
                                variant='ghost'
                                className='hover:bg-transparent hover:text-zentive-blue text-zentive-blue h-8 mt-1.5 px-0 mr-4'
                                onClick={handleResetButton}
                            >
                                {t('Reset')}
                            </Button>
                        </div>
                        <div>
                            <Button variant='ghost' className='hover:bg-transparent px-0'>
                                <IoMdClose
                                    className='h-5 w-5 text-gray-500'
                                    onClick={handleCloseButton}
                                />
                            </Button>
                        </div>
                    </div>
                </div>
                <JobDateSelector />
            </div>
        </div>
    )
}

export default JobFilterDrawer
