import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getJobById } from '@/api/job'
import Spinner from '@/components/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import { CiTrash } from 'react-icons/ci'
import ConfirmationModal from '../../Common/ConfirmationModal'
import { useState } from 'react'
import { deleteExpenseById } from '@/api/quoting'
import { Button } from '@/components/Button'
import emptyExpensesPlaceholder from '@/assets/private/empty-expenses.png'
import { useToast } from '@/hooks/useToast'
import { BsArrowLeft } from 'react-icons/bs'
import ViewExpense from './ViewExpense'
import { ExpenseType } from '@/api/quoting/schema'

const Expenses = () => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { jobId } = useParams()
    const { t } = useTranslation(['view-job'])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectedExpenseId, setSelectedExpenseId] = useState<string>('')
    const [isExpenseOpen, setIsExpenseOpen] = useState<boolean>(false)
    const [selectedExpense, setSelectedExpense] = useState<ExpenseType | null>(null)
    const navigate = useNavigate()

    const { data: job } = useQuery({
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId ?? ''),
    })

    const { mutate: deleteExpenseMu, isPending } = useMutation({
        mutationFn: (expenseId: string) => deleteExpenseById(expenseId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['jobById'] })
            setIsOpen(false)
            toast({
                description: t('Expense has been deleted successfully'),
                variant: 'crew_interface',
            })
        },
    })

    const handleDeleteClick = (expenseId: string) => {
        setIsOpen(true)
        setSelectedExpenseId(expenseId)
    }

    const handleDeleteExpense = (expenseId: string) => {
        if (!isPending) {
            deleteExpenseMu(expenseId)
        }
    }

    if (!job)
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )

    if (isExpenseOpen && selectedExpense)
        return (
            <>
                <div className='flex flex-col space-y-10'>
                    <div className='py-4 z-20 bg-white w-full px-3 mb-10'>
                        <div className='flex flex-row justify-between items-center w-full px-4 py-1'>
                            <div className='flex flex-row items-center gap-x-4'>
                                <BsArrowLeft
                                    className='h-5 w-5 md:h-10 md:w-10 cursor-pointer'
                                    onClick={() => {
                                        setIsExpenseOpen(false)
                                        setSelectedExpense(null)
                                    }}
                                />
                                <span className='font-semibold text-sm md:text-2xl'>
                                    {selectedExpense.accountName}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <ViewExpense expense={selectedExpense} />
                </div>
            </>
        )

    return (
        <div className='flex flex-col justify-between items-center'>
            <BackButtonTopBar title={t('Additional Expenses')} redirectTo={`/crew/job/${jobId}`} />
            {job?.quote?.expense?.map((details, index) => (
                <div
                    className='w-10/12 flex flex-row mx-auto bg-white rounded-sm shadow-md border px-3 py-4'
                >
                    <div
                    key={index}
                    onClick={() => {
                        setIsExpenseOpen(true)
                        setSelectedExpense(details)
                    }} 
                    className='flex justify-between items-center bg-white grid-cols-1 w-full'>
                        <p className='text-sm pr-4'>{details?.accountName}</p>
                        <p className='text-sm text-zentive-blue'></p>
                    </div>
                    <CiTrash
                                className='w-5 h-5 text-zentive-red-dark bg-white left'
                                onClick={() => handleDeleteClick(details?.expenseId ?? '')}
                            />
                </div>
            ))}
            <Button
                className='w-10/12 max-w-[1024px] fixed bottom-4 mx-0 z-20 px-8 bg-white text-zentive-green-dark hover:text-white'
                onClick={() => navigate(`/crew/job/expenses/add/${jobId}`)}
            >
                {t('Add')}
            </Button>
            <ConfirmationModal
                isOpen={isOpen}
                title={t(`Are you sure you want to delete this expense?`)}
                text={t(`Delete`)}
                onClose={() => setIsOpen(false)}
                onProceed={() => handleDeleteExpense(selectedExpenseId)}
            />

            {!job?.quote?.expense && (
                <div className='px-4 mx-4 my-10 '>
                    <div className='flex justify-center bg-white rounded-md'>
                        <img
                            src={emptyExpensesPlaceholder}
                            alt='empty-table-placeholder'
                            className='my-8 h-44'
                        />
                    </div>
                    <p className='pb-4 text-center text-2xl text-white font-semibold mt-4'>
                        {' '}
                        {t('No Additional Expenses to Show')}{' '}
                    </p>
                    <p className='pb-4 text-center text-white'>
                        {' '}
                        {t(`You have no additional costs to display at the moment.`)}{' '}
                    </p>
                </div>
            )}
        </div>
    )
}

export default Expenses
