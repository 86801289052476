import { Button } from '@/components/Button'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { IoSearchOutline } from 'react-icons/io5'
import { IoIosFunnel } from 'react-icons/io'
import { useAtomValue, useSetAtom } from 'jotai'
import {
    crewInterfaceFilterAtom,
    crewjobSearchOpenAtom,
    selectedDateFilterAtom,
} from '@/store/crew'
import { cn } from '@/utils/helper'
import { useLocation, useNavigate } from 'react-router-dom'

const CrewTopBar = () => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const jobTabPathname = '/crew/jobs'
    const setIsSearchToggle = useSetAtom(crewjobSearchOpenAtom)
    const setIsDateFilterOpen = useSetAtom(crewInterfaceFilterAtom)
    const selectedDateFilter = useAtomValue(selectedDateFilterAtom)

    const handleJobSearchClick = () => {
        navigate('/crew/jobs/search')
        setIsSearchToggle(true)
    }
    return (
        <div className='fixed w-full max-w-[1024px] top-0 py-1 z-20 bg-white'>
            <div className='flex flex-row justify-between'>
                <div>
                    <Button
                        variant='ghost'
                        onClick={() => navigate('/crew/jobs')}
                        className='hover:bg-transparent'
                    >
                        <img
                            src={zentiveLogo}
                            alt='Zentive Logo'
                            className='h-[30px] w-[80px] cursor-pointer'
                        ></img>
                    </Button>
                </div>
                {pathname === jobTabPathname && (
                    <div className='flex flex-row my-auto gap-x-4 mx-10'>
                        <IoSearchOutline
                            className='h-5 w-5 cursor-pointer'
                            onClick={handleJobSearchClick}
                        />
                        <IoIosFunnel
                            className={cn(
                                'h-5 w-5 cursor-pointer',
                                selectedDateFilter.startDate !== '' &&
                                    selectedDateFilter.endDate !== '' &&
                                    'text-zentive-blue',
                            )}
                            onClick={() => setIsDateFilterOpen(true)}
                        />
                        <span className='absolute top-2 right-8 bg-zentive-green-dark text-black text-xs rounded-full px-1'>
                            {selectedDateFilter.startDate != '' &&
                                selectedDateFilter.endDate !== '' &&
                                '1'}
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CrewTopBar
