import { getAllSubscribers, getAllSubscribersCount } from '@/api/profile'
import SearchBar from '@/components/InputSearchBox'
import { Pagination } from '@/components/Pagination'
import { Tabs, TabsList, TabsTrigger } from '@/components/Tabs'
import { BREADCRUMBS_PADDING_STYLE, USER_STATUS } from '@/constants'
import {
    manageSubsTabAtom,
    paginationAtom,
    redirectedSubsTabAtom,
    sortingSubscriptionAtom,
    toExportSubsAtom,
} from '@/store/manageSubs'
import { cn } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ExportDropdown from './ExportFile'
import SubscribersTableContents from './SubscriberTableContents'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import { ParamsFilterType } from '@/api/profile/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import EmptySubscriberList from './EmptySubscriberList'
import { ProfileSelfResponseType } from '@/api/business/schema'
import FilterAccountButton from '@/components/FilterAccountButton'

export type SubscriberProps = {
    subscriberList: ProfileSelfResponseType[]
}

const TAB_TRIGGER_CLASS =
    'w-full h-[54px] bg-white hover:bg-gray-200 hover:font-semibold flex justify-center text-[18px] text-zentive-gray-medium'
const TAB_TRIGGER_CLASS2 =
    'data-[state=active]:text-indigo-900 data-[state=active]: border-zentive-blue data-[state=active]:font-bold data-[state=active]:border-b-4'

const ManageSubscriptionSubscribers = () => {
    const { pathname } = useLocation()

    const [selected, setSelected] = useAtom(manageSubsTabAtom)
    const [pagination, setPagination] = useAtom(paginationAtom)
    const [redirected, setRedirected] = useAtom(redirectedSubsTabAtom)
    const [toExportBusinessData] = useAtom(toExportSubsAtom)

    const [keyWord, setKeyWord] = useState<string>('')

    const sortedSubs = useAtomValue(sortingSubscriptionAtom)

    const [searchFilter, setSearchFilter] = useState<ParamsFilterType>({
        alphabetically: false,
        highestPayingCustomer: false,
        newestCustomer: false,
        unpaidInvoices: false,
        state: false,
    })

    const {
        isLoading,
        data: subscriberList,
        isSuccess,
    } = useQuery({
        queryKey: [
            'subsribersList',
            pagination,
            redirected,
            selected,
            keyWord,
            sortedSubs,
            searchFilter,
        ],
        queryFn: () =>
            getAllSubscribers(
                keyWord.trim() ?? '',
                redirected || selected,
                sortedSubs,
                pagination,
                searchFilter,
            ),
    })

    const {
        data: { content: { active, billingPause, terminated } } = {
            content: { active: 0, billingPause: 0, terminated: 0 },
        },
    } = useQuery({
        queryKey: ['statusCount', isSuccess],
        queryFn: getAllSubscribersCount,
        enabled: isSuccess,
    })

    const handleSearch = (query: string) => {
        setKeyWord(query)
        setPagination((prevPagination) => ({
            ...prevPagination,
            page: 1,
        }))
    }

    // Remove dependency since we only set it on initial mount
    useEffect(() => {
        setSelected(redirected || USER_STATUS.ACTIVATED)
    }, [])

    useEffect(() => {
        // Set redirected tab to NULL on useEffect when navigating to other page
        // So that on next page visit / component mount, tab is on ACT once again
        setRedirected(null)
    }, [pathname])

    const isDirty = () => {
        if (!toExportBusinessData?.length) return true
        if (!subscriberList?.content?.length) return true
        else return false
    }

    const onClickOptions = (keyword: string, isChecked: boolean) => {
        setSearchFilter({ ...searchFilter, [keyword]: isChecked })
    }

    return (
        <section className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Subscribers' />
            <div className='mt-10 flex justify-between'>
                <div>
                    <SearchBar
                        placeHolder='Search for...'
                        onSearchChange={(e) => handleSearch(e.target.value)}
                    />
                </div>
                <div className='flex gap-4 justify-center items-center'>
                    <FilterAccountButton
                        onClickOptions={onClickOptions}
                        selectedFilter={searchFilter}
                    />
                    <ExportDropdown disabled={isDirty()} />
                </div>
            </div>
            <Tabs
                key={selected}
                defaultValue={selected}
                onValueChange={(val) => {
                    setSelected(val)

                    // Set the tab redirection to null
                    // This is the state used after updating the state under settings
                    if (redirected) {
                        setRedirected(null)
                    }
                }}
            >
                <TabsList className='w-full mt-[25px] '>
                    <TabsTrigger
                        value={USER_STATUS.ACTIVATED}
                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS2)}
                    >
                        Active ({active})
                    </TabsTrigger>
                    <TabsTrigger
                        value={USER_STATUS.BILLING_PAUSED}
                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS2)}
                    >
                        Billing Paused ({billingPause})
                    </TabsTrigger>
                    <TabsTrigger
                        value={USER_STATUS.TERMINATED}
                        className={cn(TAB_TRIGGER_CLASS, TAB_TRIGGER_CLASS2)}
                    >
                        Terminated ({terminated})
                    </TabsTrigger>
                </TabsList>
            </Tabs>

            <div className='mt-[16px]'>
                {/* If table data is still being fetched */}
                {isLoading && <AppSkeletonLoadingState />}

                {/* If table is not empty */}
                {!isLoading &&
                    !!subscriberList?.content?.length &&
                    subscriberList?.meta?.totalRecords && (
                        <>
                            <SubscribersTableContents subscriberList={subscriberList.content} />
                            <div className='mb-5 mt-3'>
                                {pagination.pageSize !== undefined && (
                                    <Pagination
                                        itemsPerPage={pagination.pageSize}
                                        setPagination={setPagination}
                                        totalRecords={subscriberList.meta.totalRecords ?? 1}
                                        pagination={pagination}
                                    />
                                )}
                            </div>
                        </>
                    )}

                {/* If table is empty */}
                {subscriberList?.content === null && <EmptySubscriberList />}
            </div>
        </section>
    )
}

export default ManageSubscriptionSubscribers
