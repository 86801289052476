import { SubscriptionType } from '@/api/subscription/schema'
import { Button } from '@/components/Button'
import { manageNewSubTypeAtom } from '@/store/manageSubs'
import { cn } from '@/utils/helper'
import { useAtomValue, useSetAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { encryptString } from '@/utils/crypto'
import { useToast } from '@/hooks/useToast'
import { userAtom } from '@/store/auth'
import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBusinessProfile } from '@/api/profile'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { isSetupPaymentOpenAtom } from '@/store/subscription'

interface ChangePlanButtonProps {
    plan: SubscriptionType
    changePlanType: string
    hasPayment: boolean
    isChangePlan: string
}
const ChangePlanButton = ({
    plan,
    changePlanType,
    isChangePlan,
    hasPayment,
}: ChangePlanButtonProps) => {
    const setIsSetupPaymentOpen = useSetAtom(isSetupPaymentOpenAtom)
    const setNewSubID = useSetAtom(manageNewSubTypeAtom)
    const navigate = useNavigate()
    const user = useAtomValue(userAtom)
    const { toast } = useToast()

    const changePlan = (changePlan: string, planName: string) => (
        <div>
            <p className='text-sm'>{changePlan}</p>
            <p className='text-base'>{planName}</p>
        </div>
    )

    const { data: businessProfileData } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        queryKey: ['businessProfile'],
        queryFn: () => getBusinessProfile(user?.profileId as string),
    })

    return (
        <Button
            disabled={
                changePlanType === 'Current Plan' || businessProfileData?.business.isDowngrade
            }
            key={plan.name}
            className={cn(
                changePlanType === 'Current Plan'
                    ? 'bg-zentive-gray-medium mt-[3px]'
                    : 'bg-zentive-blue',
                'w-[160px] mt-5',
            )}
            onClick={() => {
                if (!hasPayment) {
                    setIsSetupPaymentOpen(true)
                    return
                }

                if (isChangePlan) {
                    toast({
                        description: 'Unable to change plan downgrade is currently pending',
                    })
                } else {
                    const encryptedPlanId = encryptString(plan.subscriptionTypeId as string)

                    if (changePlanType === 'Downgrade') {
                        setNewSubID(plan.subscriptionTypeId as string)
                        navigate(
                            `/owner-subscription/change-plan/downgrade?newPlan=${encryptedPlanId}`,
                        )
                    } else if (changePlanType === 'Upgrade') {
                        setNewSubID(plan.subscriptionTypeId as string)
                        navigate(
                            `/owner-subscription/change-plan/upgrade?newPlan=${encryptedPlanId}`,
                        )
                    }
                }
            }}
            type='submit'
        >
            {changePlanType === 'Current Plan'
                ? changePlan('', 'Current Plan')
                : changePlan(changePlanType, plan.name as string)}
        </Button>
    )
}

export default ChangePlanButton
